import { createAsyncThunk } from '@reduxjs/toolkit';
import { log } from '@helpers/index';
import { noteApi } from '@api/note/noteApi';
import { TRejectValue } from '@models/index';
import { REJECT_RESPONSE_KEY } from '@api/types';
import { noteMappers } from '@redux/note/mappers';
import { updateNotesCounter } from '@redux/Task/slice';
import { addTaskToAttachList } from '@redux/note/slice';
import { noteListMappers } from '@redux/noteList/mappers';
import { TFolderItem, TNoteTag } from '@redux/noteList/zod';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { addNewTag, showNoteDeleteSuccessMessage } from '@redux/noteList/slice';
import { TAttachedTemplates, TNote, TNoteWithAttachedTemplateData } from '@redux/note/zod';
import { NoteResponseDataSchema, NoteResponseSchema, NoteTagResponseSchema } from '@models/Note';

export const getNoteByIdThunk = createAsyncThunk<
  TNoteWithAttachedTemplateData | void,
  string,
  TRejectValue
>('note/getNoteById', (data, { dispatch, rejectWithValue }) => {
  return noteApi
    .getNoteById(data)
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data) {
        throw new Error('');
      }
      const parsedData = NoteResponseSchema.safeParse(response.data);

      if (!parsedData.success) {
        log('@@note/getNoteByIdThunk pars error', parsedData.error.format());
      } else {
        return noteMappers.mapOneNoteToRedux(response.data);
      }
    })
    .catch(error => {
      if (error.response?.data?.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(getNoteByIdThunk(data)), 100);
      }
      return rejectWithValue(error.response.data);
    });
});

export const createNoteThunk = createAsyncThunk<
  TNote | void,
  { noteData: TNote; activeFolder: TFolderItem; modalCloseCallback?: () => void },
  TRejectValue
>('note/createNoteThunk', (data, { dispatch, rejectWithValue }) => {
  const { noteData, activeFolder, modalCloseCallback } = data;
  const noteToBack = noteMappers.mapToBack(noteData, activeFolder);

  return noteApi
    .createNote(noteToBack)
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data) {
        throw new Error('');
      }

      const parsedData = NoteResponseDataSchema.safeParse(response.data);

      if (!parsedData.success) {
        log('@@note/createNoteThunk pars error', parsedData.error.format());
      } else {
        if (modalCloseCallback) {
          modalCloseCallback();
        }
        return noteMappers.mapToRedux(response.data);
      }
    })
    .catch(error => {
      if (error.response?.data?.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(createNoteThunk(data)), 100);
      }
      return rejectWithValue(error.response.data);
    });
});

export const updateNoteById = createAsyncThunk<
  TNote | void,
  { noteData: TNote; activeFolder: TFolderItem; modalCloseCallback?: () => void },
  TRejectValue
>('note/updateNoteById', (data, { dispatch, rejectWithValue }) => {
  const { noteData, activeFolder, modalCloseCallback } = data;

  const noteToBack = noteMappers.mapToBack(noteData, activeFolder);

  return noteApi
    .changeNote({
      noteData: noteToBack,
      noteId: noteData.id,
    })
    .then(() => {
      if (modalCloseCallback) {
        modalCloseCallback();
      }
      return noteData;
    })
    .catch(error => {
      if (error.response?.data?.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(updateNoteById(data)), 100);
      }
      return rejectWithValue(error.response.data);
    });
});

export const moveNoteToFolderThunk = createAsyncThunk<
  TNote | void,
  { noteData: TNote; activeFolder: TFolderItem },
  TRejectValue
>('note/moveNoteToFolderThunk', (data, { dispatch, rejectWithValue }) => {
  const { noteData, activeFolder } = data;
  const noteToBack = noteMappers.mapToBack(noteData, activeFolder);

  return noteApi
    .changeNote({
      noteData: noteToBack,
      noteId: noteData.id,
    })
    .then(() => {
      return { ...noteData, folder: { id: activeFolder.id, title: activeFolder.title } };
    })
    .catch(error => {
      if (error.response?.data?.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(moveNoteToFolderThunk(data)), 100);
      }
      return rejectWithValue(error.response.data);
    });
});

export const deleteNoteByIdThunk = createAsyncThunk<
  void,
  { noteId: string; modalCloseCallback: () => void },
  TRejectValue
>('note/deleteNoteByIdThunk', (data, { dispatch, rejectWithValue }) => {
  const { noteId, modalCloseCallback } = data;

  return noteApi
    .deleteNoteById(noteId)
    .then(() => {
      if (modalCloseCallback) {
        modalCloseCallback();
      }
      dispatch(showNoteDeleteSuccessMessage());
    })
    .catch(error => {
      if (error.response?.data?.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(deleteNoteByIdThunk(data)), 100);
      }
      return rejectWithValue(error.response.data);
    });
});

export const createNewTagThunk = createAsyncThunk<
  TNoteTag | void,
  { newTagTitle: string; closeModalCallback: () => void },
  TRejectValue
>('note/createNewTagThunk', (data, { dispatch, rejectWithValue }) => {
  const { closeModalCallback, newTagTitle } = data;

  return noteApi
    .createTag(newTagTitle)
    .then(response => {
      if ('title' in response.data) {
        const parsedData = NoteTagResponseSchema.safeParse(response.data);

        if (!parsedData.success) {
          log('@@note/createNewTagThunk pars error', parsedData.error.format());
        } else {
          if (closeModalCallback) {
            closeModalCallback();
          }
          const newTag = noteListMappers.mapTagsToRedux([response.data])[0];

          dispatch(addNewTag(newTag));

          return newTag;
        }
      }
    })
    .catch(error => {
      if (error.response?.data?.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(createNewTagThunk(data)), 100);
      }
      return rejectWithValue(error.response?.data);
    });
});

export const attachNoteToTaskThunk = createAsyncThunk<
  number | void,
  { noteId: string; taskInfo: TAttachedTemplates; count: number },
  TRejectValue
>('note/attachNoteToTaskThunk', (data, { dispatch, rejectWithValue }) => {
  const { count, noteId, taskInfo } = data;

  const requestData = {
    noteId,
    templateId: taskInfo.id,
  };

  return noteApi
    .attachNoteToTask(requestData)
    .then(response => {
      if (response.status === 204) {
        dispatch(updateNotesCounter({ count: count + 1, taskId: taskInfo.id }));
        dispatch(addTaskToAttachList(taskInfo));
      }
      return response.status;
    })
    .catch(error => {
      if (error.response?.data?.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(attachNoteToTaskThunk(data)), 100);
      }
      return rejectWithValue(error?.response?.data);
    });
});

export const deleteNoteFromTaskThunk = createAsyncThunk<
  string | void,
  { noteId: string; templateId: string },
  TRejectValue
>('note/deleteNoteFromTaskThunk', (data, { dispatch, rejectWithValue }) => {
  return noteApi
    .deleteNoteFromTask(data)
    .then(response => {
      if (response.status === 204) {
        return data.templateId;
      }
    })
    .catch(error => {
      if (error.response?.data?.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(deleteNoteFromTaskThunk(data)), 100);
      }
      return rejectWithValue(error?.response?.data);
    });
});
