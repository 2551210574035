import { TAG_PREFIX } from '@const/notes';
import { TNoteListPageDataToRedux } from '@redux/noteList/types';
import { serialize } from '@components/sladeEditor/richTextEditor';
import { TFolderItem, TNoteItem, TNoteTag } from '@redux/noteList/zod';
import {
  TNoteTagResponse,
  TNoteListPageData,
  TNoteResponseData,
  TNoteFolderResponse,
} from '@models/Note';

export const noteListMappers = {
  mapNotesListToRedux(notes: TNoteResponseData[]): TNoteItem[] {
    return notes.map(({ id, title, tags, body, is_private, folder }) => ({
      id,
      folder,
      header: title,
      text: typeof body === 'string' ? body : serialize(body),
      isPrivate: is_private,
      tags: tags.map(tag => ({ ...tag, title: `#${tag.title}` })),
    }));
  },
  mapTagsToRedux(tags: TNoteTagResponse[]): TNoteTag[] {
    return tags.map(({ id, title, created_at, updated_at, company_id }) => ({
      id,
      title: `${TAG_PREFIX}${title}`,
      companyId: company_id,
      createdAt: created_at,
      updatedAt: updated_at || '',
    }));
  },
  mapFoldersToRedux(folders: TNoteFolderResponse[]): TFolderItem[] {
    return folders.map(({ id, title, created_at, updated_at, company_id }) => ({
      id,
      title,
      updatedAt: updated_at,
      createdAt: created_at,
      companyId: company_id,
      numberOfNotes: 0,
    }));
  },
  mapFolderToRedux({
    id,
    title,
    created_at,
    updated_at,
    company_id,
  }: TNoteFolderResponse): TFolderItem {
    return {
      id,
      title,
      updatedAt: updated_at,
      createdAt: created_at,
      companyId: company_id,
      numberOfNotes: 0,
    };
  },
  mapNoteListPageDataToRedux(data: TNoteListPageData): TNoteListPageDataToRedux {
    const tags: TNoteTag[] = this.mapTagsToRedux(data.tags);
    const noteList: TNoteItem[] = this.mapNotesListToRedux(data.notes);
    const folders: TFolderItem[] = this.mapFoldersToRedux(data.folders);

    return {
      tags,
      folders,
      noteList,
    };
  },
};
