import { TInitialState } from '@redux/noteList/zod';
import { COMMON_FOLDER } from '@redux/noteList/mockData';

export const initialState: TInitialState = {
  isError: false,
  isLoading: true,
  isAccessDenied: false,
  isShowFolderList: true,
  isTagDeleteMode: false,
  errorMessage: '',
  successMessage: '',
  tags: [],
  folderList: [COMMON_FOLDER],
  searchString: '',
  selectedTags: [],
  status: 'success',
  newFolderName: '',
  isShowNotes: false,
  activeFolder: COMMON_FOLDER,
  isShowSearchByTag: false,
  notesList: [],
  isShowCreateFolderModal: false,
  filteredNotesList: [],
  filteredByFolderNotesList: [],
};
