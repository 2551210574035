import React, { memo, useCallback } from 'react';
import { createSearchParams, Link, useSearchParams } from 'react-router-dom';
//
import { NoteTag } from '@blocks/noteTag';
import { PATH_NAMES } from '@const/httpConst';
import { TNoteItem } from '@redux/noteList/zod';
import { TSetTagInSearchString } from '@shared/types';
import { highlightSearch } from '@helpers/highlightSearch';
import { ReactComponent as VisibleIcon } from '@img/notes/eye_open.svg';
import { ReactComponent as NoVisibleIcon } from '@img/notes/eye_off.svg';
import { serialize } from '@components/sladeEditor/richTextEditor';

type TProps = {
  /**
   * Данные заметки
   * @param {TNoteItem}
   */
  cardData: TNoteItem;
  /**
   * Строка поиска для выделения найденного текста
   * @param {string}
   */
  searchString: string;
  /**
   * Устанавливает тэг в поисковую строку
   * @param {TSetTagInSearchString}
   */
  setTagInSearchString?: TSetTagInSearchString;
  /**
   * Флаг состояния заметки добавлена или нет
   * @param {boolean}
   */
  isSelected?: boolean;
  /**
   * Добавляет заметку в задачу
   * @param {() => void}
   */
  selectNoteCard?: () => void;
  /**
   * Флаг начала перетаскивания карточки
   * @param {boolean}
   */
  isDragging?: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const NoteCard = memo(
  ({
    cardData,
    isDragging,
    isSelected,
    searchString,
    selectNoteCard,
    setTagInSearchString,
    className = '',
  }: TProps) => {
    const [searchParams] = useSearchParams();

    const { tags, text, id, header, isPrivate } = cardData;

    const link = `${PATH_NAMES.ONE_NOTE}?${createSearchParams({
      ...searchParams,
      id: String(id),
    }).toString()}`;

    const setTagInSearchStringHandling = useCallback(
      (tagId: string) => () => {
        if (setTagInSearchString) {
          // setTagInSearchString(tagId)();
        }
      },
      [setTagInSearchString],
    );

    const tagsToRender = tags.map(({ id: tagId, title }) => (
      <NoteTag
        key={tagId}
        tagName={title}
        searchString={searchString}
        setTagInSearchString={setTagInSearchStringHandling(tagId)}
        className='mr-2 mb-1'
      />
    ));

    return (
      <div
        onClick={selectNoteCard || undefined}
        className={`relative border border-varden ${
          isSelected ? 'bg-aquaSpring border-statusGreen' : 'bg-varden'
        } cursor-pointer rounded-lg flex flex-col justify-between break-words px-4 py-3 h-[11.25rem] w-full ${className}`}>
        {!selectNoteCard ? (
          <Link
            to={link}
            aria-label={`Заметка ${id}`}
            className={`${!isDragging ? 'absolute' : 'hidden'} top-0 right-0 bottom-0 left-0`}
          />
        ) : null}
        <span className='font-medium text-black text-tooltips leading-5 mb-2 overflow-hidden h-5'>
          {highlightSearch({ searchString, targetString: header, highlightColor: '#FFCB00' })}
        </span>
        <div className='flex flex-col grow justify-between'>
          <span className='text-stormGray text-tooltips leading-5 mb-2 overflow-hidden h-[3.75rem] whitespace-pre-line'>
            {highlightSearch({
              searchString,
              targetString: serialize(text),
              highlightColor: '#FFCB00',
            })}
          </span>
          <div className='flex flex-wrap overflow-hidden h-[1.75rem]'>{tagsToRender}</div>
        </div>
        <div className='flex items-center justify-end'>
          {isPrivate ? <NoVisibleIcon /> : <VisibleIcon />}
        </div>
      </div>
    );
  },
);

NoteCard.displayName = 'NoteCard';
