import { AxiosResponse } from 'axios';
import { TTPNameList } from '@shared/types';
import { TFilialData } from '@models/Accounts';
import { PayloadAction } from '@reduxjs/toolkit';
import { TTaskInitialState } from '@redux/Task/zod';
import { TStaffListItem } from '@models/Settings';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { initialWeekDaysData, newTaskData } from '@data/index';
import { COMMON_TOAST_MESSAGES, PROMISE_STATUS } from '@const/common';
import { TTaskData, TTaskDataResponse, TTaskInfo } from '@models/Tasks';
import {
  TRequestStatus,
  REJECT_RESPONSE_KEY,
  TRejectActionPayload,
  REJECT_RESPONSE_2_KEY,
} from '@api/types';
import {
  numWord,
  validateValue,
  getStringWithoutSpaces,
  getArrayWithoutLastElement,
  getStringWithoutDoubleValues,
  getSortedStringWithoutDoubleValuesAndComaOnEnd,
} from '@helpers/index';
import {
  SEND_TIME,
  TASK_TYPE,
  CRM_EVENTS,
  EVENTS_TYPES,
  INITIAL_TIME,
  INITIAL_VALUE,
  CLIENTS_EVENTS,
  SENDING_PERIODS,
  YCLIENTS_EVENTS,
  TELEPHONY_EVENTS,
  BB_INTEGRATION_EVENTS,
} from '@const/task';
import { TNoteItem } from '@redux/noteList/zod';

export const reducers = {
  setTaskData(state: TTaskInitialState, action: PayloadAction<{ data: TTaskData }>) {
    state.isSuccess = true;
    state.data = action.payload.data;
  },
  setSortedTasksList(state: TTaskInitialState, action: PayloadAction<string[]>) {
    state.sortedTasksList = action.payload;
  },
  setTaskStatus(state: TTaskInitialState, action: PayloadAction<{ status: boolean }>) {
    state.data.isActive = action.payload.status;
  },
  setTaskType(state: TTaskInitialState, action: PayloadAction<{ taskType: TASK_TYPE }>) {
    const { taskType } = action.payload;
    state.data.taskType = taskType;
  },
  setNewTPBalloonValue(
    state: TTaskInitialState,
    action: PayloadAction<{ name: string; value: string }>,
  ) {
    const { name, value } = action.payload;
    // eslint-disable-next-line
    // @ts-ignore
    state[`${name}NewValue`] = value;
  },
  clearNewTPBalloonValue(state: TTaskInitialState, action: PayloadAction<{ name: string }>) {
    const { name } = action.payload;
    // eslint-disable-next-line
    // @ts-ignore
    state[`${name}NewValue`] = '';
  },
  addTPBalloon(
    state: TTaskInitialState,
    action: PayloadAction<{ keyName: TTPNameList; value: string }>,
  ) {
    const { keyName, value } = action.payload;
    if (!value) return;
    state.data[keyName] = state.data[keyName].concat(value);
    // eslint-disable-next-line
    // @ts-ignore
    state[`${keyName}NewValue`] = '';
  },
  deleteTPBalloon(
    state: TTaskInitialState,
    action: PayloadAction<{ keyName: TTPNameList; value: string }>,
  ) {
    const { keyName, value } = action.payload;
    state.data[keyName] = state.data[keyName].filter(item => item !== value);
  },
  setVisitQuantity(
    state: TTaskInitialState,
    action: PayloadAction<{ quantityFrom: string; quantityTo: string }>,
  ) {
    const { quantityFrom, quantityTo } = action.payload;
    if (!quantityFrom || !quantityTo) {
      state.data.visits = [];
    } else {
      state.data.visits = [quantityFrom, quantityTo];
    }
  },
  setWhenSend(state: TTaskInitialState, action: PayloadAction<{ whenSend: string }>) {
    const { whenSend } = action.payload;
    if (whenSend === SEND_TIME.EVENT) {
      state.data.selectedEventType = state.data.selectedEventType || EVENTS_TYPES.TELEPHONY;
      state.data.selectedTelephonyType = TELEPHONY_EVENTS.MISSED_CALL;
    }
    if (whenSend === SEND_TIME.REGULAR) {
      state.data.sendingPeriod = state.data.sendingPeriod || SENDING_PERIODS.DAY;
      state.data.sendingTime = state.data.sendingTime || INITIAL_TIME;

      state.data.selectedEventType = EVENTS_TYPES.TELEPHONY;
      state.data.selectedTelephonyType = TELEPHONY_EVENTS.MISSED_CALL;
    }
    if (whenSend === SEND_TIME.CLIENTS) {
      state.data.sendingTime = state.data.sendingTime || INITIAL_TIME;
      state.data.selectedClientEvent = state.data.selectedClientEvent || CLIENTS_EVENTS.LOST;
      state.data.daysWithoutVisiting = state.data.daysWithoutVisiting || INITIAL_VALUE;

      state.data.selectedEventType = EVENTS_TYPES.TELEPHONY;
      state.data.selectedTelephonyType = TELEPHONY_EVENTS.MISSED_CALL;
    }
    state.data.whenSend = whenSend;
  },
  setSendingPeriod(state: TTaskInitialState, action: PayloadAction<{ periodName: string }>) {
    const { periodName } = action.payload;

    if (periodName === SENDING_PERIODS.WEEK) {
      state.data.sendWeekDays = Object.values(state.data.sendWeekDays).some(activeDay => activeDay)
        ? state.data.sendWeekDays
        : initialWeekDaysData;
    }
    state.data.sendingPeriod = periodName;
  },
  setTaskSendingTime(state: TTaskInitialState, action: PayloadAction<{ time: string }>) {
    const { time } = action.payload;
    state.data.sendingTime = time;
  },
  setTaskSendFrequency(state: TTaskInitialState, action: PayloadAction<{ frequency: number }>) {
    const { frequency } = action.payload;
    if (frequency < 1) {
      state.data.frequency = 1;
    } else {
      state.data.frequency = frequency;
    }
  },
  setSendWeekDay(
    state: TTaskInitialState,
    action: PayloadAction<{ weekDay: keyof TTaskData['sendWeekDays'] }>,
  ) {
    const { weekDay } = action.payload;
    const { sendWeekDays } = state.data;
    const checked = sendWeekDays[weekDay];
    const newSendWeekDays = { ...sendWeekDays, [weekDay]: !checked };
    const isNoOneDaySelected = !!Object.entries(newSendWeekDays).filter(item => item[1]).length;
    if (isNoOneDaySelected) {
      state.data.sendWeekDays = newSendWeekDays;
    } else {
      state.data.sendWeekDays = initialWeekDaysData;
    }
  },
  setMonthSendDay(state: TTaskInitialState, action: PayloadAction<{ monthDays: string }>) {
    const { monthDays } = action.payload;
    const monthDaysWithOutSpaces = getStringWithoutDoubleValues(getStringWithoutSpaces(monthDays));

    if (monthDays.length - state.data.monthSendDays.length > 1) {
      return;
    }

    const lastNumber = Number(monthDaysWithOutSpaces.split(',').reverse()[0]);
    const monthDaysWithoutLastNumber = getArrayWithoutLastElement<string>(
      monthDaysWithOutSpaces.split(','),
    );
    const monthDaysLastChar = monthDaysWithOutSpaces[monthDaysWithOutSpaces.length - 1];
    const monthDaysPreLastChar = monthDaysWithOutSpaces[monthDaysWithOutSpaces.length - 2];

    if (!monthDaysWithOutSpaces && state.data.sendOnLastDayOfMonth) {
      state.data.monthSendDays = monthDaysWithOutSpaces;
    } else if (
      Number.isNaN(Number(monthDaysLastChar)) &&
      monthDaysLastChar === ',' &&
      !Number.isNaN(Number(monthDaysPreLastChar))
    ) {
      state.data.monthSendDays = monthDaysWithOutSpaces;
    } else if (lastNumber > 30) {
      state.data.sendOnLastDayOfMonth = true;
      monthDaysWithoutLastNumber.push('');
      state.data.monthSendDays = monthDaysWithoutLastNumber.join(',');
    } else if (!Number.isNaN(Number(monthDaysLastChar))) {
      state.data.monthSendDays = monthDaysWithOutSpaces;
    }
  },
  sortMonthSendDay(state: TTaskInitialState) {
    state.data.monthSendDays = getSortedStringWithoutDoubleValuesAndComaOnEnd(
      state.data.monthSendDays,
    );
  },
  setSendOnLastDayOfMonth(state: TTaskInitialState, action: PayloadAction<{ checked: boolean }>) {
    const { checked } = action.payload;
    if (!checked && !state.data.monthSendDays) state.data.monthSendDays = INITIAL_VALUE;
    state.data.sendOnLastDayOfMonth = checked;
  },
  setDaysWithoutVisiting(state: TTaskInitialState, action: PayloadAction<{ days: string }>) {
    const { days } = action.payload;
    state.data.daysWithoutVisiting = validateValue({
      lowLimit: 1,
      value: days,
      upLimit: 365,
      initialValue: INITIAL_VALUE,
    });
  },
  setDaysAfterVisit(state: TTaskInitialState, action: PayloadAction<{ days: string }>) {
    const { days } = action.payload;
    state.data.daysAfterVisit = validateValue({
      lowLimit: 1,
      value: days,
      upLimit: 365,
      initialValue: INITIAL_VALUE,
    });
  },
  setTaskName(state: TTaskInitialState, action: PayloadAction<{ taskName: string }>) {
    const { taskName } = action.payload;
    state.data.taskName = taskName;
  },
  setTaskDescription(state: TTaskInitialState, action: PayloadAction<{ taskDescription: string }>) {
    const { taskDescription } = action.payload;
    state.data.taskDescription = taskDescription;
  },
  setIsAddChecklist(state: TTaskInitialState, action: PayloadAction<{ isAddChecklist: boolean }>) {
    const { isAddChecklist } = action.payload;
    state.data.isAddChecklist = isAddChecklist;
  },
  setNewChecklistItemName(state: TTaskInitialState, action: PayloadAction<{ newValue: string }>) {
    state.newChecklistItemName = action.payload.newValue;
  },
  editChecklistItemName(
    state: TTaskInitialState,
    action: PayloadAction<{ oldValue: string; newValue: string }>,
  ) {
    const { oldValue, newValue } = action.payload;
    const newChecklist = state.data.checklistItems.map(item => {
      if (item === oldValue) return newValue;
      return item;
    });
    state.data.checklistItems = newChecklist.filter(item => !!item);
  },
  setChecklistItems(state: TTaskInitialState) {
    const { newChecklistItemName } = state;
    const { checklistItems } = state.data;
    if (newChecklistItemName) {
      state.data.checklistItems = [...checklistItems, newChecklistItemName];
    }
  },
  deleteChecklistItem(
    state: TTaskInitialState,
    action: PayloadAction<{ value: string; index: number }>,
  ) {
    const { value, index: itemIndex } = action.payload;
    state.data.checklistItems = state.data.checklistItems.filter(
      (item, index) => !(index === itemIndex && item === value),
    );
  },
  setSelectedEventType(state: TTaskInitialState, action: PayloadAction<{ eventType: string }>) {
    const { eventType } = action.payload;
    if (eventType === EVENTS_TYPES.BB_INTEGRATION) {
      state.data.selectedBBIntegrationType = BB_INTEGRATION_EVENTS.BAD_FEEDBACK;
    }
    if (eventType === EVENTS_TYPES.TELEPHONY) {
      state.data.selectedTelephonyType = TELEPHONY_EVENTS.MISSED_CALL;
    }
    if (eventType === EVENTS_TYPES.YCLIENTS) {
      state.data.selectedYclientsType = YCLIENTS_EVENTS.CLOSING_WITH_DISCOUNT;
    }
    if (eventType === EVENTS_TYPES.CRM) {
      state.data.selectedCrmType = CRM_EVENTS.RECORD_DONE;
    }
    state.data.selectedEventType = eventType;
  },
  setSelectedTelephonyType(
    state: TTaskInitialState,
    action: PayloadAction<{ telephonyType: string }>,
  ) {
    const { telephonyType } = action.payload;
    state.data.selectedTelephonyType = telephonyType;
  },
  setSelectedYclientsType(
    state: TTaskInitialState,
    action: PayloadAction<{ yclientsEventType: string }>,
  ) {
    const { yclientsEventType } = action.payload;
    state.data.selectedYclientsType = yclientsEventType;
  },
  setSelectedCrmType(state: TTaskInitialState, action: PayloadAction<{ crmEventType: string }>) {
    const { crmEventType } = action.payload;
    state.data.selectedCrmType = crmEventType;
  },
  setDiscountAmount(state: TTaskInitialState, action: PayloadAction<{ value: string }>) {
    const { value } = action.payload;
    state.data.discountAmount = validateValue({
      lowLimit: 1,
      upLimit: 100,
      value,
      initialValue: INITIAL_VALUE,
    });
  },
  setClientDelayed(state: TTaskInitialState, action: PayloadAction<{ delayAmount: string }>) {
    const { delayAmount } = action.payload;
    state.data.clientDelayed = validateValue({
      lowLimit: 1,
      value: delayAmount,
      initialValue: INITIAL_VALUE,
    });
  },
  setSelectedBBIntegration(
    state: TTaskInitialState,
    action: PayloadAction<{ bbIntegrationType: string }>,
  ) {
    const { bbIntegrationType } = action.payload;
    state.data.selectedBBIntegrationType = bbIntegrationType;
  },
  setSelectedWorkStatisticsType(
    state: TTaskInitialState,
    action: PayloadAction<{ workStatisticsType: string }>,
  ) {
    const { workStatisticsType } = action.payload;
    state.data.selectedWorkStatisticsType = workStatisticsType;
  },
  setMissedCallsPercents(state: TTaskInitialState, action: PayloadAction<{ value: string }>) {
    const { value } = action.payload;
    state.data.missedCallsPercents = validateValue({
      lowLimit: 1,
      upLimit: 100,
      value,
      initialValue: INITIAL_VALUE,
    });
  },
  setThresholdWhatsappResponseRate(
    state: TTaskInitialState,
    action: PayloadAction<{ value: string }>,
  ) {
    const { value } = action.payload;
    state.data.thresholdWhatsappResponseRate = validateValue({
      lowLimit: 1,
      value,
      initialValue: INITIAL_VALUE,
    });
  },
  setThresholdLowEnrolledPercentage(
    state: TTaskInitialState,
    action: PayloadAction<{ value: string }>,
  ) {
    const { value } = action.payload;
    state.data.thresholdLowEnrolledPercentage = validateValue({
      lowLimit: 1,
      upLimit: 100,
      value,
      initialValue: INITIAL_VALUE,
    });
  },
  setSelectedClientEvent(state: TTaskInitialState, action: PayloadAction<{ clientEvent: string }>) {
    const { clientEvent } = action.payload;
    if (clientEvent === CLIENTS_EVENTS.NOT_RECORD) {
      state.data.notRecord = state.data.notRecord || INITIAL_VALUE;
    }
    if (clientEvent === CLIENTS_EVENTS.LATE_REPLY) {
      state.data.lateReply = state.data.lateReply || INITIAL_VALUE;
    }
    if (clientEvent === CLIENTS_EVENTS.LOST) {
      state.data.daysWithoutVisiting = state.data.daysWithoutVisiting || INITIAL_VALUE;
    }
    if (clientEvent === CLIENTS_EVENTS.AFTER_VISIT) {
      state.data.daysAfterVisit = state.data.daysAfterVisit || INITIAL_VALUE;
    }
    state.data.selectedClientEvent = clientEvent;
  },
  setNotRecordLimit(state: TTaskInitialState, action: PayloadAction<{ limit: string }>) {
    const { limit } = action.payload;
    state.data.notRecord = validateValue({
      lowLimit: 1,
      value: limit,
      initialValue: INITIAL_VALUE,
    });
  },
  setLateReplyLimit(state: TTaskInitialState, action: PayloadAction<{ limit: string }>) {
    const { limit } = action.payload;
    state.data.lateReply = validateValue({
      lowLimit: 1,
      value: limit,
      initialValue: INITIAL_VALUE,
    });
  },
  clearErrorsTP(state: TTaskInitialState) {
    state.isError = false;
    state.isSavingError = false;
    state.isCopyTasksError = false;
  },
  clearSuccessTP(state: TTaskInitialState) {
    state.isSuccess = false;
    state.isSavingSuccess = false;
    state.isCopyTasksSuccess = false;
  },
  setAbandonedTextareaDataTP(
    state: TTaskInitialState,
    action: PayloadAction<{
      name: string;
      selectionStart: number;
      selectionEnd: number;
    }>,
  ) {
    const { name, selectionStart, selectionEnd } = action.payload;
    state.blurFromTextareaName = name;
    state.selectionEnd = selectionEnd;
    state.selectionStart = selectionStart;
  },
  addKeyInTextareaTP(state: TTaskInitialState, action: PayloadAction<{ innerText: string }>) {
    const { innerText } = action.payload;
    const { blurFromTextareaName, selectionEnd, selectionStart } = state;
    // eslint-disable-next-line
    // @ts-ignore
    const textAreaValue = String(state.data[blurFromTextareaName]);
    // eslint-disable-next-line
    // @ts-ignore
    state.data[blurFromTextareaName] =
      textAreaValue.substring(0, selectionEnd) +
      innerText +
      textAreaValue.substring(selectionStart);
  },
  clearFilialsToCopy(state: TTaskInitialState): void {
    state.rejectedFilialsToCopy = [];
    state.filialsToCopy = [];
    state.isCopyTasksError = false;
    state.isCopyTasksSuccess = false;
    state.isCopyTasksLoading = false;
  },
  setFilialsToCopy(state: TTaskInitialState, action: PayloadAction<TFilialData[]>): void {
    state.filialsToCopy = action.payload;
  },
  setData(state: TTaskInitialState, action: PayloadAction<{ data: TTaskInfo[] }>) {
    state.taskInfoData = action.payload.data;
  },
  setIsRefreshTl(state: TTaskInitialState, action: PayloadAction<boolean>) {
    state.isRefresh = action.payload;
  },
  setTaskIdInProgress(state: TTaskInitialState, action: PayloadAction<string>) {
    state.taskIdInProgress = action.payload;
  },
  addNotesToTask(state: TTaskInitialState, action: PayloadAction<TNoteItem[]>) {
    state.data.notes = action.payload;
  },
  deleteAddedNote(state: TTaskInitialState, action: PayloadAction<string>) {
    state.data.notes = state.data.notes.filter(note => note.id !== action.payload);
  },
  updateNotesCounter(
    state: TTaskInitialState,
    action: PayloadAction<{ taskId: string; count: number }>,
  ) {
    const { count, taskId } = action.payload;

    state.taskInfoData = state.taskInfoData.map(task => {
      if (task.id === taskId) {
        return { ...task, notes: count };
      }
      return task;
    });
  },
  clearTaskListErrors(state: TTaskInitialState) {
    state.isError = false;
    state.isDeletingError = false;
    state.isTaskSwitchingError = false;
  },
  clearTaskListDeleteSuccess(state: TTaskInitialState) {
    state.isDeletingSuccess = false;
  },
};

export const getTaskDataReducer = {
  pending: (state: TTaskInitialState) => {
    state.isError = false;
    state.isLoading = true;
    state.isSuccess = false;
    state.isSavingSuccess = false;
  },
  fulfilled: (state: TTaskInitialState, action: PayloadAction<TTaskData | void>) => {
    if (action.payload) {
      state.data = action.payload;
    } else {
      state.data = newTaskData;
    }
    if (state.isRetries) {
      state.retries = 1;
      state.isRetries = false;
    }
    state.isSuccess = true;
    state.isLoading = false;
  },
  rejected: (state: TTaskInitialState, action: PayloadAction<TRejectActionPayload>) => {
    if (action.payload) {
      if (REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
        const { detail } = action.payload;
        if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED && state.retries > 0) {
          state.isRetries = true;
          state.retries -= 1;
        } else {
          state.isError = true;
          state.isLoading = false;
        }
      }
      if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
        const { status } = action.payload;
        if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
          state.isAccessDenied = true;
        } else {
          state.isError = true;
        }
        state.isLoading = false;
      }
    } else {
      state.isError = true;
      state.isLoading = false;
    }
  },
};

export const saveTaskReducer = {
  pending: (state: TTaskInitialState) => {
    state.isError = false;
    state.isSaving = true;
    state.isSavingError = false;
    state.isSavingSuccess = false;
  },
  fulfilled: (state: TTaskInitialState, action: PayloadAction<TTaskData>) => {
    state.isSaving = false;
    state.data = action.payload;
    state.isSavingSuccess = true;
    state.successToastMessage = COMMON_TOAST_MESSAGES.SAVE_SUCCESS;
  },
  rejected: (state: TTaskInitialState, action: PayloadAction<TRejectActionPayload>) => {
    if (action.payload && REJECT_RESPONSE_KEY.STATUS in action.payload) {
      const { status } = action.payload;
      if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
        state.isAccessDenied = true;
      } else {
        state.isSavingError = true;
      }
      state.isSaving = false;
    } else if (action.payload && REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
      const { detail } = action.payload;
      if (detail !== REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        state.isSaving = false;
        state.isSavingError = true;
      }
    } else {
      state.isSaving = false;
      state.isSavingError = true;
    }
    state.errorToastMessage = COMMON_TOAST_MESSAGES.COMMON_SAVE_ERROR_MESSAGE;
  },
};

export const getListOfServicesTSReducer = {
  fulfilled: (
    state: TTaskInitialState,
    action: PayloadAction<{ data: TStaffListItem[]; categoryName: string } | void>,
  ) => {
    if (action.payload) {
      const { data, categoryName } = action.payload;
      const newStaffList = data.map(item => [String(item.id), item.name]);
      if (newStaffList.length) {
        state.categoriesDataList[categoryName] = Object.fromEntries(newStaffList);
      } else {
        state.categoriesDataList[categoryName] = { '-': 'Нет записей' };
      }
    }
  },
  rejected: (state: TTaskInitialState, action: PayloadAction<TRejectActionPayload>) => {
    if (action.payload && REJECT_RESPONSE_KEY.STATUS in action.payload) {
      const { status } = action.payload;
      if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
        state.isAccessDenied = true;
      } else {
        state.isGettingStaffListError = true;
      }
    }
    if (action.payload && REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
      const { detail } = action.payload;
      if (detail !== REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        state.isGettingStaffListError = true;
      }
    }
  },
};

// export const getListOfStaffTSReducer = {
//   fulfilled: (
//     state: TTaskInitialState,
//     action: PayloadAction<{ data: TStaffData; categoryName: string } | void>,
//   ) => {
//     if (action.payload) {
//       const { data, categoryName } = action.payload;
//
//       const { admins, masters } = data;
//
//       const adminsHeaderInDropdown = ['admins', 'АДМИНЫ'];
//
//       const newAdminsList = admins.map(item => [item.name, String(item.id)]);
//
//       newAdminsList.unshift(adminsHeaderInDropdown);
//
//       const mastersHeaderInDropdown = [
//         ['divider1', ''],
//         ['masters', 'МАСТЕРА'],
//       ];
//
//       const newMatersList = masters.map(item => [item.name, String(item.id)]);
//
//       newMatersList.unshift(...mastersHeaderInDropdown);
//
//       const newStaffList = [...newAdminsList, ...newMatersList];
//
//       if (newStaffList.length) {
//         state.categoriesDataList[categoryName] = Object.fromEntries(newStaffList);
//       } else {
//         state.categoriesDataList[categoryName] = { '-': 'Нет записей' };
//       }
//     }
//   },
//   rejected: (state: TTaskInitialState, action: PayloadAction<TRejectActionPayload>) => {
//     if (action.payload && REJECT_RESPONSE_KEY.STATUS in action.payload) {
//       const { status } = action.payload;
//       if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
//         state.isAccessDenied = true;
//       } else {
//         state.isGettingStaffListError = true;
//       }
//     }
//     if (action.payload && REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
//       const { detail } = action.payload;
//       if (detail !== REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
//         state.isGettingStaffListError = true;
//       }
//     }
//   },
// };

export const saveTasksCopyReducer = {
  pending: (state: TTaskInitialState) => {
    state.isCopyTasksError = false;
    state.isCopyTasksLoading = true;
  },
  fulfilled: (
    state: TTaskInitialState,
    action: PayloadAction<
      PromiseSettledResult<
        AxiosResponse<TTaskDataResponse | TRequestStatus> | PromiseRejectedResult
      >[]
    >,
  ) => {
    state.isCopyTasksLoading = false;
    const data = [...action.payload];

    if (data.some(item => item.status === PROMISE_STATUS.REJECTED)) {
      const rejectedData = data.filter(item => item.status === PROMISE_STATUS.REJECTED);
      const rejectedFilialsToCopy = rejectedData.map(item =>
        item.status === PROMISE_STATUS.REJECTED ? item.reason.config.params.acc_id : null,
      );
      const errorMessage = `Задача скопирована в ${
        state.filialsToCopy.length - rejectedFilialsToCopy.length
      } ${numWord(state.filialsToCopy.length - rejectedFilialsToCopy.length, [
        'филиал',
        'филиала',
        'филиалов',
      ])}`;

      state.rejectedFilialsToCopy = rejectedFilialsToCopy;
      state.isCopyTasksError = true;
      state.errorToastMessage = errorMessage;
    } else {
      const filialToCopyLength = state.filialsToCopy.length;
      const successMessage = `Задача скопирована в ${filialToCopyLength} ${numWord(
        filialToCopyLength,
        ['филиал', 'филиала', 'филиалов'],
      )}`;
      state.isCopyTasksSuccess = true;
      state.successToastMessage = successMessage;
    }
  },
  rejected: (state: TTaskInitialState) => {
    state.isCopyTasksLoading = false;
    state.isCopyTasksError = true;
  },
};

export const getTasksListReducer = {
  pending: (state: TTaskInitialState) => {
    const { isRefresh } = state;
    if (!isRefresh) {
      state.isLoading = true;
    }
    state.isError = false;
    state.isSuccess = false;
    state.isDeletingSuccess = false;
  },
  fulfilled: (
    state: TTaskInitialState,
    action: PayloadAction<{
      data: TTaskInfo[];
      order: string[];
    } | void>,
  ) => {
    if (action.payload) {
      const { data, order } = action.payload;
      const unfilteredData = data.map(item => item.id);
      state.sortedTasksList = Array.from(new Set([...order, ...unfilteredData]));
      state.taskInfoData = data;
    } else {
      state.taskInfoData = [];
    }
    if (state.isRetries) {
      state.retries = 1;
      state.isRetries = false;
    }
    state.isLoading = false;
    state.isRefresh = false;
  },
  rejected: (state: TTaskInitialState, action: PayloadAction<TRejectActionPayload>) => {
    if (action.payload) {
      if (REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
        const { detail } = action.payload;
        if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED && state.retries > 0) {
          state.isRetries = true;
          state.retries = state.retries--;
        } else {
          state.isError = true;
          state.isLoading = false;
        }
      } else if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
        const { status } = action.payload;
        if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
          state.isAccessDenied = true;
        } else {
          state.isError = true;
        }
        state.isLoading = false;
      } else {
        state.isError = true;
        state.isLoading = false;
      }
    }
    state.isRefresh = false;
  },
};

export const switchTaskReducer = {
  pending: (state: TTaskInitialState) => {
    state.isTaskSwitching = true;
    state.isTaskSwitchingError = false;
  },
  fulfilled: (state: TTaskInitialState, action: PayloadAction<string>) => {
    const tasksId = action.payload;
    state.taskInfoData =
      state.taskInfoData?.map(item => {
        if (item.id === tasksId) {
          return { ...item, active: !item.active };
        }
        return item;
      }) || [];
    state.taskIdInProgress = '';
    state.isTaskSwitching = false;
    state.isTaskSwitchingSuccess = true;
  },
  rejected: (state: TTaskInitialState, action: PayloadAction<TRejectActionPayload>) => {
    if (action.payload && REJECT_RESPONSE_KEY.STATUS in action.payload) {
      const { status } = action.payload;
      if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
        state.isAccessDenied = true;
      } else {
        state.isTaskSwitchingError = true;
      }
      state.isTaskSwitching = false;
    } else if (action.payload && REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
      const { detail } = action.payload;
      if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        state.isTaskSwitching = false;
      } else {
        state.isTaskSwitching = false;
        state.isTaskSwitchingError = true;
      }
    } else {
      state.isTaskSwitching = false;
      state.isTaskSwitchingError = true;
    }
    state.errorToastMessage = COMMON_TOAST_MESSAGES.COMMON_ERROR_MESSAGE;
  },
};

export const deleteTaskReducer = {
  pending: (state: TTaskInitialState) => {
    state.isError = false;
    state.isDeleting = true;
    state.isDeletingSuccess = false;
  },
  fulfilled: (state: TTaskInitialState) => {
    state.isDeleting = false;
    state.isDeletingSuccess = true;
    state.successToastMessage = `Шаблон ${state.taskIdInProgress} \n успешно удален`;
  },
  rejected: (state: TTaskInitialState, action: PayloadAction<TRejectActionPayload>) => {
    if (action.payload && REJECT_RESPONSE_KEY.STATUS in action.payload) {
      const { status } = action.payload;
      if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
        state.isAccessDenied = true;
      } else {
        state.isDeletingError = true;
      }
      state.isDeleting = false;
    }
    if (action.payload && REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
      const { detail } = action.payload;
      if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        state.isDeleting = false;
      } else {
        state.isDeleting = false;
        state.isDeletingError = true;
      }
    }
    if (!action.payload) {
      state.isDeleting = false;
      state.isDeletingError = true;
    }
    state.errorToastMessage = COMMON_TOAST_MESSAGES.COMMON_ERROR_MESSAGE;
  },
};
