import { Descendant } from 'slate';
import { TNote } from '@redux/note/zod';
import { NEW_NOTE_ID } from '@const/notes';
import { COMMON_FOLDER } from '@redux/noteList/mockData';

export const initialValue: Descendant[] = [
  {
    // eslint-disable-next-line
    // @ts-ignore
    type: 'paragraph',
    children: [{ text: '' }],
  },
];

export const newNote: TNote = {
  id: NEW_NOTE_ID,
  title: '',
  tags: [],
  isPrivate: false,
  folder: COMMON_FOLDER,
  description: JSON.stringify(initialValue),
};

export const noteMockData: TNote = {
  id: '1111',
  title: 'Скрипт для админов по новым клиентам',
  description: JSON.stringify(initialValue),
  tags: [
    {
      id: '1',
      title: '#Скрипты',
    },
    {
      id: '2',
      title: '#Админы',
    },
  ],
  folder: null,
  isPrivate: false,
};
