import React, { memo, useCallback, useEffect, useState } from 'react';
//
import { H1 } from '@atoms/h1';
import { Loader } from '@blocks/loader';
import { TPayData, TPayLinkData } from '@models/Pay';
import { storageDb } from '@api/storageApi';
import { PayCards } from '@components/payCards';
import { TOnChangeHandler } from '@shared/types';
import { AppDrawer } from '@components/appDrawer';
import { PayButtons } from '@components/payButtons';
import { ErrorMessage } from '@blocks/errorMessage';
import { downloadByLink, log } from '@helpers/index';
import { PAY_PAGE_ERROR_MESSAGES } from '@const/pay';
import { FilialPayData } from '@blocks/filialPayData';
import { ToastsMessages } from '@components/toastsMessages';
import { GetBillModal } from '@components/billDownloadModal';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { PayHistoryTable } from '@components/payHistoryTable';
import { setAppDrawerData, setShowAppDrawer } from '@redux/App/slice';
import { getAppData, getPayPageState, getAccountsData } from '@redux/selectors';
import { clearPayToastMessages, setPayErrorToastMessage } from '@redux/Pay/slice';
import { getInvoiceThunk, getPayerDataThunk, getPayLinkThunk } from '@redux/Pay/thunk';

interface IPayProps {
  /**
   * Флаг ошибки при запросе данных для страницы
   * @param {boolean}
   */
  isError: boolean;
  /**
   * Флаг показывает что запрос данных для страницы в процессе
   * @param {boolean}
   */
  isLoading: boolean;
  /**
   * Данные страницы
   * @param {TPayData}
   */
  data: TPayData;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const PayTemplate = memo(({ isError, isLoading, data, className = '' }: IPayProps) => {
  const [isOpenGetBillModal, setIsOpenGetBillModal] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { showAppDrawer } = useAppSelector(getAppData);
  const {
    payerData,
    isGetLinkLoading,
    selectedCardNumber,
    isGetInvoiceLoading,
    selectedCardTotalAmount,
    selectedCardMonthAmount,
    errorToastMessage,
    successToastMessage,
  } = useAppSelector(getPayPageState);
  const { selectedFilial } = useAppSelector(getAccountsData);

  // Читаем из LS имя филиала, имя сохраняется при выборе филиала на странице /accounts
  const { filialName } = storageDb.getSelectedFilialData();

  // Деструктуризируем data
  const {
    branch,
    amount,
    discount,
    payStatus,
    payTillDate,
    numberOfMonth,
    branchEmployees,
    isAutoPayEnable,
    numberOfGiftWeek,
    paymentData,
    numberOfPriceWithBonus,
  } = data;

  const onCloseDrawerHandler = () => {
    dispatch(setShowAppDrawer(false));
    dispatch(setAppDrawerData({ title: '', description: '' }));
  };

  // Применяет введенный промокод
  const applyPromoCode = () => {
    // console.log(promoCode);
  };

  const setPromoCodeHandler: TOnChangeHandler = event => {
    const { value } = event.target;
    log(value);
  };

  const getAutoPayLinkHandler = () => {
    // console.log('getAutoPayLinkHandler');
  };

  // Функция осуществляет переход по ссылке
  const changePageOnClick = () => {
    dispatch(
      getPayLinkThunk({
        months: selectedCardMonthAmount,
        onSuccess: (linkData: TPayLinkData) => {
          window.open(linkData.link, '_blank');
        },
      }),
    );
  };

  const getBillModalOpen = () => {
    if (!payerData.inn) {
      dispatch(setPayErrorToastMessage(PAY_PAGE_ERROR_MESSAGES.INN_ERROR));
    } else {
      setIsOpenGetBillModal(true);
    }
  };

  const downloadBillHandler = (pay_all = false) => {
    dispatch(
      getInvoiceThunk({
        months: selectedCardMonthAmount,
        pay_all,
        in_telegram: false,
        onSuccess: (linkData: TPayLinkData) => {
          downloadByLink(linkData.link);
          setIsOpenGetBillModal(false);
        },
      }),
    );
  };

  const sendToTGBillHandler = (pay_all = false) => {
    dispatch(
      getInvoiceThunk({
        months: selectedCardMonthAmount,
        pay_all,
        in_telegram: true,
        onSuccess: () => {
          setIsOpenGetBillModal(false);
        },
      }),
    );
  };

  const clearToastMessages = useCallback(() => {
    dispatch(clearPayToastMessages());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getPayerDataThunk());

    return () => {
      clearToastMessages();
    };
  }, [dispatch, clearToastMessages]);

  if (isLoading) return <Loader className='h-full' />;

  if (isError) {
    return <ErrorMessage />;
  }

  return (
    <div>
      <div className={`flex flex-col min-h-[100%] ${className}`}>
        <H1 className='mb-10'>Оплата</H1>
        <div className='bg-gray1 rounded-lg px-6 pt-6 pb-2 max-w-[57.5rem]'>
          <FilialPayData
            amount={amount}
            branch={branch}
            payStatus={payStatus}
            filialName={filialName}
            payTillDate={payTillDate}
            branchEmployees={branchEmployees}
            isAutoPayEnable={isAutoPayEnable}
            discount={discount}
            bbIntegration={selectedFilial?.beautyBotIntegration || false}
            className='mb-4'
          />
          <h2 className='font-bold text-h6Mobile mb-4'>Оплата</h2>
          <hr className='border-none bg-quartz1 h-0.5 mb-4' />
          {isAutoPayEnable ? null : (
            <div className='mb-6'>
              <PayCards
                numberOfMonth={numberOfMonth}
                amount={numberOfPriceWithBonus}
                numberOfGiftWeek={numberOfGiftWeek}
                className='mb-6'
              />
              <PayButtons
                isHidePromoCodeBlock
                promoCode=''
                createBill
                applyPromoCode={applyPromoCode}
                isLoading={isGetLinkLoading}
                isAutoPayEnable={isAutoPayEnable}
                getBillModalOpen={getBillModalOpen}
                onChangeHandler={setPromoCodeHandler}
                changePageOnClick={changePageOnClick}
                selectedCardNumber={selectedCardNumber}
                getAutoPayLinkHandler={getAutoPayLinkHandler}
                isFetchingInvoiceLink={false}
                selectedCardTotalAmount={selectedCardTotalAmount}
              />
              {isOpenGetBillModal ? (
                <GetBillModal
                  totalAmount={selectedCardTotalAmount}
                  filialName={filialName}
                  branchEmployees={branchEmployees}
                  monthAmount={String(selectedCardMonthAmount)}
                  sendBillInTgCallback={sendToTGBillHandler}
                  closeModalCallback={() => setIsOpenGetBillModal(false)}
                  downloadBillCallback={downloadBillHandler}
                  isLoading={isGetInvoiceLoading}
                />
              ) : null}
            </div>
          )}
        </div>
        <PayHistoryTable filteredData={paymentData} className='max-w-[57.5rem]' />
      </div>
      {/* <NewBanner className='hidden lg:flex max-w-[23.4375rem] h-fit w-full' /> */}
      <AppDrawer showAppDrawer={showAppDrawer} onCloseHandler={onCloseDrawerHandler} />
      <ToastsMessages
        errorMessage={errorToastMessage}
        isShowErrorToast={!!errorToastMessage}
        successMessage={successToastMessage}
        isShowSuccessToast={!!successToastMessage}
        clearErrorCallback={clearToastMessages}
        clearSuccessStatusCallback={clearToastMessages}
      />
    </div>
  );
});
