import React from 'react';
import { TFolderItem } from '@redux/noteList/zod';
import { ReactComponent as FolderIcon } from '@img/notes/folder_close.svg';

type TProps = {
  /**
   * Флаг активности папки
   * @param {boolean}
   */
  isActive: boolean;
  /**
   * Данные папки
   * @param {TFolderItem}
   */
  folderData: TFolderItem;
  /**
   * Устанавливает активную папку
   * @param {() => void}
   */
  setActiveFolder: () => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const NoteFolderItem = ({
  isActive,
  folderData,
  setActiveFolder,
  className = '',
}: TProps) => {
  const { title, numberOfNotes } = folderData;

  const folderStyle = 'border-varden bg-varden';
  const activeFolderStyle = 'border-btnGreen bg-aquaSpring';
  const textStyle = 'font-medium text-tooltips text-black leading-5 break-words';

  return (
    <div
      onClick={setActiveFolder}
      className={`group border ${
        isActive ? activeFolderStyle : folderStyle
      } hover:bg-aquaSpring rounded-lg cursor-pointer flex items-center justify-between px-4 py-3 break-all ${className}`}>
      <div className='flex items-center'>
        <FolderIcon
          fill={isActive ? '#43A047' : '#D6981B'}
          className='mr-2 group-hover:fill-[#65B168] min-w-[1.25rem]'
        />
        <span className={textStyle}>{title}</span>
      </div>
      {numberOfNotes ? <span className={textStyle}>{numberOfNotes}</span> : null}
    </div>
  );
};
