import React, { memo, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
//
import { reportEmptyData } from '@data/index';
import { NoAccess } from '@components/noAccess';
import { ReportTemplate } from '@templates/report';
import { QUERY_PARAMS } from '@const/apiConstants';
import { getReportById } from '@redux/Report/thunk';
import { setReportData } from '@redux/Report/slice';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { getAccountsData, getReportPageData } from '@redux/selectors';

const ReportPage = memo(() => {
  const dispatch = useAppDispatch();

  const {
    isError,
    isSaving,
    isLoading,
    isRetries,
    isSavingError,
    isAccessDenied,
    isSavingSuccess,
    errorToastMessage,
    successToastMessage,
    telegramRecipientsNewValue,
    whatsappRecipientsNewValue,
    data,
  } = useAppSelector(getReportPageData);

  const [searchParams] = useSearchParams();

  // Получаем id задачи из searchParams
  const reportId = searchParams.get(QUERY_PARAMS.STATS_ID);

  // Получаем id филиала из searchParams
  const accId = searchParams.get(QUERY_PARAMS.ACC_ID);

  // Получаем объект с данными выбранного филиала
  const { selectedFilial } = useAppSelector(getAccountsData);

  // Эффект делает запрос за данными задачи если из URL удалось взять id задачи
  // в противном случае, в state загружается пустая/новый шаблон отчета
  useEffect(() => {
    if (reportId && accId) {
      dispatch(getReportById({ id: reportId }));
    } else {
      dispatch(setReportData({ data: reportEmptyData }));
    }
  }, [reportId, accId, dispatch]);

  // Эффект делает повторный запрос за данными отчета при ошибке токена
  useEffect(() => {
    if (isRetries) {
      if (reportId && accId) {
        setTimeout(() => dispatch(getReportById({ id: reportId })), 100);
      } else {
        dispatch(setReportData({ data: reportEmptyData }));
      }
    }
  }, [isRetries, reportId, accId, dispatch]);

  return (
    <>
      {isAccessDenied ? <NoAccess /> : null}
      {!isAccessDenied ? (
        <ReportTemplate
          data={data}
          isError={isError}
          isSaving={isSaving}
          isLoading={isLoading}
          isSavingError={isSavingError}
          isSavingSuccess={isSavingSuccess}
          accId={selectedFilial?.accId || ''}
          errorToastMessage={errorToastMessage}
          successToastMessage={successToastMessage}
          telegramRecipientsNewValue={telegramRecipientsNewValue}
          whatsappRecipientsNewValue={whatsappRecipientsNewValue}
        />
      ) : null}
    </>
  );
});

export default ReportPage;
