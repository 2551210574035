import { z } from 'zod';

export const NoteTagResponseSchema = z.object({
  id: z.string(),
  title: z.string(),
  company_id: z.number(),
  created_at: z.string(),
  updated_at: z.string().nullish(),
});

export const InNoteTagItemSchema = z.object({
  id: z.string(),
  title: z.string(),
});

export type TInNoteTag = z.infer<typeof InNoteTagItemSchema>;

export const InNoteFolderItemSchema = z.object({
  id: z.string(),
  title: z.string(),
});

export type TNoteTagResponse = z.infer<typeof NoteTagResponseSchema>;

export const NoteFolderResponseSchema = z.object({
  id: z.string(),
  title: z.string(),
  company_id: z.number(),
  created_at: z.string(),
  updated_at: z.string().nullish(),
});

export type TNoteFolderResponse = z.infer<typeof NoteFolderResponseSchema>;

export const attached_templatesSchema = z.object({
  id: z.string(),
  title: z.string(),
});

export const NoteResponseDataSchema = z.object({
  id: z.string(),
  body: z.string(),
  title: z.string(),
  author: z.number(),
  company_id: z.number(),
  created_at: z.string(),
  is_private: z.boolean(),
  updated_at: z.string().nullish(),
  tags: InNoteTagItemSchema.array(),
  folder: InNoteFolderItemSchema.nullish(),
});

export const NoteResponseSchema = z.object({
  note: NoteResponseDataSchema,
  attached_templates: attached_templatesSchema.array(),
});

export const NoteListPageDataSchema = z.object({
  notes: NoteResponseDataSchema.array(),
  tags: NoteTagResponseSchema.array(),
  folders: NoteFolderResponseSchema.array(),
});

export type TNoteListPageData = z.infer<typeof NoteListPageDataSchema>;

export type TNoteResponseData = z.infer<typeof NoteResponseDataSchema>;

export type TOneNoteResponseData = z.infer<typeof NoteResponseSchema>;

export const NoteToCreateDataSchema = z.object({
  body: z.string(),
  title: z.string(),
  is_private: z.boolean(),
  tags: InNoteTagItemSchema.array(),
  folder: InNoteFolderItemSchema.nullish(),
});

export type TNoteToCreateData = z.infer<typeof NoteToCreateDataSchema>;
