import React, { memo, useCallback, useEffect, useState } from 'react';
//
import { Chips } from '@uikit/Chips';
import { createPortal } from 'react-dom';
import { Overlay } from '@atoms/overlay';
import { useAppDispatch } from '@hooks/index';
import { TNoteListStatuses, TNoteTag } from '@redux/noteList/zod';
import { NoteTags } from '@components/noteTags';
import { deleteTagThunk } from '@redux/noteList/thunk';
import { NoteDeleteModal } from '@components/noteDeleteModal';
import { SearchInputWithButton } from '@components/searchInputWithButton';
import { TDeleteTag, TOnChangeHandler, TSetTagInSearchString } from '@shared/types';
import {
  hideSearchByTag,
  setShowSearchByTag,
  removeSelectedToSearchTag,
} from '@redux/noteList/slice';

type TProps = {
  /**
   * Статус запросов на страницы
   * @param {TNoteListStatuses}
   */
  status: TNoteListStatuses;
  /**
   * Флаг режима удаления тегов
   * @param {boolean}
   */
  isTagDeleteMode: boolean;
  /**
   * Список всех тегов на филиале
   * @param {TNoteTag[]}
   */
  tags: TNoteTag[];
  /**
   * Список всех тегов на филиале
   * @param {TNoteTag[]}
   */
  selectedTags: TNoteTag[];
  /**
   * Строка поиска в инпут
   * @param {string}
   */
  searchString: string;
  /**
   * Флаг открытия блока работы с тэгами
   * @param {boolean}
   */
  isSearchByTag: boolean;
  /**
   * Callback для очистки поисковой строки
   * @param {() => void}
   */
  clearSearchStringHandler: () => void;
  /**
   * Callback для обработки изменения поисковой строки
   * @param {TOnChangeHandler}
   */
  setSearchStringHandler: TOnChangeHandler;
  /**
   * Устанавливает тэг в поисковую строку
   * @param {TSetTagInSearchString}
   */
  setTagInSearchString: TSetTagInSearchString;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const NotesSearch = memo(
  ({
    tags,
    status,
    searchString,
    selectedTags,
    isSearchByTag,
    isTagDeleteMode,
    setTagInSearchString,
    setSearchStringHandler,
    clearSearchStringHandler,
    className = '',
  }: TProps) => {
    const dispatch = useAppDispatch();
    const [tagsToRender, setTagsToRender] = useState<TNoteTag[]>(tags);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [tagToDelete, setTagToDelete] = useState<TNoteTag | null>(null);

    useEffect(() => {
      const filteredTags = tags.filter(
        tag => !selectedTags.find(selectedTag => selectedTag.id === tag.id),
      );

      setTagsToRender(filteredTags);
    }, [selectedTags, tags]);

    const showDeleteModalHandler: TDeleteTag = useCallback(
      tagData => () => {
        setTagToDelete(tagData);
        setShowDeleteModal(true);
      },
      [],
    );

    const hideDeleteTagModal = useCallback(() => {
      setTagToDelete(null);
      setShowDeleteModal(false);
    }, []);

    const deleteTagHandler = useCallback(() => {
      if (tagToDelete) {
        dispatch(deleteTagThunk({ tagId: tagToDelete.id, modalCloseCallback: hideDeleteTagModal }));
      }
    }, [hideDeleteTagModal, tagToDelete, dispatch]);

    const showTags = useCallback(() => {
      if (!isSearchByTag && tags.length) {
        dispatch(setShowSearchByTag(true));
      }
    }, [isSearchByTag, tags.length, dispatch]);

    const hideTags = useCallback(() => {
      dispatch(hideSearchByTag());
    }, [dispatch]);

    const unSelectTagHandler = useCallback(
      (tagId: string) => () => {
        dispatch(removeSelectedToSearchTag(tagId));
      },
      [dispatch],
    );

    const selectedTagsToRender = selectedTags.map(({ title, id }) => (
      <Chips
        key={id}
        round
        noClick
        removable
        tag={title}
        type='dense'
        color='success'
        disabled={false}
        withTick={false}
        withNumber={null}
        withAvatar={null}
        onRemove={unSelectTagHandler(id)}
        className='cursor-pointer mr-2 mb-2'
      />
    ));

    return (
      <div className={`${isSearchByTag && !showDeleteModal ? 'z-[51]' : ''} ${className}`}>
        <div onClick={showTags} className={isSearchByTag ? 'mb-4' : ''}>
          <SearchInputWithButton
            buttonText=''
            hideSearchButton
            placeholder='Найти'
            disabled={isTagDeleteMode}
            searchString={searchString}
            disableSearchButton={false}
            setSearchStringHandler={setSearchStringHandler}
            clearSearchStringHandler={clearSearchStringHandler}
            startSearchHandler={() => {
              // log('startSearchHandler');
            }}
            className={isSearchByTag ? 'mb-2' : 'mb-0'}
          />
          {isSearchByTag ? <div>{selectedTagsToRender}</div> : null}
        </div>
        {isSearchByTag ? (
          <NoteTags
            tags={tagsToRender}
            isTagDeleteMode={isTagDeleteMode}
            tagDeleteHandler={showDeleteModalHandler}
            setTagInSearchString={setTagInSearchString}
          />
        ) : null}
        <NoteDeleteModal
          isOpen={showDeleteModal}
          cancelButtonText='Отмена'
          confirmButtonText='Удалить'
          disableButton={status !== 'success'}
          closeModalHandler={hideDeleteTagModal}
          callbackToConfirmButton={deleteTagHandler}
          showLoaderInButton={status === 'tagDeleting'}
          dialogTitle={`Удалить тег «${tagToDelete?.title}»?`}
          dialogDescription={`Тег «${tagToDelete?.title}» будет удалён из всех заметок, где он был использован.`}
        />
        {isSearchByTag && !showDeleteModal
          ? createPortal(<Overlay onClickCallback={hideTags} />, document.body)
          : null}
      </div>
    );
  },
);
