import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';
//
import { Icon } from '@atoms/icon';
import { PATH_NAMES } from '@const/httpConst';

interface IReportPageNavLinkBlockProps {
  /**
   * Id отчета, если id нет изменится надпись на "Новый шаблон отчёта"
   * @param {string}
   */
  id: string;
  /**
   * Id филиала используется для формирования ссылки
   * @param {string}
   */
  accId: string;
  /**
   * Флаг состояния загрузки, если идет загрузка надпись меняется на "Шаблон..."
   */
  isLoading: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const ReportPageNavLinkBlock = memo(
  ({ id, accId, isLoading, className = '' }: IReportPageNavLinkBlockProps) => (
    <div className={`flex flex-wrap items-center ${className}`}>
      <NavLink
        to={`${PATH_NAMES.REPORTS}?acc_id=${accId}`}
        className='flex items-center text-gray2 hover:no-underline hover:text-gray2 tracking-[0.0275em] mr-[1.125rem]'>
        Отчеты на телефон
        <Icon variant='chevronRight' className='ml-[1.125rem]' />
      </NavLink>
      <p className='flex items-center m-0 text-blackText tracking-[0.022em]'>
        <Icon variant='document' className='mr-2.5 h-5' />
        {!isLoading ? (id ? `Шаблон №${id}` : 'Новый шаблон отчёта') : 'Шаблон...'}
      </p>
    </div>
  ),
);
