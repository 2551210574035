import React, { memo, useCallback } from 'react';
//
import { Chips } from '@uikit/Chips';
import { Button } from '@uikit/Button';
import { useAppDispatch } from '@hooks/index';
import { TNoteTag } from '@redux/noteList/zod';
import { setTagDeleteMode } from '@redux/noteList/slice';
import { TDeleteTag, TSetTagInSearchString } from '@shared/types';

type TProps = {
  /**
   * Флаг режима удаления тегов
   * @param {boolean}
   */
  isTagDeleteMode: boolean;
  /**
   * Список всех тегов на филиале
   * @param {TNoteTag[]}
   */
  tags: TNoteTag[];
  /**
   * Удаляет тег из базы тегов
   * @param {TDeleteTag}
   */
  tagDeleteHandler: TDeleteTag;
  /**
   * Устанавливает тэг в поисковую строку
   * @param {TSetTagInSearchString}
   */
  setTagInSearchString: TSetTagInSearchString;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const NoteTags = memo(
  ({ tags, isTagDeleteMode, setTagInSearchString, tagDeleteHandler, className = '' }: TProps) => {
    const dispatch = useAppDispatch();

    const editModeToggle = useCallback(() => {
      dispatch(setTagDeleteMode(!isTagDeleteMode));
    }, [isTagDeleteMode, dispatch]);

    const tagsToRender = tags.map(tag => (
      <div key={tag.id} onClick={!isTagDeleteMode ? setTagInSearchString(tag.id) : undefined}>
        <Chips
          round
          noClick
          type='dense'
          color='success'
          tag={tag.title}
          disabled={false}
          withTick={false}
          withNumber={null}
          withAvatar={null}
          removable={isTagDeleteMode}
          onRemove={tagDeleteHandler(tag)}
          className='cursor-pointer mr-2 mb-2'
        />
      </div>
    ));

    return (
      <div className={className}>
        <div className='flex items-center justify-between mb-2'>
          <span
            className={`font-medium text-small_text leading-4 tracking-[0.168em] ${
              isTagDeleteMode ? 'text-[#F44336]' : 'text-gray3'
            }`}>
            {isTagDeleteMode ? 'УДАЛЕНИЕ ТЕГОВ' : 'ПОИСК ПО ТЕГАМ'}
          </span>
          <Button
            type='icon'
            color='default'
            view='outlined'
            onClick={editModeToggle}
            icon={isTagDeleteMode ? 'tick' : 'edit'}
          />
        </div>
        <div className='flex flex-wrap'>{tagsToRender}</div>
      </div>
    );
  },
);

NoteTags.displayName = 'NoteTags';
