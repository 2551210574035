import React from 'react';

export const highlightSearch = ({
  searchString,
  targetString,
  highlightColor,
}: {
  searchString: string;
  targetString: string;
  highlightColor: string;
}): React.ReactNode => {
  if (!searchString || !targetString) {
    return targetString;
  }

  const searchIndex = targetString.toLowerCase().indexOf(searchString.toLowerCase());

  if (searchIndex === -1) {
    return targetString;
  }

  const beforeSearch = targetString.slice(0, searchIndex);
  const matchedSearch = targetString.slice(searchIndex, searchIndex + searchString.length);
  const afterSearch = targetString.slice(searchIndex + searchString.length);

  return (
    <span>
      {beforeSearch}
      <span style={{ background: highlightColor }}>{matchedSearch}</span>
      {afterSearch}
    </span>
  );
};
