import React, { memo, useCallback, useEffect } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { Icon } from '@blueprintjs/core';
//
import { H1 } from '@atoms/h1';
import { Button } from '@uikit/Button';
import { PATH_NAMES } from '@const/httpConst';
import { NoAccess } from '@components/noAccess';
import { QUERY_PARAMS } from '@const/apiConstants';
import { ErrorMessage } from '@blocks/errorMessage';
import { useWindowWidth } from '@hooks/useWindowWidth';
import { getNoteListPageData } from '@redux/noteList/thunk';
import { ToastsMessages } from '@components/toastsMessages';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { NoteListTemplate } from '@templates/noteListTemplate';
import { getAccountsData, getNoteListData } from '@redux/selectors';
import { ReactComponent as NewNoteIcon } from '@img/notes/new_note.svg';
import { ReactComponent as FolderIcon } from '@img/notes/folder_close.svg';
import {
  setIsShowNotes,
  setIsShowFolders,
  clearErrorMessageNL,
  clearSuccessMessageNL,
} from '@redux/noteList/slice';

const NoteList = memo(() => {
  const navigate = useNavigate();
  const width = useWindowWidth();
  const dispatch = useAppDispatch();

  const { selectedFilial } = useAppSelector(getAccountsData);

  const {
    tags,
    status,
    isError,
    isLoading,
    folderList,
    isShowNotes,
    activeFolder,
    searchString,
    errorMessage,
    selectedTags,
    newFolderName,
    isAccessDenied,
    successMessage,
    isTagDeleteMode,
    isShowFolderList,
    filteredNotesList,
    isShowSearchByTag,
  } = useAppSelector(getNoteListData);

  useEffect(() => {
    if (selectedFilial?.accId) {
      dispatch(getNoteListPageData());
    }
  }, [selectedFilial?.accId, dispatch]);

  useEffect(() => {
    if (!isShowNotes && width > 640) {
      dispatch(setIsShowNotes(true));
    }
    if (!isShowFolderList && width > 640) {
      dispatch(setIsShowFolders(true));
    }
  }, [isShowNotes, isShowFolderList, width, dispatch]);

  const showFolderListForMobile = useCallback(() => {
    dispatch(setIsShowNotes(false));
    dispatch(setIsShowFolders(true));
  }, [dispatch]);

  const openNewNotePage = useCallback(() => {
    navigate({
      pathname: PATH_NAMES.NEW_NOTE,
      search: createSearchParams({
        [QUERY_PARAMS.ACC_ID]: selectedFilial?.accId || '',
      }).toString(),
    });
  }, [selectedFilial?.accId, navigate]);

  const clearAllSuccessStatuses = useCallback(() => {
    dispatch(clearSuccessMessageNL());
  }, [dispatch]);

  const clearAllErrorMessages = useCallback(() => {
    dispatch(clearErrorMessageNL());
  }, [dispatch]);

  const header = <H1 className='mb-6'>Заметки</H1>;

  const navigateBlock = (
    <div className='flex flex-wrap items-center mb-[0.875rem]'>
      <Button
        dense
        text='Задачи'
        type='default'
        view='outlined'
        color='default'
        className='!px-3 mb-2.5'
        onClick={showFolderListForMobile}
      />
      <Icon icon='chevron-right' size={20} color='#4A4B57' className='ml-4 mr-3 mb-2.5' />
      <div className='flex items-center mb-4'>
        <FolderIcon fill='#B4B4BB' className='mr-3' />
        <span className='text-gray2 leading-6 tracking-[0.0275em]'>{activeFolder?.title}</span>
      </div>
    </div>
  );

  return (
    <>
      {!isError && isAccessDenied ? <NoAccess className='h-[50vh]' /> : null}
      {isError && !isAccessDenied ? <ErrorMessage className='h-[50vh]' /> : null}
      {!isError && !isAccessDenied ? (
        <div className='flex flex-col'>
          <div>
            {width > 641 ? header : null}
            {width < 641 && isShowFolderList ? header : null}
            {width < 641 && !isShowFolderList ? navigateBlock : null}
          </div>
          <div className='absolute bottom-[5rem] right-[1rem] sm:static sm:flex items-center justify-end sm:mb-6 z-[1]'>
            <Button
              view='filled'
              color='warning'
              dense={width > 641}
              disabled={isLoading}
              onClick={openNewNotePage}
              type={width > 641 ? 'action' : 'icon'}
              icon={<NewNoteIcon fill='currentColor' />}
              text={width > 641 ? 'Новая заметка' : undefined}
              className={width < 641 ? 'h-[3.5rem] w-[3.5rem]' : '!px-4 !py-2'}
            />
          </div>
          <NoteListTemplate
            tags={tags}
            status={status}
            folders={folderList}
            isLoading={isLoading}
            isShowNotes={isShowNotes}
            activeFolder={activeFolder}
            selectedTags={selectedTags}
            searchString={searchString}
            notesList={filteredNotesList}
            newFolderName={newFolderName}
            isShowFolders={isShowFolderList}
            isTagDeleteMode={isTagDeleteMode}
            isSearchByTag={isShowSearchByTag}
          />
          <ToastsMessages
            errorMessage={errorMessage}
            successMessage={successMessage}
            isShowErrorToast={!!errorMessage}
            isShowSuccessToast={!!successMessage}
            clearErrorCallback={clearAllErrorMessages}
            clearSuccessStatusCallback={clearAllSuccessStatuses}
          />
        </div>
      ) : null}
    </>
  );
});

NoteList.displayName = 'NoteList';

export default NoteList;
