import infoIcon from '@img/dashboard/info.svg';
import deleteIcon from '@img/taskPage/close.svg';
import tickIcon from '@img/baseTemplate/tick.svg';
import smallTGIcon from '@img/pay/smallTGIcon.svg';
import on from '@img/statusComponent/statusOn.svg';
import documentIcon from '@img/common/document.svg';
import phoneIcon from '@img/baseTemplate/phone.svg';
import off from '@img/statusComponent/statusOff.svg';
import logoutIcon from '@img/baseTemplate/logout.svg';
import errorToastIcon from '@img/toastIcons/error.svg';
import calendarIcon from '@img/dashboard/calendar.svg';
import adminBotIcon from '@img/loginPage/adminBot.webp';
import caretDownIcon from '@img/baseTemplate/caret.svg';
import emptyList from '@img/baseTemplate/emptyList.svg';
import formattingIcon from '@img/common/formatting.svg';
import learningIcon from '@img/baseTemplate/learning.svg';
import darkCrossIcon from '@img/reportPage/darkCross.svg';
import taskTrackerIcon from '@img/common/taskTracker.svg';
import arrowCircleIcon from '@img/common/arrowCircle.svg';
import downloadWhiteIcon from '@img/pay/downloadWhite.svg';
import chevronRightIcon from '@img/common/chevronRight.svg';
import thirdPlaceIcon from '@img/analytics/thirdPlace.svg';
import firstPlaceIcon from '@img/analytics/firstPlace.svg';
import checkButtonIcon from '@img/taskPage/checkButton.svg';
import secondPlaceIcon from '@img/analytics/secondPlace.svg';
import warningInfoIcon from '@img/taskPage/warnigInfoIcon.svg';
import verticalMoreIcon from '@img/analytics/verticalMore.svg';
import successToastIcon from '@img/toastIcons/successToast.svg';
import telegramCyanIcon from '@img/baseTemplate/telegramCyan.svg';
import greenChevronLeftIcon from '@img/loginPage/chevronLeft.svg';
import videoInstructionIcon from '@img/analytics/videoInstruction.svg';
import switchOrientationIcon from '@img/analytics/switchOrientation.svg';
import greenChevronRightIcon from '@img/dashboard/chevronRightGreen.svg';
import { DASHBOARD_ADMINS_KEYS, DASHBOARD_KEYS } from '@const/dashboard';
import qrCodeTelegramLinkIcon from '@img/loginPage/qrCodeTelegramLink.svg';
import errorInInputIcon from '@img/textareaComponent/errorInInputIcon.svg';
import videoInstructionSmallIcon from '@img/analytics/videoInstructionSmall.svg';
import { TDashboardDrawerDataItem, THelp, TIcon, TSimpleStringObj } from '@models/index';

export const ICON_VARIANT: TIcon = {
  info: infoIcon,
  delete: deleteIcon,
  phone: phoneIcon,
  logout: logoutIcon,
  statusOn: on,
  statusOff: off,
  errorToast: errorToastIcon,
  successToast: successToastIcon,
  telegramCyan: telegramCyanIcon,
  caretDown: caretDownIcon,
  learning: learningIcon,
  adminBot: adminBotIcon,
  checkButton: checkButtonIcon,
  document: documentIcon,
  chevronRight: chevronRightIcon,
  taskTracker: taskTrackerIcon,
  errorInInput: errorInInputIcon,
  arrowCircle: arrowCircleIcon,
  tick: tickIcon,
  smallTG: smallTGIcon,
  warningInfo: warningInfoIcon,
  emptyList,
  formatting: formattingIcon,
  darkCross: darkCrossIcon,
  calendar: calendarIcon,
  greenChevronLeft: greenChevronLeftIcon,
  qrCodeTelegramLink: qrCodeTelegramLinkIcon,
  greenChevronRight: greenChevronRightIcon,
  downloadWhite: downloadWhiteIcon,
  videoInstruction: videoInstructionIcon,
  videoInstructionSmall: videoInstructionSmallIcon,
  verticalMore: verticalMoreIcon,
  switchOrientation: switchOrientationIcon,
  firstPlace: firstPlaceIcon,
  secondPlace: secondPlaceIcon,
  thirdPlace: thirdPlaceIcon,
};

export const LABEL_TEXT: THelp = {
  // first tooltip
  firstTooltipText: '',
  firstTooltipButtonText: '',
  tooltipTitle: '',
  // dashboard
  all_amount: '',
  amount_service_rate: '',
  op_records: '',
  earned_by_out_calls: '',
  all_out_calls: '',
  all_in_calls: '',
  wa_average_time: '',
  return_master_rate: '',
  churn_by_master: '',
  tasks: '',
  // task
  services: 'Услуги',
  visits: 'Количество визитов',
  visitQuantity: 'Клиентам с количеством визитов',
  everyFewDays: 'Создавать через каждые',
  taskDescription: 'Описание',
  // report
  sendToAllStaff: 'Отправлять всем мастерам',
  tgReport: '',
  reportDescription: 'Описание',
  whenSendRegularTasks: '',
  whenSendReports: '',
  // settings
  customerChurnTime: '',
  apiKeyTaskTracker: 'API ключи',
  domainTaskTracker: 'Домен',
  autoArchiveTasks: 'Автоматическая архивация клиентских задач',
  telephonyOperatorName: 'Название оператора',
  domain: 'Домен',
  apiKey: 'API ключи',
  iSetUpWebhook: 'Я настроил вебхук у оператора',
  phoneNumber: 'Номер телефона',
  taskOpenNotify: 'Укажите телеграм id',
  statsNotifyPhoneNumbers: 'Укажите номера телефонов для отправки статистики в whatsapp',
  staff: 'Сотрудники',
  selectedStaff: 'Укажите id сотрудника',
  taskTracker: 'в таск-трекере',
  yclients: 'в Yclients',
  employeesToIgnore: 'Сотрудники',
  forgottenTaskNotify: 'Укажите телеграм id',
  missedCallAnswer: 'Уведомлять клиентов после пропущенного звонка',
  callStats: 'Включение рассылки статистики звонков админа за день',
  tasksNotify: '',
  noStaffStats: '',
  blackList: '',
  masters: '',
  admins: '',
  billData: 'Данные счета',
  owner: 'Владелец',
  performer: 'Исполнитель',
  task_completed: 'Завершенные задачи',
  task_created: 'Созданные задачи',
  task_postpone: 'Отложенные задачи',
  task_overdue: 'Просроченные задачи',
  useGroups: 'Учитывать клиентов сети для этого салона',
  // _______________________
  // pay
  tariff: 'Ваш тариф:',
  selectAllFilial: 'Оплатить все филиалы',
  // ______________________
  // pay
  yourTariff: 'Ваш тариф',
  staffTableToolTip: 'Информация о сотрудниках',
};

export const TOOLTIPS_TEXT: THelp = {
  // first tooltip
  firstTooltipText: 'Наведите на значок «i», чтобы прочитать подсказку',
  firstTooltipButtonText: 'Понятно',
  tooltipTitle: 'Как рассчитывается?',
  // dashboard
  all_amount: 'all_amount',
  amount_service_rate: 'amount_service_rate',
  op_records: 'op_records',
  earned_by_out_calls: 'earned_by_out_calls',
  all_out_calls: 'all_out_calls',
  all_in_calls: 'all_in_calls',
  wa_average_time: 'wa_average_time',
  return_master_rate: 'return_master_rate',
  churn_by_master: 'churn_by_master',
  tasks: 'tasks',
  // task
  services: 'no text',
  visits: 'no text',
  visitQuantity: 'no text',
  everyFewDays: 'no text',
  taskDescription: 'no text',
  // report
  sendToAllStaff:
    'При активации пункта «Отправка всем мастерам» шаблон будет отправляться  всем мастерам, которые работают в день отправки шаблона. Если отправка шаблона приходится на последний день месяца, то  шаблон получат все мастера салона. ВАЖНО! В отправке могут участвовать только мастера, активировавшие Умное расписание со своего телефона.',
  tgReport:
    'При активации пункта «Отправка всем мастерам» шаблон будет отправляться  всем мастерам, которые работают в день отправки шаблона. Если отправка шаблона приходится на последний день месяца, то  шаблон получат все мастера салона. ВАЖНО! В отправке могут участвовать только мастера, активировавшие Умное расписание со своего телефона.',
  reportDescription: 'no text',
  whenSendRegularTasks:
    'Время отправки сообщений можно выбрать с шагом в 15 минут. Если ввести значение, не кратное 15 минутам, оно будет автоматически округлено до ближайшего кратного значения.',
  whenSendReports:
    'Время отправки сообщений можно выбрать с шагом в 1 час. Если ввести значение, не кратное 1 часу, оно будет автоматически округлено до ближайшего кратного значения.',
  // settings
  customerChurnTime: 'Изменение данного параметра доступно раз в 3 часа',
  apiKeyTaskTracker: 'Укажите API ключ для таск-трекера',
  domainTaskTracker: 'Укажите домен для таск-трекера',
  autoArchiveTasks: 'no text',
  telephonyOperatorName: 'Укажите название оператора',
  apiKey: 'Укажите API ключ для телефонии',
  domain: 'Укажите домен для телефонии',
  iSetUpWebhook: 'Настройте вебхук как указано в инструкции',
  phoneNumber: 'Укажите номер телефона подключенного к телефонии',
  taskOpenNotify: 'Укажите телеграм id',
  statsNotifyPhoneNumbers: 'Укажите номера телефонов для отправки статистики в whatsapp',
  staff:
    "Укажите код сотрудника из CRM системы, для которого необходимо отправлять шаблон. Оставьте пустым для отправки всем клиентам. Через '-' вы можете указать сотрудников которых необходимо игнорировать. Вы можете отключить отправку всех шаблонов сотруднику в настройках",
  selectedStaff: 'Укажите id сотрудника для постановки задач в YCLIENTS',
  taskTracker: 'no text',
  yclients: 'no text',
  employeesToIgnore: 'no text',
  missedCallAnswer:
    'После пропущенного звонка клиенту в WhatsApp отправляется сообщение: “Извините, что не смогли ответить на ваш звонок. Администратор перезвонит в ближайшее время”',
  forgottenTaskNotify: 'no text',
  callStats:
    'Ежедневно в 21:00 детализация звонков будет отправляться в Excel-файле только владельцу.',
  tasksNotify:
    'По дефолту уведомление указанному сотруднику приходит в Telegram, для отправки уведомлений в WhatsApp введите его номер телефона. Данная функция позволит улучшить оперативность и эффективность работы.',
  noStaffStats:
    'Функция позволяет исключать сотрудников из анализа для более корректного отображения статистики. Для исключения сотрудника введите его имя или ID.',
  blackList:
    'Это список клиентов салона, по которым не нужно создавать задачи. В этом списке могут быть клиенты, которые плохо себя проявили в салоне, внутренние номера, которые использовались для проверки и другие.',
  masters:
    'Для подключения сотрудника к админ боту скопируйте ссылку, расположенную рядом с именем сотрудника и отправьте ему. Сотрудник, перейдя по ссылке должен следовать инструкциям.',
  admins:
    'Для подключения Админа/ Управляющего к Таск-трекеру.\n' +
    'Админу нужно зайти в YCL под своей учетной записью\n' +
    'На странице ‘Журнал записи’ открыть приложения (крайняя кнопка справа вверху)\n' +
    'В отобразившемся окне отобразятся вкладки ‘Клиенты’, ‘Чат’, ‘Задачи’\n' +
    'Выбрать вкладку ‘Задачи’\n' +
    'В отобразившемся окне нажать кнопку ‘Получить доступ’\n' +
    'Таск-трекер доступен Админу / Управляющему только в YCL\n' +
    'Или вы можете скопировать и переслать админу его персональную ссылку для подключения. Тогда Таск-трекер будет доступен в TG и YCL',
  billData: 'Информация по расчетному счету на который будут генерироваться платежка',
  owner: '',
  performer: '',
  task_completed:
    'Вы получите уведомление, когда задача будет выполнена. Это позволит вам отслеживать прогресс и завершение задач.',
  task_created:
    'Вы получите уведомление, когда будет создана новая задача. Это поможет вам быть в курсе всех новых задач и своевременно на них реагировать.',
  task_postpone:
    'Вы получите уведомление, когда задача будет отложена. Это информирует вас о любых изменениях в приоритетах или планах по выполнению задачи.',
  task_overdue:
    'Вы получите уведомление, если задача будет просрочена. Это важно для своевременного принятия мер и предотвращения задержек в работе.',
  useGroups:
    'При активации этого пункта задачи для потерявшихся клиентов будут использовать клиентскую базу всей сети. Например, при включенном пункте, в момент создания задач будет анализироваться последнее посещение клиента во всех салонах сети. Если клиент не был у вас больше указанного количества дней, но недавно посетил другой салон вашей сети, задача "Позвонить потеряшке" для этого клиента выставляться не будет, так как он не ушел из сети.',
  // _______________________
  // pay
  tariff:
    'Тариф рассчитывается исходя из количества сотрудников в СRM системе.\n 1 сотрудник - 990 руб,\n от 2 до 5 сотрудников - 1990 руб,\n от 6 до 10 сотрудников - 2990 руб,\n от 11 до 15 сотрудников - 3490 руб,\n от 16 до 20 сотрудников - 3890 руб,\n от 21 сотрудника - 4290 руб.',
  selectAllFilial: '',
  // ______________________
  // pay
  yourTariff: `Тариф рассчитывается исходя из количества сотрудников в СRM системе.\n  ${'\u2022'} 1 сотрудник - 990 руб,\n ${'\u2022'} От 2 до 5 сотрудников - 1990 руб,\n ${'\u2022'} От 6 до 10 сотрудников - 2990 руб,\n ${'\u2022'} От 11 до 15 сотрудников - 3490 руб,\n ${'\u2022'} От 16 до 20 сотрудников - 3890 руб,\n ${'\u2022'} От 21 сотрудника - 4290 руб.`,
  staffTableToolTip:
    'Для подключения Админа/ Управляющего к Таск-трекеру. \n' +
    'Админу нужно зайти в YCL под своей учетной записью\n' +
    'На странице ‘Журнал записи’ открыть приложения (крайняя кнопка справа вверху)\n' +
    'В отобразившемся окне отобразятся вкладки ‘Клиенты’, ‘Чат’, ‘Задачи’\n' +
    'Выбрать вкладку ‘Задачи’\n' +
    'В отобразившемся окне нажать кнопку ‘Получить доступ’\n' +
    'Таск-трекер доступен Админу / Управляющему только в YCL\n' +
    'Или вы можете скопировать и переслать админу его персональную ссылку для подключения. Тогда Таск-трекер будет доступен в TG и YCL',
};

export enum NO_FILIAL_ERROR {
  TEXT = 'noFilial',
}

export const operatorNames: TSimpleStringObj = {
  megafon: 'Мегафон',
};

export enum INPUT_NAMES {
  QUANTITY_FROM = 'quantityFrom',
  QUANTITY_TO = 'quantityTo',
}

export const addDeleteStaff: TSimpleStringObj = {
  add: 'Добавить сотрудника',
  delete: 'Удалить сотрудника',
};

export const staffList: TSimpleStringObj = {
  123: 'Иванов',
  456: 'Петров',
  789: 'Сидоров',
};

export const DashboardInfo: Record<DASHBOARD_KEYS, TDashboardDrawerDataItem> = {
  [DASHBOARD_KEYS.SERVICE_CATEGORY]: {
    title: 'Сумма дохода (по категориям услуг)',
    subTitle: 'Доходы по категориям услуг/услугам',
    description: {
      how: 'Суммируется стоимость выполненных услуг в рамках категории/суммируется стоимость выполненных услуг',
      business:
        'Анализируйте, какие категории услуг/услуги являются наиболее и наименее доходными. Стремитесь увеличить эффективность услуг, приносящих меньший доход.',
    },
  },
  [DASHBOARD_KEYS.ALL_AMOUNT_MASTERS]: {
    title: 'Сумма дохода (по мастерам)',
    subTitle: 'Доходы по мастерам',
    description: {
      how: 'Суммируется стоимость выполненных услуг по каждому мастеру',
      business:
        'Обращайте внимание на эффективность работы каждого мастера. Возможно кто-то из мастеров использует не весь потенциал, что уменьшает доходность вашего салона.',
    },
  },
  [DASHBOARD_KEYS.AMOUNT_SERVICE_RATE]: {
    title: 'Доход по услугам',
    subTitle: 'Доходы по категориям услуг/услугам',
    description: {
      how: 'Суммируется стоимость выполненных услуг в рамках категории/суммируется стоимость выполненных услуг',
      business:
        'Анализируйте, какие категории услуг/услуги являются наиболее и наименее доходными. Стремитесь увеличить эффективность услуг, приносящих меньший доход.',
    },
  },
  [DASHBOARD_KEYS.OP_RECORDS]: {
    title: 'Оперативная запись',
    subTitle: 'Оперативная запись',
    description: {
      how: 'Суммируется количество записей, сделанных в день посещения салона клиентом (звонки и на месте?)',
      business:
        'Анализируйте эффективность работы администратора в рамках увеличения продаж и выстраивания долгосрочных отношений с клиентами',
    },
  },
  [DASHBOARD_KEYS.FILTERS_SERVICE_CATEGORY]: {
    title: 'Сумма записей после исходящих (по категориям услуг)',
    subTitle: 'Сумма записей после исходящих',
    description: {
      how: 'Суммируется количество исходящих звонков за день расчета, по результатам которых формируется запись на услугу',
      business:
        'Анализируйте уровень спроса на услуги вашего салона и оценивайте эффективность работы администратора в рамках продаж услуг салона.',
    },
  },
  [DASHBOARD_KEYS.FILTERS_MASTERS]: {
    title: 'Сумма записей после исходящих (по мастерам)',
    subTitle: 'Сумма записей после исходящих',
    description: {
      how: 'Суммируется количество исходящих звонков за день расчета, по результатам которых формируется запись на услугу',
      business:
        'Анализируйте уровень спроса на услуги вашего салона и оценивайте эффективность работы администратора в рамках продаж услуг салона.',
    },
  },
  // [DASHBOARD_KEYS.FILTERS_ADMINS]: {
  //   title: 'Сумма записей после исходящих (по админам)',
  //   subTitle: 'Сумма записей после исходящих',
  //   description: {
  //     how: 'Суммируется количество исходящих звонков за день расчета, по результатам которых формируется запись на услугу',
  //     business:
  //       'Анализируйте уровень спроса на услуги вашего салона и оценивайте эффективность работы администратора в рамках продаж услуг салона.',
  //   },
  // },
  [DASHBOARD_KEYS.MASTERS]: {
    title: 'Возвращаемость по мастерам',
    subTitle: 'Возвращаемость по мастерам',
    description: {
      how: 'Суммируется количество клиентов,  пришедших на услугу повторно, по каждому мастеру отдельно',
      business: `Анализируйте эффективность работы мастеров и администратора. Обратите внимание на желание клиента продолжать сотрудничество с мастером, а также на работу администратора по выстраиванию долгосрочных отношений с клиентом.`,
    },
  },
  [DASHBOARD_KEYS.RETURN_NEW_CLIENT_RATE]: {
    title: 'Возвращаемость (по новым клиентам)',
    subTitle: 'Возвращаемость по новым клиентам',
    description: {
      how: 'Суммируется количество новых клиентов,  пришедших на услугу повторно',
      business: `Улучшайте эффективность данного показателя, предлагая новым клиентам запись на услугу в день посещения салона. Таким образом вы также увеличите и показатель оперативной записи.`,
    },
  },
  [DASHBOARD_KEYS.GENERAL_CLIENT_RATE]: {
    title: 'Возвращаемость (по общей возвращаемости в салон)',
    subTitle: 'Общая возвращаемость в салон',
    description: {
      how: 'Суммируется количество клиентов,  пришедших на услугу повторно',
      business: `Анализируйте эффективность выстраивания долгосрочных отношений с клиентом и качество оказываемых услуг. Обратите внимание на то, хотят ли клиенты продолжать сотрудничать с вашим салоном. Если показатель возвращаемости низкий, возможно ваших клиентов что-то не устраивает в работе мастеров или администратора.`,
    },
  },
  [DASHBOARD_KEYS.CHURN_BY_MASTER]: {
    title: 'Отток клиентов у каждого мастера',
    subTitle: 'Отток клиентов каждого мастера',
    description: {
      how: 'Суммируется количество ушедших из салона клиентов по каждому мастеру отдельно',
      business: `Анализируйте качество работы каждого мастера, это напрямую влияет на удержание клиентов в салоне и ваш доход. Старайтесь увеличить данный показатель.`,
    },
  },
  [DASHBOARD_KEYS.CREATED]: {
    title: 'Задачи (созданные задачи)',
    subTitle: 'Задачи (созданные)',
    description: {
      how: 'Суммируется количество созданных задач за день',
      business: `Прогнозируйте ежедневную нагрузку администратора для улучшения эффективности его работы.`,
      more: 'Необходимо доработать функционал для наличия возможности анализировать количество созданных задач за конкретный выбранный нашим клиентом период',
    },
  },
  [DASHBOARD_KEYS.FILTERS_TASKS_COMPLETED]: {
    title: 'Задачи (выполненные задачи)',
    subTitle: 'Задачи (выполненные)',
    description: {
      how: 'Суммируется количество выполненных задач за день',
      business: `Оценивайте эффективность работы администратора на ежедневной основе.`,
    },
  },
  [DASHBOARD_KEYS.FILTERS_TASKS_MISSED]: {
    title: 'Задачи (просроченные задачи)',
    subTitle: 'Задачи (просроченные)',
    description: {
      how: 'Суммируется количество просроченных задач за день',
      business: `Обратите внимание на наличие и увеличение количества данного показателя. Он может говорить о неэффективной работе администратора.`,
    },
  },
  [DASHBOARD_ADMINS_KEYS.ALL_OUT_CALLS]: {
    title: 'Исходящие звонки (доля записанных)',
    subTitle: 'Исходящие звонки (доля записанных)',
    description: {
      how: 'Сумма исходящих звонков за день, в результате которых была осуществлена запись клиента на услугу делится на общее количество исходящих звонков за аналогичный период',
      business: `Оценивайте конверсию звонков в запись, улучшение данного показателя позволит увеличить доход салона.`,
    },
  },
  [DASHBOARD_KEYS.FILTERS_ALL_RECORDS]: {
    title: 'Входящие звонки (Количество созданных записей)',
    subTitle: 'Входящие звонки (с созданием новых записей)',
    description: {
      how: 'Суммируется количество входящих звонков за день расчета, по результаты которых формируется запись на услугу',
      business: `Анализируйте уровень спроса на услуги вашего салона и оценивайте эффективность работы администратора в рамках продаж услуг салона.`,
      more: 'Сделать рекомендательный характер значений показателей. Например, салоны с количеством сотрудников до 10 должны осуществлять 20 количество исходящих звонков.Своего рода KPI для админов.',
    },
  },
  [DASHBOARD_KEYS.FILTERS_NEW_CLIENTS]: {
    title: 'Входящие звонки (новые клиенты)',
    subTitle: 'Входящие звонки (от новых клиентов)',
    description: {
      how: 'Суммируется количество входящих звонков от новых клиентов за день расчета',
      business: `Анализируйте уровень спроса новых клиентов на услуги вашего салона. Таким образом вы сможете понять популярность вашего салона и скорректировать маркетинговую стратегию.`,
    },
  },
  [DASHBOARD_KEYS.ALL_FILTERS_MISSED_CALLS]: {
    title: 'Входящие звонки (пропущенные)',
    subTitle: 'Входящие звонки (количество пропущенных)',
    description: {
      how: 'Суммируется количество пропущенных звонков за день расчета',
      business: `Обращайте внимание на количество пропущенных входящих звонков. Возможно это одна из причин недополучения дохода.`,
    },
  },
  [DASHBOARD_ADMINS_KEYS.WA_AVERAGE_TIME]: {
    title: 'Среднее время ответа',
    subTitle: 'Среднее время ответа',
    description: {
      how: 'Рассчитывается время ответа на каждое отвеченное сообщение в секундах за день, затем вычисляется медианное значение из данной выборки. ',
      business: `Стремитесь к уменьшению данного показателя. Это позволит оптимизировать работу администратора по привлечению клиентов и увеличению количества оказанных услуг салоном.`,
    },
  },
};

export enum DASHBOARD_TEXT {
  HOW_BUTTON = 'Как расчитывается?',
  HOW_TEXT = 'Как расчитываем?',
  BUSINESS_TEXT = 'Бизнес-советы',
  MORE_TEXT = 'Дополнительно',
}
export const emptyDashboardInfoItem = {
  title: '',
  subTitle: '',
  description: {
    how: '',
    business: '',
  },
};

export const PAGE_TEXT = {
  STAFF_TABLE_DESCRIPTION:
    'Для подключения сотрудника к админ боту скопируйте ссылку, расположенную рядом с именем сотрудника и отправьте ему. Сотрудник, перейдя по ссылке должен следовать инструкциям.',
};

export enum SIDE_NAV_PAGE_NAMES {
  ANALYTICS = 'Аналитика',
  DASHBOARD = 'Дашборд',
  ADMINS = 'Админы',
  FILIALS = 'Филиалы',
  REPORTS = 'Отчеты на телефон',
  TASKS = 'Задачи',
  REGULAR_TASKS = 'Регулярные',
  EVENT_TASKS = 'При событии',
  CLIENTS_TASKS = 'Клиентские',
  NOTES = 'Заметки',
  SETTINGS = 'Настройки',
  GENERAL_SETTINGS = 'Общие',
  TELEPHONY = 'Телефония',
  SETTINGS_ADMINS = 'Админы',
  SETTINGS_MASTERS = 'Мастера',
  SETTINGS_NOTIFICATIONS = 'Уведомления',
  PAY = 'Оплата',
  BLACKLIST = 'Черный список',
  STAFF = 'Сотрудники',
  PAGE_404 = '404',
  CALLS_DETAILS = 'Детализация звонков',
  PAY_DETAILS = 'Данные счёта',
}
