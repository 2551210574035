import React, { memo, useMemo, useRef, useState } from 'react';
//
import { EVENT_KEY_NAMES } from '@const/common';
import { DeleteButton } from '@blocks/deleteButton';
import { TKeyboardClickEvent, TOnChangeHandler } from '@shared/types';

import { CheckListItem } from '../checkListItem';

interface ICheckListBlockProps {
  /**
   * Список элементов чеклиста
   * @param {Array<string>}
   */
  checklistItems: string[];
  /**
   * Переменная для хранения имени нового элемента чеклиста
   *  @param {string}
   */
  newChecklistItemName: string;
  /**
   * Callback для добавления нового элемента в чеклист
   * @param {() => void}
   */
  addChecklistItem: () => void;
  /**
   * Callback для удаления/скрытия чеклиста
   * @param {() => void}
   */
  setIsAddChecklistHandler: () => void;
  /**
   * Обработчик onChange для ввода заначения нового элемента чеклиста
   */
  setNewChecklistNameHandler: TOnChangeHandler;
  /**
   * Обработчик для изменения значения существующего элемента чеклиста
   * @param {(oldValue: string) => (newValue: string) => void}
   */
  editCheckListItemHandler: (oldValue: string) => (newValue: string) => void;
  /**
   * Callback для удаления элемента чеклиста
   * @param {(index: number) => (value: string) => () => void}
   */
  deleteChecklistItemHandler: (index: number) => (value: string) => () => void;
}

export const CheckListBlock = memo(
  ({
    checklistItems,
    addChecklistItem,
    newChecklistItemName,
    editCheckListItemHandler,
    setIsAddChecklistHandler,
    deleteChecklistItemHandler,
    setNewChecklistNameHandler,
  }: ICheckListBlockProps) => {
    // реф для инпута для возвращения фокуса в него
    const ref = useRef<HTMLInputElement>(null);

    // состояние показать скрыть инпут для введения нового пункта в чеклист
    const [isShow, setIsShow] = useState(false);

    // массив элементов чеклиста для рендеринга
    const checkListItemsToRender = useMemo(
      () =>
        checklistItems.map((item, index) => {
          const key = `${index}${item}`;
          return (
            <CheckListItem
              key={key}
              itemValue={item}
              itemValueEditCallback={editCheckListItemHandler(item)}
              deleteChecklistItemHandler={deleteChecklistItemHandler(index)}
              className='mb-[1.875rem]'
            />
          );
        }),
      [checklistItems, editCheckListItemHandler, deleteChecklistItemHandler],
    );

    // возвращает фокус на реф
    const focusToRef = () => ref.current && ref.current.focus();

    // если есть данные в newChecklistItemName, добавляет новый пункт в чеклист,
    const showAddCheckListItem = () => {
      if (newChecklistItemName) {
        addChecklistItem();
      } else if (!isShow) {
        setIsShow(true);
        setTimeout(() => focusToRef());
      } else {
        focusToRef();
      }
    };

    // Добавляет новый пункт в чеклст при нажатии на Enter,
    const addNewChecklistItemOnKeyDownHandler: TKeyboardClickEvent = event => {
      const { key } = event;

      if (key === EVENT_KEY_NAMES.BACKSPACE && !newChecklistItemName.length) {
        setIsShow(false);
      }
      if (key === EVENT_KEY_NAMES.ENTER) {
        focusToRef();
        addChecklistItem();
      }
    };

    // добавляет новый элемент и скрывает инпут для ввода
    const addNewChecklistItemOnBlurHandler = () => {
      addChecklistItem();
      setIsShow(false);
    };

    return (
      <div className='relative bg-gray1 p-4 rounded mb-10 max-w-[47rem] w-full'>
        <p className='block text-grayText tracking-[0.0275em] mb-5'>Чеклист</p>
        <DeleteButton
          callback={setIsAddChecklistHandler}
          className='absolute top-[0.625rem] right-[0.375rem] !h-10 !w-10'
        />
        {checkListItemsToRender.length ? (
          <div className='mb-[1.875rem]'>
            <ul className='pl-2.5'>{checkListItemsToRender}</ul>
          </div>
        ) : null}
        <span className='block bg-darkDot bg-left-1 pl-[2.4rem] ml-2.5 mt-6 mb-7 bg-no-repeat'>
          {isShow ? (
            <input
              ref={ref}
              type='text'
              value={newChecklistItemName}
              onChange={setNewChecklistNameHandler}
              onBlur={addNewChecklistItemOnBlurHandler}
              onKeyDown={addNewChecklistItemOnKeyDownHandler}
              className='border-none bg-transparent focused:border-none hover:border-none tracking-wide w-full'
            />
          ) : null}
        </span>
        <div className='flex items-center ml-2.5 mt-1'>
          <button
            onClick={showAddCheckListItem}
            disabled={isShow && !newChecklistItemName}
            className={`border-none flex items-center ${
              isShow && !newChecklistItemName ? 'text-green2' : 'text-green1'
            }  shadow-none hover:shadow-none mr-5 tracking-wide`}>
            <svg
              width='18'
              fill='none'
              height='18'
              viewBox='0 0 18 18'
              className={`${isShow && !newChecklistItemName ? 'fill-green2' : 'fill-green1'} mr-5`}
              xmlns='http://www.w3.org/2000/svg'>
              <path
                clipRule='evenodd'
                fillRule='evenodd'
                d='M16.2 7.79961H10.2V1.79961C10.2 1.13961 9.66001 0.599609 9.00001 0.599609C8.34001 0.599609 7.80001 1.13961 7.80001 1.79961V7.79961H1.80001C1.14001 7.79961 0.600006 8.33961 0.600006 8.99961C0.600006 9.65961 1.14001 10.1996 1.80001 10.1996H7.80001V16.1996C7.80001 16.8596 8.34001 17.3996 9.00001 17.3996C9.66001 17.3996 10.2 16.8596 10.2 16.1996V10.1996H16.2C16.86 10.1996 17.4 9.65961 17.4 8.99961C17.4 8.33961 16.86 7.79961 16.2 7.79961Z'
              />
            </svg>
            Добавить
          </button>
        </div>
      </div>
    );
  },
);
