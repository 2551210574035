import React, { memo, useMemo } from 'react';
//
import { Icon } from '@atoms/icon';
import { preview } from '@helpers/previewMaker';
import {
  TASK_TYPE,
  TASK_VARIANTS,
  TASK_PREVIEW_TEXT,
  TASK_PREVIEW_CLIENT_ITEMS,
} from '@const/task';

type TTaskPreviewProps = {
  /**
   * Название задачи
   * @param {string}
   * @default 'Название задачи'
   */
  taskName?: string;
  /**
   * Тип задачи
   * @param {TASK_TYPE}
   * @default TASK_TYPE.CASE
   */
  taskType?: TASK_TYPE;
  /**
   * Описание задачи
   * @param {string}
   * @default ''
   */
  taskDescription?: string;
  /**
   * Чек-лист задачи
   * @param {string[]}
   * @default []
   */
  taskCheckList?: string[];
};
export const TaskPreview = memo(
  ({
    taskDescription = '',
    taskType = TASK_TYPE.CASE,
    taskName = 'Название задачи',
    taskCheckList = [],
  }: TTaskPreviewProps) => {
    const renderTaskType = useMemo(() => {
      if (!(taskType in TASK_VARIANTS)) return null;

      const { class: taskClass, text } = TASK_VARIANTS[taskType as keyof typeof TASK_VARIANTS];

      return (
        <div className={`inline-block px-1 py-0.5 mb-6 rounded-full ${taskClass}`}>{text}</div>
      );
    }, [taskType]);

    const renderTaskDescription = useMemo(() => {
      if (!taskDescription) return null;

      return (
        <div className='text-stormGray text-body2 mb-4'>
          {preview.getPreviewMarkUp(taskDescription)}
        </div>
      );
    }, [taskDescription]);

    const renderTaskCheckList = useMemo(() => {
      if (!taskCheckList.length) return null;

      return (
        <ul className='rounded-[0.5rem] border border-gray4 py-2 px-3 bg-gray5'>
          <li className='text-blackText mb-4'>Чек-лист:</li>
          {taskCheckList.map(item => (
            <li className='text-blackText text-body2 mb-4 ml-6 list-disc' key={item}>
              {item}
            </li>
          ))}
        </ul>
      );
    }, [taskCheckList]);

    const renderClientCard = useMemo(() => {
      if (taskType !== TASK_TYPE.CLIENT) return null;

      return (
        <ul className='rounded-[0.5rem] border border-gray4 py-2 px-3 bg-gray5 mb-4'>
          <li className='text-blackText mb-4'>{`${TASK_PREVIEW_TEXT.CLIENT_CARD}:`}</li>
          {TASK_PREVIEW_CLIENT_ITEMS.map(({ title, value }) => {
            return (
              <li className='flex mb-2' key={title}>
                <span className='flex-shrink text-gray6 text-captionSmall'>{title}</span>
                <div className='border-b border-dashed flex-1 border-gray4' />
                <span className='text-tooltips text-client_text font-semibold'>{value}</span>
              </li>
            );
          })}
        </ul>
      );
    }, [taskType]);

    return (
      <div>
        <div className='flex items-start mb-4'>
          <Icon variant='checkButton' className='mr-2' />
          <span className='text-h3Mobile font-medium'>{preview.getPreviewMarkUp(taskName)}</span>
        </div>
        {renderTaskType}
        {renderClientCard}
        {renderTaskDescription}
        {renderTaskCheckList}
      </div>
    );
  },
);
