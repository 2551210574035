import { combineReducers, configureStore } from '@reduxjs/toolkit';
//
import { payReducer } from '@redux/Pay/slice';
import { appReducer } from '@redux/App/slice';
import { authReducer } from '@redux/Auth/slice';
import { noteReducer } from '@redux/note/slice';
import { taskReducer } from '@redux/Task/slice';
import { reportReducer } from '@redux/Report/slice';
import { emojiReducer } from '@redux/emoji/emojiSlice';
import { accountsReducer } from '@redux/Accounts/slice';
import { noteListReducer } from '@redux/noteList/slice';
import { settingsReducer } from '@redux/Settings/slice';
import { dashboardReducer } from '@redux/Dashboard/slice';
import { blackListReducer } from '@redux/BlackList/slice';
import { analyticsReducer } from '@redux/Analytics/slice';

const RootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  accounts: accountsReducer,
  task: taskReducer,
  report: reportReducer,
  blackList: blackListReducer,
  settings: settingsReducer,
  pay: payReducer,
  dashboard: dashboardReducer,
  emoji: emojiReducer,
  analytics: analyticsReducer,
  noteList: noteListReducer,
  note: noteReducer,
});

export const store = configureStore({
  reducer: RootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoreActions: true,
        ignoredPaths: [
          'dashboard.financesStartDate',
          'dashboard.financesEndDate',
          'dashboard.adminsStartDate',
          'dashboard.adminsEndDate',
          'dashboard.mastersStartDate',
          'dashboard.mastersEndDate',
          'dashboard.analyticsStartDate',
          'dashboard.analyticsEndDate',
        ],
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
