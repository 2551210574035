import React, { memo } from 'react';
import { highlightSearch } from '@helpers/highlightSearch';

type TProps = {
  /**
   * Строка поиска в инпут
   * @param {string}
   */
  searchString: string;
  /**
   * Названние тэга
   * @param {string}
   */
  tagName: string;
  /**
   * Устанавливает тэг в поисковую строку
   * @param {() => void}
   */
  setTagInSearchString: () => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const NoteTag = memo(
  ({ tagName, searchString, setTagInSearchString, className = '' }: TProps) => {
    return (
      <div onClick={setTagInSearchString} className={className}>
        <span className='font-medium text-tagGreen text-small_text leading-4'>
          {highlightSearch({ searchString, targetString: tagName, highlightColor: '#FFCB00' })}
        </span>
      </div>
    );
  },
);

NoteTag.displayName = 'NoteTag';
