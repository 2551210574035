import React from 'react';
//
import { Button } from '@uikit/Button';
import { EVENT_KEY_NAMES } from '@models/index';
import { TOnChangeHandler } from '@shared/types';
import { SearchInput } from '@uikit/Inputs/SearchInput';

type TSearchInputWithButton = {
  /**
   * Строка поиска в инпут
   * @param {string}
   */
  searchString: string;
  /**
   * Флаг отключения кнопки поиска
   * @param {boolean}
   */
  disableSearchButton: boolean;
  /**
   * Callback запуска поиска
   * @param {() => void}
   */
  startSearchHandler: () => void;
  /**
   * Callback для очистки поисковой строки
   * @param {() => void}
   */
  clearSearchStringHandler: () => void;
  /**
   * Callback для обработки изменения поисковой строки
   * @param {TOnChangeHandler}
   */
  setSearchStringHandler: TOnChangeHandler;
  /**
   * Placeholder для input
   * @param {string}
   */
  placeholder: string;
  /**
   * Текст в кнопку поиска
   * @param {string}
   */
  buttonText: string;
  /**
   * Скрывает кнопу начала поиска
   * @param {boolean}
   */
  hideSearchButton?: boolean;
  /**
   * Флаг изменяет поведение элементов на мобильниках
   * @param {boolean}
   */
  isChatHistoryPage?: boolean;
  /**
   * Дополнительный текст располагается под инпутом
   * @param {string}
   */
  additionalInputDescription?: string;
  /**
   * Флаг блокировки поля ввода
   * @param {boolean}
   */
  disabled?: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const SearchInputWithButton = ({
  buttonText,
  placeholder,
  searchString,
  disabled = false,
  hideSearchButton,
  startSearchHandler,
  disableSearchButton,
  setSearchStringHandler,
  clearSearchStringHandler,
  isChatHistoryPage = false,
  additionalInputDescription,
  className = '',
}: TSearchInputWithButton) => {
  const wrapperStyle = 'flex-col md:flex-row';
  const buttonWrapperStyle = 'w-full md:w-fit';
  const searchInputWrapperStyle = 'mb-4 md:mb-0 md:mr-4 w-full';

  // Запускает поиск по нажатию на Enter
  const onKeyUpHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = event;
    if (key === EVENT_KEY_NAMES.ENTER) {
      startSearchHandler();
    }
  };

  return (
    <div
      className={`flex ${
        isChatHistoryPage ? wrapperStyle : ''
      } items-center justify-between p-px w-full ${className}`}>
      <div
        className={`${
          isChatHistoryPage ? searchInputWrapperStyle : hideSearchButton ? 'mr-0' : 'mr-4'
        } w-full`}>
        <SearchInput
          fill
          type='def'
          view='outlined'
          color='default'
          disabled={disabled}
          value={searchString}
          onKeyUp={onKeyUpHandler}
          placeholder={placeholder}
          onChange={setSearchStringHandler}
          clearSearchString={clearSearchStringHandler}
        />
        {additionalInputDescription ? (
          <p className='text-small_text text-grayText tracking-[0.033em]'>
            {additionalInputDescription}
          </p>
        ) : null}
      </div>
      {!hideSearchButton ? (
        <div
          className={`self-start ${
            isChatHistoryPage ? buttonWrapperStyle : ''
          } md:min-w-[7.1875rem]`}>
          <Button
            fill
            dense
            type='action'
            view='outlined'
            color='default'
            text={buttonText}
            onClick={startSearchHandler}
            disabled={disableSearchButton}
          />
        </div>
      ) : null}
    </div>
  );
};
