import React, { memo, useEffect, useState } from 'react';
import { Icon } from '@blueprintjs/core';
//
import { TOOLTIPS_TEXT } from '@data/dict';
import { storageDb } from '@api/storageApi';
import { FirstTooltip } from '@blocks/firstTooltip';

interface IIconWithTooltipsProps {
  /**
   * Флаг для сокрытия иконки с подсказкой
   * @param {boolean}
   */
  noIcon?: boolean;
  /**
   * Опциональный параметр отображает обучающую подсказку
   * @param {boolean}
   */
  isFirstTooltip?: boolean;
  /**
   * Флаг для переключения цвета иконки на красный
   * @param {boolean}
   */
  isRed?: boolean;
  /**
   * Слушатель события нажатия кнопки "Как это работает?"
   * @type {() => void}@
   */
  openDrawerHandler: () => void;
  /**
   * Опциональный параметр цвет иконки
   * @param {string}
   */
  iconColor?: string;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const IconWithDrawer = memo(
  ({
    isRed,
    noIcon,
    iconColor,
    isFirstTooltip,
    openDrawerHandler,
    className = '',
  }: IIconWithTooltipsProps) => {
    const [isViewTooltip, setIsViewTooltip] = useState<{ tooltip: string }>({ tooltip: 'false' });

    useEffect(() => {
      if (isFirstTooltip) {
        const tooltipStatus = storageDb.getTooltipStatus();

        if (tooltipStatus.tooltip !== isViewTooltip.tooltip) {
          setIsViewTooltip(storageDb.getTooltipStatus());
        }
      }
    }, [isViewTooltip.tooltip, isFirstTooltip]);

    const hideTooltipHandler = () => {
      storageDb.setTooltipStatus('false');
      setIsViewTooltip({ tooltip: 'false' });
    };

    return (
      <div className='relative flex sm:items-center sm:justify-center'>
        {noIcon ? null : (
          <div className='flex items-center cursor-pointer' onClick={openDrawerHandler}>
            <Icon
              icon='info-sign'
              color={isRed ? 'red' : iconColor || '#37833B'}
              className={`mr-[0.375rem] ${className}`}
            />
            <span className='text-green1'>{TOOLTIPS_TEXT.tooltipTitle}</span>
          </div>
        )}
        {isFirstTooltip && !isViewTooltip.tooltip.includes('false') ? (
          <FirstTooltip
            bodyText={TOOLTIPS_TEXT.firstTooltipText}
            color='default'
            view='filled'
            className='top-0 left-0 translate-y-[-111%] translate-x-[-49.8%] absolute w-[334px] z-[999]'
            callback={hideTooltipHandler}
          />
        ) : null}
      </div>
    );
  },
);
