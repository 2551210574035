import React, { memo } from 'react';
//
import { IWithChildren } from '@models/index';

interface IH1Props extends IWithChildren {
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const H1 = memo(({ children, className = '' }: IH1Props) => (
  <h1
    className={`font-inter text-h1_header lg:text-h1 md:text-h2Mobile font-bold text-blackHeader m-0 !tracking-[-0.04rem] ${className}`}>
    {children}
  </h1>
));

H1.displayName = 'H1';
