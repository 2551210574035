import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
//
import { Menu } from '@uikit/Menu';
import { NavLi } from '@blocks/navLi';
import { CONFIG } from '@const/config';
import { Dropdown } from '@uikit/Dropdown';
import { storageDb } from '@api/storageApi';
import { ACCESS_RIGHTS } from '@const/access';
import { PATH_NAMES } from '@const/httpConst';
import { MenuItem } from '@uikit/Menu/MenuItem';
import { getAccountsData } from '@redux/selectors';
import { BBAdsSideNav } from '@blocks/bbAdsSideNav';
import { getAccounts } from '@redux/Accounts/thunks';
import { MenuDivider } from '@uikit/Menu/MenuDivider';
import { amplitudeSetUserId } from '@helpers/amplitude';
import { setSelectedFilial } from '@redux/Accounts/slice';
import { ABNewAdsSideNav } from '@blocks/abNewAdsSideNav';
import { getFilialName, getLinkHref } from '@helpers/index';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { SIDE_NAV_PAGE_NAMES } from '@data/dict';

interface ISideNavProps {
  /**
   * Опциональный флаг показывает или скрывает пункт меню "Филиалы"
   * @param {boolean}
   */
  isMobile?: boolean;
  /**
   * Callback вызывается при нажатии на пункт меню
   * @param {() => void}
   */
  onClickHandler: () => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const SideNav = memo(({ isMobile, onClickHandler, className = '' }: ISideNavProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const pathName = useLocation().pathname;
  const { data, isRetries, isLoading, selectedFilial, bannerData } =
    useAppSelector(getAccountsData);

  const { auth } = storageDb.getAuth();

  const [openMenu, setOpenMenu] = useState<string>('');

  useEffect(() => {
    if (pathName.includes(PATH_NAMES.ANALYTICS)) setOpenMenu(SIDE_NAV_PAGE_NAMES.ANALYTICS);
    if (pathName.includes(PATH_NAMES.DASHBOARD)) setOpenMenu(SIDE_NAV_PAGE_NAMES.DASHBOARD);
    if (pathName.includes(PATH_NAMES.TASKS_ALL)) setOpenMenu(SIDE_NAV_PAGE_NAMES.TASKS);
    if (pathName.includes(PATH_NAMES.SETTINGS)) setOpenMenu(SIDE_NAV_PAGE_NAMES.SETTINGS);
  }, [pathName]);

  const changeNewCommercialOnClick = useCallback(() => {
    const target = '_blank';
    const newWindow = window.open(bannerData.infoUrl, target, 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  }, [bannerData.infoUrl]);

  // Закрывает меню в мобильном разрешении
  const closeMenu = useCallback(() => {
    if (isMobile) {
      onClickHandler();
    }
  }, [isMobile, onClickHandler]);

  // Открывает ландинг бютибота в новом окне
  const changePageOnClick = useCallback(() => {
    const target = '_blank';
    const newWindow = window.open(CONFIG.BB_INTEGRATION_LINK, target, 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  }, []);

  // Запрашивает список филиалов при рендеренге компонента если нет списка филиалов в store
  useEffect(() => {
    if (!data.length && auth) {
      dispatch(getAccounts());
    }
  }, [auth, data, dispatch]);

  // Запрашивает список филиалов повторно при ошибке токена и если нет списка филиалов в store
  useEffect(() => {
    if (isRetries && !data.length && auth) {
      setTimeout(() => dispatch(getAccounts()), 100);
    }
  }, [auth, data, isRetries, dispatch]);

  // Эффект при перезагрузке страницы считывает записанный в LS номер филиала
  // находит филиал в списке и записывает данные выбранного филиала в state
  useEffect(() => {
    if (!isLoading) {
      const { accId } = storageDb.getSelectedFilialData();
      const filial = data.filter(item => item.accId === accId)[0];
      if (filial && accId !== selectedFilial?.accId) {
        dispatch(
          setSelectedFilial({
            accId: filial.accId,
            branchId: filial.branchId,
            filialName: filial.name,
            rights: filial.rights,
          }),
        );
        amplitudeSetUserId(String(filial.branchId));
      }
    }
  }, [isLoading, selectedFilial, dispatch, data]);

  // Записывает данные выбранного филиала в state
  const selectFilial = useCallback(
    (accId: string) => {
      const { pathname } = window.location;
      const filial = data.filter(item => item.accId === accId)[0];
      dispatch(
        setSelectedFilial({
          accId: filial.accId,
          branchId: filial.branchId,
          filialName: filial.name,
          rights: filial.rights,
        }),
      );
      amplitudeSetUserId(String(filial.branchId));
      navigate(`${pathname}?acc_id=${accId}`);
    },
    [data, dispatch, navigate],
  );

  // При изменении филиала переводим пользователя на список регулярных задач
  const selectFilialHandler = useCallback(
    (accId: string) => () => {
      selectFilial(accId);
      navigate(`${PATH_NAMES.TASKS_ALL}?acc_id=${accId}`);
    },
    [selectFilial, navigate],
  );

  // Подготавливает список филиалов для отображения
  const filialList = useMemo(
    () =>
      data.map(item => (
        <MenuItem
          key={item.accId}
          text={<span>{item.name}</span>}
          onClick={selectFilialHandler(item.accId)}
          className={` ${selectedFilial?.accId === item.accId ? '!bg-ivory' : ''}`}
        />
      )),
    [data, selectedFilial?.accId, selectFilialHandler],
  );

  // Обрезает длинное название филиала до 21 символа и добавляет ...
  const filialName = useMemo(
    () => getFilialName(selectedFilial?.name || ''),
    [selectedFilial?.name],
  );

  // Массив прав выбранного филиала
  const selectedFilialRights = selectedFilial?.rights || [];

  // // Проверяем наличие прав владельца
  // const isOwner = selectedFilialRights.includes(ACCESS_RIGHTS.OWNER);

  // Проверяем наличие прав на пользование настройками
  const isShowSettings = selectedFilialRights.includes(ACCESS_RIGHTS.SETTINGS);

  // Проверяем наличие прав на пользование страницей Админы
  const isShowAdminsInSettings = selectedFilialRights.includes(ACCESS_RIGHTS.SETTINGS_ADMINS);

  // Проверяем наличие прав на пользование аналитикой
  const isShowAnalytics = selectedFilialRights.includes(ACCESS_RIGHTS.ANALYTICS);

  // Проверяем наличие прав на пользование шаблонами отчетов
  const isShowTemplates = selectedFilialRights.includes(ACCESS_RIGHTS.TEMPLATES);

  const memoizedFilialListDropDown = useMemo(
    () => (
      <Dropdown
        view='outlined'
        color='success'
        position='bottom-left'
        className={`w-[17.5rem] !bg-left-4 ${
          selectedFilial?.process ? '!bg-connected' : '!bg-connectionError'
        } !bg-no-repeat`}
        text={<span className='!font-normal text-body2'>{filialName}</span>}
        title={selectedFilial?.name}
        content={
          <Menu className={filialList.length === 1 ? `one_item_ul` : ''} view='raised'>
            {filialList}
          </Menu>
        }
      />
    ),
    [filialName, selectedFilial?.name, selectedFilial?.process, filialList],
  );

  const renderBBIntegration = useMemo(() => {
    if (isLoading || !selectedFilial || selectedFilial.beautyBotIntegration) {
      return null;
    }

    return <BBAdsSideNav changePageOnClick={changePageOnClick} />;
  }, [changePageOnClick, isLoading, selectedFilial]);

  const openMenuHandler = useCallback(
    (menuItem: string) => () => {
      if (openMenu === menuItem) {
        setOpenMenu('');
      } else {
        setOpenMenu(menuItem);
      }
    },
    [openMenu],
  );

  const isAnalytics = useMemo(() => {
    const isActive = pathName === PATH_NAMES.ANALYTICS;
    const isOpen = openMenu === SIDE_NAV_PAGE_NAMES.ANALYTICS;
    return {
      isActive,
      isOpen,
    };
  }, [pathName, openMenu]);

  // const isDashboard = useMemo(() => {
  //   const isActive = pathName === PATH_NAMES.DASHBOARD;
  //   const isOpen = openMenu === SIDE_NAV_PAGE_NAMES.DASHBOARD;
  //   return {
  //     isActive,
  //     isOpen,
  //   };
  // }, [pathName, openMenu]);

  const isTasks = useMemo(() => {
    const isActive = pathName === PATH_NAMES.TASKS_ALL;
    const isOpen = openMenu === SIDE_NAV_PAGE_NAMES.TASKS;
    return {
      isActive,
      isOpen,
    };
  }, [pathName, openMenu]);

  const isSettings = useMemo(() => {
    const isActive = pathName === PATH_NAMES.SETTINGS;
    const isOpen = openMenu === SIDE_NAV_PAGE_NAMES.SETTINGS;
    return {
      isActive,
      isOpen,
    };
  }, [pathName, openMenu]);

  return (
    <div className={className}>
      {memoizedFilialListDropDown}
      <nav className='mt-6 min-w-[17.5rem]'>
        <ul className='w-full'>
          <MenuDivider color='default' className='!my-[0.5rem] !mx-0' />
          {isMobile ? (
            <>
              <NavLi
                onClick={closeMenu}
                href={PATH_NAMES.ACCOUNTS}
                active={pathName.includes(PATH_NAMES.ACCOUNTS)}>
                {SIDE_NAV_PAGE_NAMES.FILIALS}
              </NavLi>
              <MenuDivider color='default' className='!my-[0.5rem] !mx-0' />
            </>
          ) : null}
          {isShowAnalytics ? (
            <>
              <NavLi
                isDropDown
                onClick={openMenuHandler(SIDE_NAV_PAGE_NAMES.ANALYTICS)}
                active={isAnalytics.isActive}
                href={getLinkHref({
                  route: PATH_NAMES.ANALYTICS,
                  accId: selectedFilial?.accId || '',
                })}
                isOpen={isAnalytics.isOpen}>
                {SIDE_NAV_PAGE_NAMES.ANALYTICS}
              </NavLi>
              <ul
                className={`${
                  isAnalytics.isOpen ? 'max-h-[7.5rem]' : 'max-h-0'
                } overflow-hidden transition-max-height duration-700 ease-in-out`}>
                <NavLi
                  isSecondary
                  onClick={closeMenu}
                  active={pathName === PATH_NAMES.ANALYTICS_ADMINS}
                  href={getLinkHref({
                    route: PATH_NAMES.ANALYTICS_ADMINS,
                    accId: selectedFilial?.accId || '',
                  })}>
                  {SIDE_NAV_PAGE_NAMES.ADMINS}
                </NavLi>
                <NavLi
                  isSecondary
                  onClick={closeMenu}
                  active={pathName === PATH_NAMES.ADMINS_TABLE}
                  href={getLinkHref({
                    route: PATH_NAMES.ADMINS_TABLE,
                    accId: selectedFilial?.accId || '',
                  })}>
                  {SIDE_NAV_PAGE_NAMES.CALLS_DETAILS}
                </NavLi>
              </ul>
              <MenuDivider color='default' className='!my-[0.5rem] !mx-0' />
            </>
          ) : null}
          {/* {false ? (
            <>
              <NavLi
                isDropDown
                onClick={openMenuHandler(SIDE_NAV_PAGE_NAMES.DASHBOARD)}
                active={isDashboard.isActive}
                href={getLinkHref({
                  route: PATH_NAMES.DASHBOARD,
                  accId: selectedFilial?.accId || '',
                })}
                isOpen={isDashboard.isOpen}>
                Дашборд
              </NavLi>
              <ul
                className={`${
                  isDashboard.isOpen ? 'max-h-[7.5rem]' : 'max-h-0'
                } overflow-hidden transition-max-height duration-700 ease-in-out`}>
                <NavLi
                  isSecondary
                  onClick={closeMenu}
                  active={pathName.includes(PATH_NAMES.FINANCES)}
                  href={getLinkHref({
                    route: PATH_NAMES.FINANCES,
                    accId: selectedFilial?.accId || '',
                  })}>
                  Салон
                </NavLi>
                <NavLi
                  isSecondary
                  onClick={closeMenu}
                  active={pathName.includes(PATH_NAMES.MASTERS)}
                  href={getLinkHref({
                    route: PATH_NAMES.MASTERS,
                    accId: selectedFilial?.accId || '',
                  })}>
                  Мастера
                </NavLi>
                <NavLi
                  isSecondary
                  onClick={closeMenu}
                  active={pathName.includes(PATH_NAMES.ADMINS)}
                  href={getLinkHref({
                    route: PATH_NAMES.ADMINS,
                    accId: selectedFilial?.accId || '',
                  })}>
                  Админы
                </NavLi>
              </ul>
              <MenuDivider color='default' className='!my-[0.5rem] !mx-0' />
            </>
          ) : null} */}
          {isShowTemplates ? (
            <>
              <NavLi
                onClick={openMenuHandler(SIDE_NAV_PAGE_NAMES.REPORTS)}
                active={pathName.includes(PATH_NAMES.REPORTS)}
                href={getLinkHref({
                  route: PATH_NAMES.REPORTS,
                  accId: selectedFilial?.accId || '',
                })}>
                {SIDE_NAV_PAGE_NAMES.REPORTS}
              </NavLi>
              <MenuDivider color='default' className='!my-[0.5rem] !mx-0' />
            </>
          ) : null}
          <NavLi
            isDropDown
            onClick={openMenuHandler(SIDE_NAV_PAGE_NAMES.TASKS)}
            active={isTasks.isActive}
            isOpen={isTasks.isOpen}
            href={getLinkHref({
              route: PATH_NAMES.TASKS_ALL,
              accId: selectedFilial?.accId || '',
            })}>
            {SIDE_NAV_PAGE_NAMES.TASKS}
          </NavLi>
          <ul
            className={`${
              isTasks.isOpen ? 'max-h-[7.5rem]' : 'max-h-0'
            } overflow-hidden transition-max-height duration-700 ease-in-out`}>
            <NavLi
              isSecondary
              onClick={closeMenu}
              active={pathName.includes(PATH_NAMES.TASKS_REGULAR)}
              href={getLinkHref({
                route: PATH_NAMES.TASKS_REGULAR,
                accId: selectedFilial?.accId || '',
              })}>
              {SIDE_NAV_PAGE_NAMES.REGULAR_TASKS}
            </NavLi>
            <NavLi
              isSecondary
              onClick={closeMenu}
              active={pathName.includes(PATH_NAMES.TASKS_EVENT)}
              href={getLinkHref({
                route: PATH_NAMES.TASKS_EVENT,
                accId: selectedFilial?.accId || '',
              })}>
              {SIDE_NAV_PAGE_NAMES.EVENT_TASKS}
            </NavLi>
            <NavLi
              isSecondary
              onClick={closeMenu}
              active={pathName.includes(PATH_NAMES.TASKS_CLIENTS)}
              href={getLinkHref({
                route: PATH_NAMES.TASKS_CLIENTS,
                accId: selectedFilial?.accId || '',
              })}>
              {SIDE_NAV_PAGE_NAMES.CLIENTS_TASKS}
            </NavLi>
          </ul>
          <MenuDivider color='default' className='!my-[0.5rem] !mx-0' />
          <NavLi
            onClick={closeMenu}
            active={
              pathName.includes(PATH_NAMES.NOTE_LIST) ||
              pathName.includes(PATH_NAMES.NEW_NOTE) ||
              pathName.includes(PATH_NAMES.ONE_NOTE)
            }
            href={getLinkHref({
              route: PATH_NAMES.NOTE_LIST,
              accId: selectedFilial?.accId || '',
            })}>
            {SIDE_NAV_PAGE_NAMES.NOTES}
          </NavLi>
          {isShowSettings ? (
            <>
              <MenuDivider color='default' className='!my-[0.5rem] !mx-0' />
              <NavLi
                isDropDown
                onClick={openMenuHandler(SIDE_NAV_PAGE_NAMES.SETTINGS)}
                isOpen={isSettings.isOpen}
                href={getLinkHref({
                  route: PATH_NAMES.SETTINGS,
                  accId: selectedFilial?.accId || '',
                })}>
                {SIDE_NAV_PAGE_NAMES.SETTINGS}
              </NavLi>
              <ul
                className={`${
                  isSettings.isOpen
                    ? `${isShowAdminsInSettings ? 'max-h-[12.5rem]' : 'max-h-[10rem]'}`
                    : 'max-h-0'
                } overflow-hidden transition-max-height duration-700 ease-in-out`}>
                <NavLi
                  isSecondary
                  onClick={closeMenu}
                  active={pathName === PATH_NAMES.SETTINGS}
                  href={getLinkHref({
                    route: PATH_NAMES.SETTINGS,
                    accId: selectedFilial?.accId || '',
                  })}>
                  {SIDE_NAV_PAGE_NAMES.GENERAL_SETTINGS}
                </NavLi>
                <NavLi
                  isSecondary
                  onClick={closeMenu}
                  active={pathName.includes(PATH_NAMES.TELEPHONY)}
                  href={getLinkHref({
                    route: PATH_NAMES.TELEPHONY,
                    accId: selectedFilial?.accId || '',
                  })}>
                  {SIDE_NAV_PAGE_NAMES.TELEPHONY}
                </NavLi>
                <NavLi
                  isSecondary
                  onClick={closeMenu}
                  active={pathName.includes(PATH_NAMES.SETTINGS_NOTIFICATIONS)}
                  href={getLinkHref({
                    route: PATH_NAMES.SETTINGS_NOTIFICATIONS,
                    accId: selectedFilial?.accId || '',
                  })}>
                  {SIDE_NAV_PAGE_NAMES.SETTINGS_NOTIFICATIONS}
                </NavLi>
                {isShowAdminsInSettings ? (
                  <NavLi
                    isSecondary
                    onClick={closeMenu}
                    active={pathName.includes(PATH_NAMES.SETTINGS_ADMINS)}
                    href={getLinkHref({
                      route: PATH_NAMES.SETTINGS_ADMINS,
                      accId: selectedFilial?.accId || '',
                    })}>
                    {SIDE_NAV_PAGE_NAMES.SETTINGS_ADMINS}
                  </NavLi>
                ) : null}
                <NavLi
                  isSecondary
                  onClick={closeMenu}
                  active={pathName.includes(PATH_NAMES.SETTINGS_PAY)}
                  href={getLinkHref({
                    route: PATH_NAMES.SETTINGS_PAY,
                    accId: selectedFilial?.accId || '',
                  })}>
                  {SIDE_NAV_PAGE_NAMES.PAY_DETAILS}
                </NavLi>

                {/* TODO: Отключено по задаче AB-283 */}

                {/* <NavLi
                  isSecondary
                  onClick={closeMenu}
                  active={pathName.includes(PATH_NAMES.SETTINGS_MASTERS)}
                  href={getLinkHref({
                    route: PATH_NAMES.SETTINGS_MASTERS,
                    accId: selectedFilial?.accId || '',
                  })}>
                  {SIDE_NAV_PAGE_NAMES.SETTINGS_MASTERS}
                </NavLi> */}
                <NavLi
                  isSecondary
                  onClick={closeMenu}
                  active={pathName.includes(PATH_NAMES.BLACKLIST)}
                  href={getLinkHref({
                    route: PATH_NAMES.BLACKLIST,
                    accId: selectedFilial?.accId || '',
                  })}>
                  {SIDE_NAV_PAGE_NAMES.BLACKLIST}
                </NavLi>
              </ul>
            </>
          ) : null}
          <MenuDivider color='default' className='!my-[0.5rem] !mx-0' />
          <NavLi
            active={pathName.includes(PATH_NAMES.PAY)}
            href={getLinkHref({
              route: PATH_NAMES.PAY,
              accId: selectedFilial?.accId || '',
            })}>
            {SIDE_NAV_PAGE_NAMES.PAY}
          </NavLi>
          <MenuDivider color='default' className='!my-[0.5rem] !mx-0 !border-t-4' />
        </ul>
        {renderBBIntegration}
        {bannerData.imageUrl ? (
          <ABNewAdsSideNav
            imgURL={bannerData.imageUrl}
            changePageOnClick={changeNewCommercialOnClick}
            className='mt-2'
          />
        ) : null}
      </nav>
    </div>
  );
});
