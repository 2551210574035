import { TRequestStatus } from '@api/types';
import { apiService } from '@api/ApiService';
import { TRenameFolderData } from '@redux/noteList/types';
import { END_POINTS_URL, QUERY_PARAMS } from '@const/apiConstants';
import {
  TNoteTagResponse,
  TNoteToCreateData,
  TNoteResponseData,
  TNoteListPageData,
  TNoteFolderResponse,
  TOneNoteResponseData,
} from '@models/Note';

export const noteApi = {
  getNotesPageData() {
    return apiService.get<TNoteListPageData | TRequestStatus>(END_POINTS_URL.ALL_NOTE_PAGE_DATA);
  },
  getAllTags() {
    return apiService.get<TNoteTagResponse[] | TRequestStatus>(END_POINTS_URL.TAGS);
  },
  createTag(title: string) {
    return apiService.post<TNoteTagResponse | TRequestStatus>(END_POINTS_URL.TAGS, { title });
  },
  deleteTag(id: string) {
    return apiService.delete<void | TRequestStatus>(
      `${END_POINTS_URL.TAGS}/${id}`,
      {},
      {
        params: {
          [QUERY_PARAMS.TAG_ID]: id,
        },
      },
    );
  },
  getAllFolders() {
    return apiService.get<TNoteFolderResponse[] | TRequestStatus>(END_POINTS_URL.FOLDERS, {});
  },
  createFolder(title: string) {
    return apiService.post<TNoteFolderResponse | TRequestStatus>(END_POINTS_URL.FOLDERS, { title });
  },
  getFolderById(id: string) {
    return apiService.get<TNoteFolderResponse | TRequestStatus>(
      `${END_POINTS_URL.FOLDERS}/${id}`,
      {},
    );
  },
  renameFolder({ folderId, title }: TRenameFolderData) {
    return apiService.patch<TNoteFolderResponse | TRequestStatus>(
      `${END_POINTS_URL.FOLDERS}/${folderId}`,
      { title },
      {
        params: {
          [QUERY_PARAMS.FOLDER_ID]: folderId,
        },
      },
    );
  },
  deleteFolder(folderId: string) {
    return apiService.delete<string | TRequestStatus>(
      `${END_POINTS_URL.FOLDERS}/${folderId}`,
      {},
      {
        params: {
          [QUERY_PARAMS.FOLDER_ID]: folderId,
        },
      },
    );
  },
  getAllNotes() {
    return apiService.get<TNoteResponseData[] | TRequestStatus>(END_POINTS_URL.NOTES);
  },
  createNote(noteData: TNoteToCreateData) {
    return apiService.post<TNoteResponseData | TRequestStatus>(END_POINTS_URL.NOTES, noteData);
  },
  getNoteById(noteId: string) {
    return apiService.get<TOneNoteResponseData | TRequestStatus>(
      `${END_POINTS_URL.NOTES}/${noteId}`,
      {
        [QUERY_PARAMS.NOTE_ID]: noteId,
      },
    );
  },
  changeNote({ noteId, noteData }: { noteData: TNoteToCreateData; noteId: string }) {
    return apiService.patch<TRequestStatus>(`${END_POINTS_URL.NOTES}/${noteId}`, noteData, {
      params: {
        [QUERY_PARAMS.NOTE_ID]: noteId,
      },
    });
  },
  deleteNoteById(noteId: string) {
    return apiService.delete<TRequestStatus>(
      `${END_POINTS_URL.NOTES}/${noteId}`,
      {},
      {
        params: {
          [QUERY_PARAMS.NOTE_ID]: noteId,
        },
      },
    );
  },
  attachNoteToTask({ noteId, templateId }: { noteId: string; templateId: string }) {
    return apiService.post<TRequestStatus>(
      END_POINTS_URL.ATTACH_NOTE_TO_TASK,
      {},
      {
        params: {
          [QUERY_PARAMS.NOTE_ID]: noteId,
          [QUERY_PARAMS.TEMPLATE_ID]: templateId,
        },
      },
    );
  },
  deleteNoteFromTask({ noteId, templateId }: { noteId: string; templateId: string }) {
    return apiService.get(`${END_POINTS_URL.UNPIN_NOTE_FROM_TASK}/${noteId}`, {
      [QUERY_PARAMS.NOTE_ID]: noteId,
      [QUERY_PARAMS.TEMPLATE_ID]: templateId,
    });
  },
};
