import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';
//
import { Icon } from '@atoms/icon';
import { PATH_NAMES } from '@const/httpConst';
import { taskPageNameDict } from '@const/task';

interface ITaskPathLinksProps {
  /**
   * Id задачи, если передано отличное от '' значение,
   * отображается средний элемент пути и меняется заголовок с
   * "Новая задача" на "ЗЗадача №Id"
   */
  id: string;
  /**
   * Флаг состояния загрузки, если идет загрузка заголовок меняется на "Задача..."
   * @param {boolean}
   */
  isLoading: boolean;
  /**
   * Параметр для формирования ссылки на соответствующий раздел задач
   * @param {string}
   */
  taskTypeToPath: string;
  /**
   * Id выбранного филиала
   * @param {string}
   */
  selectedFilialAccId: string;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
}

export const TaskPathLinks = memo(
  ({ id, isLoading, taskTypeToPath, selectedFilialAccId, className = '' }: ITaskPathLinksProps) => {
    const isShowMiddleNavLink = taskTypeToPath && id;
    return (
      <div className={`flex flex-wrap items-center ${className}`}>
        <NavLink
          to={`${PATH_NAMES.TASKS_ALL}?acc_id=${selectedFilialAccId}`}
          className='flex items-center text-body_text text-gray2 hover:no-underline hover:text-gray2 mr-[1.125rem] tracking-[0.0275em]'>
          Задачи
          <Icon variant='chevronRight' className='ml-[1.125rem]' />
        </NavLink>
        {isShowMiddleNavLink ? (
          <NavLink
            to={`/tasks/${taskTypeToPath}?acc_id=${selectedFilialAccId}`}
            className='flex items-center text-body_text text-gray2 hover:no-underline hover:text-gray2 mr-[1.125rem] tracking-[0.0275em]'>
            {taskPageNameDict[`/tasks/${taskTypeToPath}`]}
            <Icon variant='chevronRight' className='ml-[1.125rem]' />
          </NavLink>
        ) : null}
        <p className='font-medium flex items-center m-0 text-body_text text-blackText tracking-[0.022em]'>
          <Icon variant='document' className='mr-2.5 h-5' />
          {!isLoading ? (id ? `Задача №${id}` : 'Новая задача') : 'Задача...'}
        </p>
      </div>
    );
  },
);
