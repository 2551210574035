import { TFolderItem, TNoteItem, TNoteTag } from '@redux/noteList/zod';

export const COMMON_FOLDER: TFolderItem = {
  title: 'Все заметки',
  id: '0',
  companyId: 0,
  createdAt: '',
  updatedAt: '',
  numberOfNotes: 0,
};

export const folderList: TFolderItem[] = [
  {
    title: 'Администраторы',
    id: '1',
    companyId: 0,
    createdAt: '',
    updatedAt: '',
    numberOfNotes: 1,
  },
  {
    title: 'Актуальное',
    id: '2',
    companyId: 0,
    createdAt: '',
    updatedAt: '',
    numberOfNotes: 11,
  },
  {
    title: 'Личная папка',
    id: '3',
    companyId: 0,
    createdAt: '',
    updatedAt: '',
    numberOfNotes: 0,
  },
];

export const notesCardList: TNoteItem[] = [
  {
    id: '0e01bda3-20d5-4618-adf8-8ec9828f5117',
    folder: null,
    header: 'Список для закупки',
    text: 'Средства для дезинфекции\n Туалетная бумага',
    tags: [
      {
        title: 'Списки',
        id: '90bcf1f9-efce-47a8-a581-f5f3e54a0eb4',
      },
      {
        title: 'Закупки',
        id: '1f44eff4-9916-4726-b432-778b58059b81',
      },
    ],
    isPrivate: false,
  },
  {
    id: '2',
    folder: null,
    header: 'Скрипт - новые клиенты',
    text: 'Скрипт для обработки звонков новым клиентам',
    tags: [
      {
        title: 'Скрипты',
        id: '90bcf1f9-efce-47a8-a581-f5f3e54a0eb4',
      },
      {
        title: 'Новые клиенты',
        id: '1f44eff4-9916-4726-b432-778b58059b81',
      },
    ],
    isPrivate: false,
  },
  {
    id: '3',
    folder: null,
    header: 'Список админов',
    text: 'Администраторы салона:\n...',
    tags: [
      {
        title: 'test',
        id: '90bcf1f9-efce-47a8-a581-f5f3e54a0eb4',
      },
      {
        title: 'test',
        id: '1f44eff4-9916-4726-b432-778b58059b81',
      },
    ],
    isPrivate: true,
  },
  {
    id: '4',
    folder: null,
    header: 'Админы на премию',
    text: 'Текст заметки: необходимо составить расписание мастеров',
    tags: [
      {
        title: 'Списки',
        id: '90bcf1f9-efce-47a8-a581-f5f3e54a0eb4',
      },
      {
        title: 'Админы',
        id: '1f44eff4-9916-4726-b432-778b58059b81',
      },
    ],
    isPrivate: false,
  },
  {
    id: '5',
    folder: null,
    header: 'Секретная заметка',
    text: '1',
    tags: [
      {
        title: 'Управление',
        id: '90bcf1f9-efce-47a8-a581-f5f3e54a0eb4',
      },
      {
        title: 'Админы',
        id: '1f44eff4-9916-4726-b432-778b58059b81',
      },
    ],
    isPrivate: true,
  },
  {
    id: '6',
    folder: null,
    header:
      'Админы на ПокровскойАдмины на ПокровскойАдмины на ПокровскойАдмины на ПокровскойАдмины на Покровской',
    text: 'Текст заметки: необходимо составить расписание мастеров н',
    tags: [
      {
        title: 'Списки',
        id: '90bcf1f9-efce-47a8-a581-f5f3e54a0eb4',
      },
      {
        title: 'Админы',
        id: '1f44eff4-9916-4726-b432-778b58059b81',
      },
    ],
    isPrivate: false,
  },
];

export const tags: TNoteTag[] = [
  {
    title: 'test',
    id: '90bcf1f9-efce-47a8-a581-f5f3e54a0eb4',
    companyId: 367678,
    updatedAt: null,
    createdAt: '2024-08-04T20:38:53.781000',
  },
  {
    title: 'test1',
    id: '1f44eff4-9916-4726-b432-778b58059b81',
    companyId: 367678,
    updatedAt: null,
    createdAt: '2024-08-04T20:39:12.932000',
  },
  {
    title: 'test2',
    id: '30cc9be2-6011-416e-a7f5-e4eb6acb294a',
    companyId: 367678,
    updatedAt: null,
    createdAt: '2024-08-04T20:39:12.932000',
  },
  {
    title: 'test3',
    id: '0ed0edb9-052e-4cf0-9050-eedb9f9bfacb',
    companyId: 367678,
    updatedAt: null,
    createdAt: '2024-08-04T20:39:12.932000',
  },
];
