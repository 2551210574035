import React from 'react';
import { Transforms, Editor } from 'slate';
import addImageIcon from '@img/notes/richTextEditor/image.svg';
import deleteImageIcon from '@img/notes/richTextEditor/delete.svg';
import { imageExtension } from '@components/sladeEditor/imageExtension';
import { useSlateStatic, useSelected, useFocused, ReactEditor, useReadOnly } from 'slate-react';

import { Button } from './components';
import { TImageElement } from './custom-types.d';

const isValidUrl = (urlString: string) => {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
};

// eslint-disable-next-line
// @ts-ignore
export const Image = ({ attributes, children, element }) => {
  const editor = useSlateStatic();
  // eslint-disable-next-line
  // @ts-ignore
  const path = ReactEditor.findPath(editor, element);

  const selected = useSelected();
  const focused = useFocused();
  const readOnly = useReadOnly();

  const removeImageHandler = () => {
    if (!readOnly) {
      Transforms.removeNodes(editor, { at: path });
    }
  };

  return (
    <div {...attributes}>
      {children}
      <div
        contentEditable={false}
        className='group relative'
        // className={css`
        //   position: relative;
        // `}
      >
        <img
          src={element.url}
          alt='added'
          className={`block max-w-[100%] max-h-[20em] ${
            selected && focused ? 'shadow-[0_0_0_3px_rgba(180,213,255,1)]' : ''
          }`}
          // className={css`
          //   display: block;
          //   max-width: 100%;
          //   max-height: 20em;
          //   box-shadow: ${selected && focused ? '0 0 0 3px #B4D5FF' : 'none'};
          // `}
        />
        <Button
          active
          reversed={false}
          onClick={removeImageHandler}
          className={`absolute top-2 left-2 bg-white hidden ${
            !readOnly ? 'group-hover:inline' : ''
          }`}
          //   css`
          // display: ${selected && focused ? 'inline' : 'none'};
          // position: absolute;
          // top: 0.5em;
          // left: 0.5em;
          // background-color: white;
          // `
        >
          <img src={deleteImageIcon} alt='delete' className='' />
        </Button>
      </div>
    </div>
  );
};

const isImageUrl = (url: string) => {
  if (!url) return false;
  if (!isValidUrl(url)) return false;
  const ext = new URL(url).pathname.split('.').pop();
  return imageExtension.includes(ext || '');
};

// eslint-disable-next-line
// @ts-ignore
const insertImage = (editor: Editor, url) => {
  const image: TImageElement = { type: 'image', url, children: [{ text: '' }] };
  Transforms.insertNodes(editor, image);
  Transforms.insertNodes(editor, {
    // eslint-disable-next-line
    // @ts-ignore
    type: 'paragraph',
    children: [{ text: '' }],
  });
};

export const InsertImageButton = () => {
  const editor = useSlateStatic();
  return (
    <Button
      active={false}
      reversed={false}
      // eslint-disable-next-line
      // @ts-ignore
      onMouseDown={event => {
        event.preventDefault();
        // eslint-disable-next-line
        const url = window.prompt('Введите URL-адрес изображения:');
        if (url && !isImageUrl(url)) {
          // eslint-disable-next-line
          alert('URL не является изображением');
          return;
        }
        if (url && isImageUrl(url)) {
          insertImage(editor, url);
        }
      }}
      className='group'>
      <img src={addImageIcon} alt='add' className='opacity-40 group-hover:opacity-100' />
    </Button>
  );
};

// eslint-disable-next-line
// @ts-ignore
export const withImages = editor => {
  // eslint-disable-next-line
  // @ts-ignore
  const { insertData, isVoid } = editor;

  // eslint-disable-next-line
  // @ts-ignore
  editor.isVoid = element => {
    return element.type === 'image' ? true : isVoid(element);
  };
  // eslint-disable-next-line
  // @ts-ignore
  editor.insertData = data => {
    const text = data.getData('text/plain');
    const { files } = data;

    if (files && files.length > 0) {
      // eslint-disable-next-line
      for (const file of files) {
        const reader = new FileReader();
        const [mime] = file.type.split('/');

        if (mime === 'image') {
          reader.addEventListener('load', () => {
            const url = reader.result;
            insertImage(editor, url);
          });

          reader.readAsDataURL(file);
        }
      }
    } else if (isImageUrl(text)) {
      insertImage(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};
