import { TAG_PREFIX } from '@const/notes';
import { TFolderItem } from '@redux/noteList/zod';
import { COMMON_FOLDER } from '@redux/noteList/mockData';
import { TNote, TNoteWithAttachedTemplateData } from '@redux/note/zod';
import { TNoteResponseData, TNoteToCreateData, TOneNoteResponseData } from '@models/Note';

export const noteMappers = {
  mapToRedux({ id, title, tags, body, is_private, folder }: TNoteResponseData): TNote {
    return {
      id,
      title,
      folder,
      description: body,
      isPrivate: is_private,
      tags: tags.map(tag => ({ ...tag, title: `#${tag.title}` })),
    };
  },
  mapOneNoteToRedux({
    note,
    attached_templates,
  }: TOneNoteResponseData): TNoteWithAttachedTemplateData {
    const { id, title, folder, body, is_private, tags } = note;

    return {
      note: {
        id,
        title,
        folder,
        description: body,
        isPrivate: is_private,
        tags: tags.map(tag => ({ ...tag, title: `#${tag.title}` })),
      },
      attachedTemplates: attached_templates,
    };
  },
  mapToBack(
    { title, tags, description, isPrivate }: TNote,
    activeFolder: TFolderItem,
  ): TNoteToCreateData {
    const folder =
      activeFolder.id !== COMMON_FOLDER.id
        ? {
            id: activeFolder.id,
            title: activeFolder.title,
          }
        : null;

    return {
      title,
      folder,
      body: description,
      is_private: isPrivate,
      tags: tags.map(tag => ({ ...tag, title: tag.title.replace(TAG_PREFIX, '') })),
    };
  },
};
