import React, { memo } from 'react';
import { Icon } from '@blueprintjs/core';
//
import { Button } from '@uikit/Button';
import { Drawer } from '@uikit/Drawer';
import { Dialog } from '@uikit/Dialog';
import { TNonePageStatus } from '@redux/note/zod';
import { useWindowWidth } from '@hooks/useWindowWidth';

type TProps = {
  /**
   * Статус запросов на страницы
   * @param {TNonePageStatus}
   */
  status: TNonePageStatus;
  /**
   * Флаг показа модалки
   * @param {boolean}
   */
  isOpen: boolean;
  /**
   * Заголовок модального окна
   * @param {string}
   */
  title: string;
  /**
   * Текст диалога
   * @param {string}
   */
  description: string;
  /**
   * Callback на кнопку отмены
   * @param {() => void}
   */
  cancelButtonCallback: () => void;
  /**
   * текст в кнопку отмены
   * @param {string}
   */
  cancelButtonText: string;
  /**
   * Callback на кнопку подтверждения
   * @param {() => void}
   */
  successButtonCallback: () => void;
  /**
   * текст в кнопку подтверждения
   * @param {string}
   */
  successButtonText: string;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const NoteSuccessModal = memo(
  ({
    title,
    isOpen,
    status,
    description,
    cancelButtonText,
    successButtonText,
    successButtonCallback,
    cancelButtonCallback,
    className = '',
  }: TProps) => {
    const width = useWindowWidth();

    const isLoading = status === 'noteCreation' || status === 'noteUpdates';

    const buttons = (
      <>
        <Button
          dense
          type='action'
          view='outlined'
          color='default'
          text={cancelButtonText}
          onClick={cancelButtonCallback}
          className='mb-4 sm:mb-0 sm:mr-6'
        />
        <Button
          dense
          type='action'
          view='filled'
          color='success'
          loading={isLoading}
          text={successButtonText}
          onClick={successButtonCallback}
        />
      </>
    );

    return (
      <div className={className}>
        {width < 641 ? (
          <Drawer size='100%' position='right' isOpen={isOpen} style={{ borderRadius: 0 }}>
            <div className='flex flex-col grow pt-4 px-1'>
              <div className='flex items-center justify-between mb-4'>
                <span className='font-bold text-myrtle text-[1.25rem] leading-8 tracking-[0.0075em]'>
                  {title}
                </span>
                <button
                  aria-label='close'
                  onClick={cancelButtonCallback}
                  className='bg-none border-none flex items-start self-start justify-end h-9 w-9'>
                  <Icon icon='cross' size={16} color='#4A4B57' className='m-[0.3125rem]' />
                </button>
              </div>
              <div className='flex flex-col grow justify-between pb-4'>
                <span className='font-normal text-myrtle leading-6 tracking-[0.0275em]'>
                  {description}
                </span>
                <div className='flex flex-col'>{buttons}</div>
              </div>
            </div>
          </Drawer>
        ) : (
          <Dialog
            type='default'
            view='raised'
            isOpen={isOpen}
            title={title}
            backdropOpacity={40}
            rightButton={buttons}
            onClose={cancelButtonCallback}
            text={
              <div className='relative w-full'>
                <span className='font-normal text-myrtle leading-6 tracking-[0.0275em]'>
                  {description}
                </span>
              </div>
            }
            className='max-w-[35rem] w-full'
          />
        )}
      </div>
    );
  },
);

NoteSuccessModal.displayName = 'NoteExitWithOutSaveModal';
