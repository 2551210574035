import React, { ReactNode, Ref, PropsWithChildren } from 'react';
import ReactDOM from 'react-dom';
import { Transforms, Element as SlateElement } from 'slate';
import { ReactEditor, useReadOnly, useSlateStatic } from 'slate-react';
import looksOneIcon from '@img/notes/richTextEditor/looks_one.svg';
import looksTwoIcon from '@img/notes/richTextEditor/looks_two.svg';
import formatBoldIcon from '@img/notes/richTextEditor/format_bold.svg';
import formatItalicIcon from '@img/notes/richTextEditor/format_italic.svg';
import formatUnderlinedIcon from '@img/notes/richTextEditor/format_underlined.svg';
import codeIcon from '@img/notes/richTextEditor/code.svg';
import formatQuoteIcon from '@img/notes/richTextEditor/format_quote.svg';
import formatListNumberedIcon from '@img/notes/richTextEditor/format_list_numbered.svg';
import formatListBulletedIcon from '@img/notes/richTextEditor/format_list_bulleted.svg';
import checkListIcon from '@img/notes/richTextEditor/checklist.svg';
import formatAlignLeftIcon from '@img/notes/richTextEditor/format_align_left.svg';
import formatAlignCenterIcon from '@img/notes/richTextEditor/format_align_center.svg';
import formatAlignRightIcon from '@img/notes/richTextEditor/format_align_right.svg';
import formatAlignJustifyIcon from '@img/notes/richTextEditor/format_align_justify.svg';

interface IBaseProps {
  className: string;
  [key: string]: unknown;
}
type OrNull<T> = T | null;

const buttonsIcons: Record<string, string> = {
  looks_one: looksOneIcon,
  looks_two: looksTwoIcon,
  format_bold: formatBoldIcon,
  format_italic: formatItalicIcon,
  format_underlined: formatUnderlinedIcon,
  code: codeIcon,
  format_quote: formatQuoteIcon,
  format_list_numbered: formatListNumberedIcon,
  format_list_bulleted: formatListBulletedIcon,
  checklist: checkListIcon,
  format_align_left: formatAlignLeftIcon,
  format_align_center: formatAlignCenterIcon,
  format_align_right: formatAlignRightIcon,
  format_align_justify: formatAlignJustifyIcon,
};

export const Button = React.forwardRef(
  (
    {
      className,
      active,
      reversed,
      ...props
    }: PropsWithChildren<
      {
        active: boolean;
        reversed: boolean;
      } & IBaseProps
    >,
    ref: Ref<OrNull<HTMLSpanElement>>,
  ) => (
    <span
      {...props}
      // eslint-disable-next-line
      // @ts-ignore
      ref={ref}
      className={`cursor-pointer text-${
        reversed ? (active ? 'white' : 'gray3') : active ? 'black' : 'gray3'
      } ${className}`}
    />
  ),
);

export const EditorValue = React.forwardRef(
  (
    {
      className,
      value,
      ...props
    }: PropsWithChildren<
      {
        // eslint-disable-next-line
        value: any;
      } & IBaseProps
    >,
    ref: Ref<OrNull<null>>,
  ) => {
    const textLines = value?.document?.nodes
      // eslint-disable-next-line
      // @ts-ignore
      .map(node => node.text)
      .toArray()
      .join('\n');
    return (
      <div
        // eslint-disable-next-line
        // @ts-ignore
        ref={ref}
        {...props}
        className={`mt-[1.875rem] mb-[1.25rem] ${className}`}>
        <div className='bg-[#f8f8f8] border-0.5 border-[#eeeeee] text-tooltips text-[#404040] pt-[0.3125] px-[1.25rem]'>
          Slate&apos;s value as text
        </div>
        <div className='text-[#404040] text-small_text py-2.5 px-5'>{textLines}</div>
      </div>
    );
  },
);

export const Icon = React.forwardRef(
  (
    { className, title, active, ...props }: PropsWithChildren<IBaseProps>,
    ref: Ref<OrNull<HTMLSpanElement>>,
  ) => {
    return (
      <img
        // eslint-disable-next-line
        // @ts-ignore
        ref={ref}
        alt={String(title)}
        src={buttonsIcons[String(props.children)]}
        className={`text-[1.125rem] align-bottom text-inherit mr-2 ${active ? '' : 'opacity-40'} ${
          className || ''
        }`}
      />
    );
  },
);

export const Instruction = React.forwardRef(
  ({ className, ...props }: PropsWithChildren<IBaseProps>, ref: Ref<OrNull<HTMLDivElement>>) => (
    <div
      {...props}
      // eslint-disable-next-line
      // @ts-ignore
      ref={ref}
      className={`bg-[#f8f8e8] text-tooltips whitespace-pre-wrap mb-5 mx-2.5 ${className}`}
    />
  ),
);

export const Menu = React.forwardRef(
  ({ className, ...props }: PropsWithChildren<IBaseProps>, ref: Ref<OrNull<HTMLDivElement>>) => (
    <div
      {...props}
      data-test-id='menu'
      // eslint-disable-next-line
      // @ts-ignore
      ref={ref}
      className={className}
    />
  ),
);

export const Portal = ({ children }: { children?: ReactNode }) => {
  return typeof document === 'object' ? ReactDOM.createPortal(children, document.body) : null;
};

export const Toolbar = React.forwardRef(
  ({ className, ...props }: PropsWithChildren<IBaseProps>, ref: Ref<OrNull<HTMLDivElement>>) => (
    <Menu
      {...props}
      // eslint-disable-next-line
      // @ts-ignore
      ref={ref}
      className={`relative border border-gray2 rounded-lg flex items-center px-4 py-2 ${className}`}
    />
  ),
);

// eslint-disable-next-line
// @ts-ignore
export const CheckListItemElement = ({ attributes, children, element }) => {
  const editor = useSlateStatic();
  const readOnly = useReadOnly();
  const { checked } = element;
  return (
    <div {...attributes} className='flex items-center'>
      <span contentEditable={false} className='mr-3'>
        <input
          type='checkbox'
          checked={checked}
          disabled={readOnly}
          onChange={event => {
            // eslint-disable-next-line
            // @ts-ignore
            const path = ReactEditor.findPath(editor, element);
            // eslint-disable-next-line
            // @ts-ignore
            const newProperties: Partial<SlateElement> = { checked: event.target.checked };
            Transforms.setNodes(editor, newProperties, { at: path });
          }}
          className='cursor-pointer'
        />
      </span>
      <span
        contentEditable={!readOnly}
        suppressContentEditableWarning
        className={`focus:outline-none ${checked ? 'opacity-70 line-through' : ''}`}>
        {children}
      </span>
    </div>
  );
};
