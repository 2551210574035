import React from 'react';
//
import { PayCard } from '@components/payCard';
import { getPayPageState } from '@redux/selectors';
import { setSelectedCardNumber } from '@redux/Pay/slice';
import { useAppDispatch, useAppSelector } from '@hooks/index';

type TPayCards = {
  /**
   * Массив цен по месяцам
   * @param {number[]}
   */
  amount: number[];
  /**
   * Массив с количеством месяцев для оплаты
   * @param {string}
   */
  numberOfMonth: string[];
  /**
   * Массив с количеством недель при оплате
   * @param {string[]}
   */
  numberOfGiftWeek: string[];
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const PayCards = ({
  amount,
  numberOfMonth,
  numberOfGiftWeek,
  className = '',
}: TPayCards) => {
  const {
    selectedCardNumber,
    data: { amount: basePrice },
  } = useAppSelector(getPayPageState);
  const dispatch = useAppDispatch();

  const setActiveCardHandler =
    ({
      totalAmount,
      cardNumber,
      monthAmount,
    }: {
      totalAmount: number;
      cardNumber: number;
      monthAmount: number;
    }) =>
    () =>
      dispatch(setSelectedCardNumber({ totalAmount, cardNumber, monthAmount }));

  const cardsToRender = numberOfMonth.map((item, index) => {
    const plusOneIndex = index + 1;
    const isActive = index === selectedCardNumber;
    const totalAmount = Number(basePrice) * Number(numberOfMonth[index]);
    return (
      <PayCard
        key={numberOfMonth[index]}
        months={item}
        oneMonthCost={amount[index]}
        basePrice={Number(basePrice)}
        giftWeeks={numberOfGiftWeek[index]}
        setActiveCard={setActiveCardHandler({
          totalAmount,
          cardNumber: index,
          monthAmount: Number(numberOfMonth[index]),
        })}
        className={`${plusOneIndex !== numberOfMonth.length ? 'mr-4 mb-4' : ''} ${
          isActive
            ? 'border border-green2 !bg-aquaSpring px-[0.9375rem] py-[1.4375rem]'
            : 'bg-lightGray px-4 py-6'
        }`}
      />
    );
  });

  return (
    <div className={`flex lg:flex-wrap w-screen lg:w-full overflow-x-auto ${className}`}>
      {cardsToRender}
    </div>
  );
};
