import React, { memo } from 'react';
import { Icon } from '@blueprintjs/core';
//
import { Dialog } from '@uikit/Dialog';
import { Button } from '@uikit/Button';
import { Drawer } from '@uikit/Drawer';
import { useWindowWidth } from '@hooks/useWindowWidth';

type TProps = {
  /**
   * Флаг показа модалки
   * @param {boolean}
   */
  isOpen: boolean;
  /**
   * Закрывает модальное окно
   * @param {() =. void}
   */
  closeModalHandler: () => void;
  /**
   * Callback в кнопку подтверждения действия
   * @param {() => void}
   */
  callbackToConfirmButton: () => void;
  /**
   * Заголовок диалога
   * @param {string}
   */
  dialogTitle: string;
  /**
   * Текст пояснения
   * @param {string}
   */
  dialogDescription: string;
  /**
   * Текст для кнопки отмены операции
   * @param {string}
   */
  cancelButtonText: string;
  /**
   * Текст для кнопки подтверждения операции
   * @param {string}
   */
  confirmButtonText: string;
  /**
   * Флаг отображения лоадера в кнопке
   * @param {boolean}
   */
  showLoaderInButton: boolean;
  /**
   * Флаг отключения кнопки
   * @param {boolean}
   */
  disableButton: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const NoteDeleteModal = memo(
  ({
    isOpen,
    dialogTitle,
    disableButton,
    cancelButtonText,
    confirmButtonText,
    dialogDescription,
    closeModalHandler,
    showLoaderInButton,
    callbackToConfirmButton,
    className = '',
  }: TProps) => {
    const width = useWindowWidth();

    const buttons = (
      <>
        <Button
          dense
          type='action'
          view='outlined'
          color='default'
          text={cancelButtonText}
          onClick={closeModalHandler}
          className='mr-6'
        />
        <Button
          dense
          type='action'
          view='filled'
          color='danger'
          text={confirmButtonText}
          disabled={disableButton}
          loading={showLoaderInButton}
          onClick={callbackToConfirmButton}
        />
      </>
    );

    return (
      <div className={className}>
        {width > 641 ? (
          <Dialog
            type='default'
            view='raised'
            isOpen={isOpen}
            title={dialogTitle}
            backdropOpacity={40}
            rightButton={buttons}
            onClose={closeModalHandler}
            text={<span>{dialogDescription}</span>}
            className='break-all whitespace-pre-line'
          />
        ) : (
          <Drawer size='100%' position='right' isOpen={isOpen} style={{ borderRadius: 0 }}>
            <div className='flex flex-col grow pt-4 px-1'>
              <div className='flex items-center justify-between mb-4'>
                <span className='font-bold text-myrtle text-[1.25rem] leading-8 tracking-[0.0075em] break-words'>
                  {dialogTitle}
                </span>
                <button
                  aria-label='close'
                  onClick={closeModalHandler}
                  className='bg-none border-none flex items-start self-start justify-end h-9 w-9'>
                  <Icon icon='cross' size={16} color='#4A4B57' className='m-[0.3125rem]' />
                </button>
              </div>
              <div className='flex flex-col grow justify-between pb-4'>
                <span className='font-normal text-[#43130F] leading-6 tracking-[0.0275em]'>
                  {dialogDescription}
                </span>
                <div className='flex items-center justify-end'>{buttons}</div>
              </div>
            </div>
          </Drawer>
        )}
      </div>
    );
  },
);

NoteDeleteModal.displayName = 'NodeListDeleteModal';
