import { TStaffListItem, TStaffListResponse } from '@models/Settings';
import { CATEGORIES_EP_NAME, END_POINTS_URL, QUERY_PARAMS } from '@const/apiConstants';
import {
  TTaskCardData,
  TTaskDataToBack,
  TSwitchedTaskData,
  TTaskDataResponse,
  TTasksListRequestData,
  TTaskOperationRequestData,
} from '@models/Tasks';

import { apiService } from '../ApiService';
import { TRequestData, TRequestStatus, TGetResponseWithData } from '../types';

/**
 * API страницы задачи и списка задач
 * @module tasksApi
 */

export const tasksApi = {
  /**
   * Метод делает запрос за списком задач в параметрах запроса передается ключи
   * r_t: boolean; e_t: boolean; c_t: boolean;
   * все ключи false - получает список всех задач
   * {r_t=true, e_t=false, c_t=false} регулярные задачи
   * {e_t=true, r_t=false, c_t=false} клиентские задачи
   * {c_t=true, r_t=false, e_t=false} задачи по событиям
   * @method getTasksListData
   * @param data {TTasksListRequestData} данные для запроса списка задач
   * @return {Promise<AxiosResponse<TGetResponseWithData<TTaskCardData[]> | TRequestStatus, any>>}
   */
  getTasksListData(data: TTasksListRequestData) {
    return apiService.get<TGetResponseWithData<TTaskCardData[]> | TRequestStatus>(
      END_POINTS_URL.TASK_LIST,
      {
        c_t: data.ct,
        e_t: data.et,
        r_t: data.rt,
      },
    );
  },
  /**
   * Метод получает данные одной задачи по ID
   * @method getTaskById
   * @param data {TTaskOperationRequestData} ID задачи
   * @return {Promise<AxiosResponse<ITaskDataResponse | TRequestStatus, any>>}
   */
  getTaskById(data: TTaskOperationRequestData) {
    return apiService.get<TTaskDataResponse | TRequestStatus>(END_POINTS_URL.ONE_TASK, {
      tasks_id: data.id,
    });
  },
  /**
   * Метод удаляет задачу по ID
   * @method deleteTaskById
   * @param data {TTaskOperationRequestData} ID задачи
   * @return {Promise<AxiosResponse<TRequestStatus, any>>}
   */
  deleteTaskById(data: TTaskOperationRequestData) {
    return apiService.post<TRequestStatus>(
      END_POINTS_URL.DELETE_TASK,
      {},
      {
        params: {
          tasks_id: data.id,
        },
      },
    );
  },
  /**
   * Метод переключает активность задачи
   * @method switchTaskById
   * @param data {TTaskOperationRequestData} ID задачи
   * @return {Promise<AxiosResponse<TRequestStatus | TSwitchedTaskData, any>>}
   */
  switchTaskById(data: TTaskOperationRequestData) {
    return apiService.post<TSwitchedTaskData | TRequestStatus>(
      END_POINTS_URL.SWITCH_TASK_ACTIVITY,
      {},
      {
        params: {
          tasks_id: data.id,
        },
      },
    );
  },
  /**
   * Метод сохраняет изменения в существующей задаче или создает новую
   * @method saveReport
   * @param data {TRequestData<ITaskDataResponse>} измененные или новые данные задачи
   * @return {Promise<AxiosResponse<ITaskDataResponse, any>>}
   */
  saveTask(data: TRequestData<TTaskDataToBack> & { options: { accId: string; userId: number } }) {
    return apiService.post<TTaskDataResponse>(END_POINTS_URL.SAVE_TASK, data.data, {
      params: {
        [QUERY_PARAMS.USER_ID]: data.options.userId,
        [QUERY_PARAMS.ACC_ID]: data.options.accId,
      },
    });
  },
  /**
   * Метод запрашивает список категорий для отображения выпадающего списка
   * @method getCategoryList
   * @param data {string}
   * @return Promise<AxiosResponse<TRequestStatus | TGetResponseWithData<TStaffListItem[]>, any>>
   */
  getCategoryList(data: string) {
    return apiService.get<TGetResponseWithData<TStaffListItem[]> | TRequestStatus>(
      CATEGORIES_EP_NAME[data],
    );
  },
  /**
   * Метод запрашивает список сотрудников для отображения выпадающего списка
   * @method getStaffList
   * @return Promise<AxiosResponse<TRequestStatus | TStaffData>, any>>
   */
  getStaffList() {
    return apiService.get<TStaffListResponse | TRequestStatus>(END_POINTS_URL.STAFF);
  },
};
