import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
//
import { CONFIG } from '@const/config';
import { Footer } from '@blocks/footer';
import { SideNav } from '@blocks/sideNav';
import { logout } from '@redux/Auth/slice';
import { Header } from '@components/header';
import { IWithChildren } from '@models/index';
import { PATH_NAMES } from '@const/httpConst';
import { useLocation } from 'react-router-dom';
import { NoAccess } from '@components/noAccess';
import { ErrorMessage } from '@blocks/errorMessage';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { getAccountsData, getAuthData } from '@redux/selectors';

interface IBaseTemplateProps extends IWithChildren {
  /**
   * Петь для корректной работы компонента в storyBook
   * @param {string}
   */
  storyBookPath?: string;
  /**
   * Флаг показывает ошибку доступа
   * @param {boolean}
   */
  isAccessDenied: boolean;
}

export const BaseTemplate = memo(
  ({ children, isAccessDenied, storyBookPath }: IBaseTemplateProps) => {
    const dispatch = useAppDispatch();
    const { isError } = useAppSelector(getAccountsData);
    const path = useLocation().pathname;
    // Состояние меню для мобильных
    const [isOpen, setIsOpen] = useState(() => false);
    const [windowSize, setWindowSize] = useState(() => ({ width: 0 }));

    const pathToCompare = storyBookPath || path;

    // eslint-disable-next-line
    // @ts-ignore
    const isShow404Page = !Object.values(PATH_NAMES).includes(pathToCompare);

    const hideSideNav =
      pathToCompare === PATH_NAMES.ACCOUNTS || pathToCompare === PATH_NAMES.START_PAGE;

    const hideHeaderAndFooter = pathToCompare === PATH_NAMES.START_PAGE;

    const memoizedChildren = useMemo(() => [children], [children]) as React.ReactNode;

    // получаем данные авторизованного пользователя
    const { authUserData } = useAppSelector(getAuthData);

    const memoizedAuthUserData = useMemo(() => authUserData, [authUserData]);

    // Эффект следит за изменением ширины экрана и если ширина больше 640 и открыть меню,
    // закрывает все принудительно
    useEffect(() => {
      if (windowSize.width > 981 && isOpen) {
        setIsOpen(false);
      }
    }, [windowSize, isOpen]);

    // Эффект следит за изменением ширины экрана и записывает полученное значение в windowSize
    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
        });
      }
      // Add event listener
      window.addEventListener('resize', handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener('resize', handleResize);
    }, []); // Empty array ensures that effect is only run on mount

    // Открывает/закрывает мобильное меню
    const onClickHandler = useCallback(() => {
      setIsOpen(prevState => !prevState);
    }, []);

    const logoutHandler = useCallback(() => {
      dispatch(logout());
    }, [dispatch]);

    return (
      <>
        {!hideHeaderAndFooter ? (
          <Header
            isOpen={isOpen}
            isAccountPage={false}
            logoutHandler={logoutHandler}
            onClickHandler={onClickHandler}
            authUserData={memoizedAuthUserData}
            supportTelegramLink={CONFIG.SUPPORT_TELEGRAM_LINK}
            supportTelegramPhoneToHref={CONFIG.SUPPORT_PHONE_NUMBER_TO_HREF}
            supportTelegramPhoneToShow={CONFIG.SUPPORT_PHONE_NUMBER_TO_SHOW}
            className={isShow404Page ? '' : 'mb-[2.3125rem]'}
          />
        ) : null}
        {isAccessDenied && !isError ? <NoAccess /> : null}
        {!isAccessDenied && !isError ? (
          <div
            className={`flex ${
              !isShow404Page && !hideSideNav
                ? 'lg:grid px-[1rem] lg:px-[1.5rem] lg:grid-cols-[17.5rem_1fr]'
                : 'h-full'
            } lg:gap-1.5 mb-[5rem] sm:mb-[2rem] ${isOpen ? 'hidden' : ''}`}>
            {!isShow404Page && !hideSideNav ? (
              <SideNav onClickHandler={onClickHandler} className='hidden lg:block ' />
            ) : null}
            <main className='w-full'>{memoizedChildren}</main>
          </div>
        ) : null}
        {isError ? <ErrorMessage /> : null}
        {!hideHeaderAndFooter ? (
          <Footer
            offerAgreementLink={CONFIG.OFFER_AGREEMENT}
            privacyPolicyLink={CONFIG.PRIVACY_POLICY}
            supportTelegramLink={CONFIG.SUPPORT_TELEGRAM_LINK}
            linkToKnowledgeBase={CONFIG.LINK_TO_KNOWLEDGE_BASE}
            supportPhoneToHref={CONFIG.SUPPORT_PHONE_NUMBER_TO_HREF}
            supportPhoneToShow={CONFIG.SUPPORT_PHONE_NUMBER_TO_SHOW}
            className={isOpen ? 'hidden' : ''}
          />
        ) : null}
      </>
    );
  },
);
