import { createSlice } from '@reduxjs/toolkit';
import { initialState } from '@redux/note/state';
import {
  updateNoteById,
  createNoteThunk,
  getNoteByIdThunk,
  createNewTagThunk,
  deleteNoteByIdThunk,
  attachNoteToTaskThunk,
  moveNoteToFolderThunk,
  deleteNoteFromTaskThunk,
} from '@redux/note/thunk';
import {
  reducers,
  createNoteReducer,
  getNoteByIdReducer,
  createNewTagReducer,
  deleteNoteByIdReducer,
  updateNoteByIdReducer,
  attachNoteToTaskReducer,
  moveNoteToFolderReducer,
  deleteNoteFromTaskReducer,
} from '@redux/note/reducers';

export const noteSlice = createSlice({
  name: 'note',
  initialState,
  reducers,
  extraReducers: builder => {
    builder.addCase(createNoteThunk.pending, createNoteReducer.pending);
    builder.addCase(createNoteThunk.fulfilled, createNoteReducer.fulfilled);
    builder.addCase(createNoteThunk.rejected, createNoteReducer.rejected);
    builder.addCase(getNoteByIdThunk.pending, getNoteByIdReducer.pending);
    builder.addCase(getNoteByIdThunk.fulfilled, getNoteByIdReducer.fulfilled);
    builder.addCase(getNoteByIdThunk.rejected, getNoteByIdReducer.rejected);
    builder.addCase(updateNoteById.pending, updateNoteByIdReducer.pending);
    builder.addCase(updateNoteById.fulfilled, updateNoteByIdReducer.fulfilled);
    builder.addCase(updateNoteById.rejected, updateNoteByIdReducer.rejected);
    builder.addCase(moveNoteToFolderThunk.pending, moveNoteToFolderReducer.pending);
    builder.addCase(moveNoteToFolderThunk.fulfilled, moveNoteToFolderReducer.fulfilled);
    builder.addCase(moveNoteToFolderThunk.rejected, moveNoteToFolderReducer.rejected);
    builder.addCase(createNewTagThunk.pending, createNewTagReducer.pending);
    builder.addCase(createNewTagThunk.fulfilled, createNewTagReducer.fulfilled);
    builder.addCase(createNewTagThunk.rejected, createNewTagReducer.rejected);
    builder.addCase(deleteNoteByIdThunk.pending, deleteNoteByIdReducer.pending);
    builder.addCase(deleteNoteByIdThunk.fulfilled, deleteNoteByIdReducer.fulfilled);
    builder.addCase(deleteNoteByIdThunk.rejected, deleteNoteByIdReducer.rejected);
    builder.addCase(attachNoteToTaskThunk.pending, attachNoteToTaskReducer.pending);
    builder.addCase(attachNoteToTaskThunk.fulfilled, attachNoteToTaskReducer.fulfilled);
    builder.addCase(attachNoteToTaskThunk.rejected, attachNoteToTaskReducer.rejected);
    builder.addCase(deleteNoteFromTaskThunk.pending, deleteNoteFromTaskReducer.pending);
    builder.addCase(deleteNoteFromTaskThunk.fulfilled, deleteNoteFromTaskReducer.fulfilled);
    builder.addCase(deleteNoteFromTaskThunk.rejected, deleteNoteFromTaskReducer.rejected);
  },
});

export const noteReducer = noteSlice.reducer;

export const {
  addTag,
  setTitle,
  deleteTag,
  setNewTag,
  setEditMode,
  setNoteData,
  setVisibility,
  setDescription,
  clearErrorMessageN,
  addTaskToAttachList,
  clearSuccessMessageN,
  setActiveFolderInNote,
  showNotUniqueTitleError,
  setTaskListToAttachNote,
  setIsShowNoSaveExitModal,
} = noteSlice.actions;
