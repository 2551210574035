import React, { memo, useCallback, useEffect } from 'react';
import { Icon } from '@blueprintjs/core';
//
import { Button } from '@uikit/Button';
import { Dialog } from '@uikit/Dialog';
import { Drawer } from '@uikit/Drawer';
import { EVENT_KEY_NAMES } from '@models/index';
import { useWindowWidth } from '@hooks/useWindowWidth';
import { TextInput } from '@uikit/Inputs/DefaultInput';
import { TNoteListStatuses } from '@redux/noteList/zod';
import { TKeyboardClickEvent, TOnChangeHandler } from '@shared/types';
import { ReactComponent as FolderIcon } from '@img/notes/folder_close.svg';

type TProps = {
  /**
   * Статус запросов на страницы
   * @param {TNoteListStatuses}
   */
  status: TNoteListStatuses;
  /**
   * Флаг показа модалки
   * @param {boolean}
   */
  isOpen: boolean;
  /**
   * Значение инпута в модалку создания новой папки
   * @param {string}
   */
  newFolderName: string;
  /**
   * Обрабатывает ввод значения в инпуте
   * @param {(folderId: string) => void}
   */
  setNewFolderName: (folderId: string) => void;
  /**
   * Закрывает модальное окно
   * @param {() =. void}
   */
  closeModalHandler: () => void;
  /**
   * Создает новую папку
   * @param {(folderName: string) => void}
   */
  createNewFolder: (folderName: string) => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const NoteCreateFolderModal = memo(
  ({
    isOpen,
    status,
    newFolderName,
    createNewFolder,
    setNewFolderName,
    closeModalHandler,
    className = '',
  }: TProps) => {
    const width = useWindowWidth();

    useEffect(() => {
      if (!isOpen) {
        setNewFolderName('');
      }
    }, [isOpen, setNewFolderName]);

    const setNewFolderNameHandler: TOnChangeHandler = useCallback(
      event => {
        const { value } = event.currentTarget;

        setNewFolderName(value);
      },
      [setNewFolderName],
    );

    const createNewFolderByEnterKey: TKeyboardClickEvent = useCallback(
      event => {
        if (event.key === EVENT_KEY_NAMES.ENTER) {
          createNewFolder(newFolderName);
        }
      },
      [newFolderName, createNewFolder],
    );

    const createFolderNameHandler = useCallback(
      (folderName: string) => () => {
        createNewFolder(folderName);
      },
      [createNewFolder],
    );

    const clearFolderName = useCallback(() => {
      setNewFolderName('');
    }, [setNewFolderName]);

    const textInput = (
      <TextInput
        fill
        type='text'
        clearButton
        view='outlined'
        color='default'
        value={newFolderName}
        onChange={setNewFolderNameHandler}
        clearSearchString={clearFolderName}
        onKeyUp={createNewFolderByEnterKey}
        placeholder='Введите имя новой папки'
        leftElement={<FolderIcon fill='#5A5B6A' className='px-3 h-10 w-[2.76rem]' />}
      />
    );

    const buttons = (
      <>
        <Button
          dense
          type='action'
          view='outlined'
          color='default'
          text='Отмена'
          onClick={closeModalHandler}
          className='mr-6'
        />
        <Button
          dense
          type='action'
          view='filled'
          color='success'
          text='Создать'
          loading={status === 'folderCreate'}
          disabled={!newFolderName || status === 'error'}
          onClick={createFolderNameHandler(newFolderName)}
        />
      </>
    );

    return (
      <div className={className}>
        {width < 641 ? (
          <Drawer size='100%' position='right' isOpen={isOpen} style={{ borderRadius: 0 }}>
            <div className='flex flex-col grow p-1'>
              <div className='flex items-center justify-between mb-4'>
                <span className='font-bold text-myrtle text-[1.25rem] leading-8 tracking-[0.0075em]'>
                  Создать папку
                </span>
                <button
                  aria-label='close'
                  onClick={closeModalHandler}
                  className='bg-none border-none flex items-start justify-end h-9 w-9'>
                  <Icon icon='cross' size={16} color='#4A4B57' className='m-[0.3125rem]' />
                </button>
              </div>
              <div className='flex flex-col grow justify-between pb-4'>
                {textInput}
                <div className='flex items-center justify-end'>{buttons}</div>
              </div>
            </div>
          </Drawer>
        ) : (
          <Dialog
            type='default'
            view='raised'
            isOpen={isOpen}
            title='Создать папку'
            backdropOpacity={40}
            rightButton={buttons}
            onClose={closeModalHandler}
            text={<div className='relative w-full'>{textInput}</div>}
          />
        )}
      </div>
    );
  },
);

NoteCreateFolderModal.displayName = 'NoteCreateFolderModal';
