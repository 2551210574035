import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Icon } from '@blueprintjs/core';
//
import { Button } from '@uikit/Button';
import { useAppDispatch } from '@hooks/index';
import { INPUT_VARIANTS } from '@models/index';
import { AutoComponentWithLabel } from '@components/autoComponentWithLabel';
import { setBooleanInputValue, setTextInputValue } from '@redux/Settings/slice';
import { TSettingsTelephonyData, TTelephonyFieldsListItem } from '@models/Settings';

type TSettingsOperatorDataProps = {
  /**
   * Ссылка на инструкцию по подключению телефонии
   * @param {string}
   */
  manualInstructionHref: string;
  /**
   * Флаг ошибки проверки возможность подключения телефонии
   * @param {boolean}
   */
  isCheckConnectionError: boolean;
  /**
   * Проверяет возможность подключения телефонии с указанными данными
   * @param {() => void}
   */
  checkConnection: () => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
  /**
   * Список поле ввода для данного оператора
   * @param {TTelephonyFieldsListItem[]}
   */
  fields: TTelephonyFieldsListItem[];
  /**
   * Данные телефонии
   * @param {TSettingsTelephonyData}
   */
  data: TSettingsTelephonyData;
};

export const SettingsOperatorData = ({
  data,
  fields,
  className = '',
  checkConnection,
  manualInstructionHref,
  isCheckConnectionError,
}: TSettingsOperatorDataProps) => {
  const dispatch = useAppDispatch();
  const [isCheckConnectionDisabled, setIsCheckConnectionDisabled] = useState<boolean>(true);

  useEffect(() => {
    const allRequiredFieldsFilled = fields
      .filter(field => field.required)
      .every(field => !!data[field.name]);

    setIsCheckConnectionDisabled(!allRequiredFieldsFilled);
  }, [data, fields]);

  const setTextInputHandler = useCallback(
    ({ name }: { name: string }) =>
      ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(
          setTextInputValue({
            field: name,
            value,
          }),
        );
      },
    [dispatch],
  );

  const setCheckboxHandler = useCallback(
    ({ name }: { name: string }) =>
      ({ target: { checked: value } }: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(
          setBooleanInputValue({
            field: name,
            value,
          }),
        );
      },
    [dispatch],
  );

  const clearTextInputHandler = useCallback(
    ({ name }: { name: string }) =>
      () => {
        dispatch(
          setTextInputValue({
            field: name,
            value: '',
          }),
        );
      },
    [dispatch],
  );

  const renderFields = useMemo(() => {
    if (!fields?.length) {
      return null;
    }

    return fields?.map(({ name, title, type, required }) => {
      let value = '';
      let checked = false;

      switch (type) {
        case INPUT_VARIANTS.TEXT:
          value = data[name] as string;
          break;
        case INPUT_VARIANTS.CHECKBOX:
          checked = !!data[name];
          break;
        case INPUT_VARIANTS.NUMBER:
          value = String(data[name] as number);
          break;
        default:
          break;
      }

      return (
        <AutoComponentWithLabel
          key={name}
          name={name}
          type={type}
          label={title}
          value={value}
          checked={checked}
          onChangeTextInput={setTextInputHandler({ name })}
          onChangeCheckbox={setCheckboxHandler({ name })}
          required={required}
          clearInput={clearTextInputHandler({ name })}
          className='mb-6 w-full'
        />
      );
    });
  }, [fields, data, setTextInputHandler, setCheckboxHandler, clearTextInputHandler]);

  return (
    <div className={`flex flex-col w-full ${className}`}>
      {renderFields}
      <Button
        dense
        type='action'
        color='default'
        view='outlined'
        onClick={checkConnection}
        text='Проверить подключение'
        disabled={isCheckConnectionDisabled}
        className='mb-4 w-[14.875rem]'
      />
      {isCheckConnectionError ? (
        <div className='flex flex-wrap items-center mb-[1.19rem]'>
          <p className='text-black leading-6 tracking-[0.0275em] mr-2 mb-0'>
            <Icon icon='delete' color='#F6655A' size={20} className='mr-3' />
            Интеграция с телефонией не настроена. Для подключения воспользуйтесь
          </p>
          <a
            target='_blank'
            rel='noreferrer noopener'
            href={manualInstructionHref}
            className='no-underline text-statusGreen leading-6 tracking-[0.0275em]'>
            инструкцией&nbsp;
            <Icon icon='share' color='#65B168' size={20} />
          </a>
        </div>
      ) : (
        <div className='mb-[2.69rem]' />
      )}
    </div>
  );
};
