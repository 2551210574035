import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
//
import isEqual from '@helpers/isEqual';
import { TNote } from '@redux/note/zod';
import { createPortal } from 'react-dom';
import { Overlay } from '@atoms/overlay';
import { NotePath } from '@blocks/notePath';
import { newNote } from '@redux/note/mockData';
import { clickOnElement } from '@helpers/index';
import { NoAccess } from '@components/noAccess';
import { getTasksList } from '@redux/Task/thunks';
import { QUERY_PARAMS } from '@const/apiConstants';
import { ErrorMessage } from '@blocks/errorMessage';
import { NoteTemplate } from '@templates/noteTemplate';
import { COMMON_FOLDER } from '@redux/noteList/mockData';
import { ToastsMessages } from '@components/toastsMessages';
import { getNoteListPageData } from '@redux/noteList/thunk';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { NoteSuccessModal } from '@components/noteSuccessModal';
import { MAX_DESCRIPTION_LENGTH, MAX_TITLE_LENGTH, NEW_NOTE_ID } from '@const/notes';
import { createNoteThunk, getNoteByIdThunk, updateNoteById } from '@redux/note/thunk';
import { getAccountsData, getNoteData, getNoteListData, getTaskPageData } from '@redux/selectors';
import {
  setEditMode,
  setNoteData,
  clearErrorMessageN,
  clearSuccessMessageN,
  showNotUniqueTitleError,
  setIsShowNoSaveExitModal,
} from '@redux/note/slice';

type TProps = {
  /**
   * Флаг создания новой заметки
   * @param {boolean}
   */
  isNewNote: boolean;
};

const Note = memo(({ isNewNote }: TProps) => {
  const ref = useRef<(EventTarget & Element) | undefined>(undefined);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const id = searchParams.get(QUERY_PARAMS.ID);
  const [clickCoordinates, setClickCoordinates] = useState({
    clientX: 0,
    clientY: 0,
  });

  const { taskInfoData } = useAppSelector(getTaskPageData);
  const { selectedFilial } = useAppSelector(getAccountsData);
  const { activeFolder, folderList, notesList, tags } = useAppSelector(getNoteListData);
  const {
    data,
    status,
    newTag,
    isError,
    oldData,
    isLoading,
    isNoteEdit,
    errorMessage,
    successMessage,
    isAccessDenied,
    attachedTemplates,
    taskListToAttachNote,
    isShowNoSaveExitModal,
  } = useAppSelector(getNoteData);

  useEffect(() => {
    if (selectedFilial?.accId) {
      dispatch(getNoteListPageData());
      dispatch(getTasksList({ ct: false, et: false, rt: false }));
    }
    return () => {
      dispatch(setNoteData(newNote));
    };
  }, [selectedFilial?.accId, dispatch]);

  useEffect(() => {
    if (selectedFilial?.accId && isNewNote && data.id === NEW_NOTE_ID) {
      dispatch(setEditMode(true));
    }
  }, [isNewNote, data.id, selectedFilial?.accId, dispatch]);

  useEffect(() => {
    if (selectedFilial?.accId && id) {
      dispatch(getNoteByIdThunk(id));
    }
  }, [id, selectedFilial?.accId, dispatch]);

  const clearAllSuccessStatuses = useCallback(() => {
    dispatch(clearSuccessMessageN());
  }, [dispatch]);

  const clearAllErrorMessages = useCallback(() => {
    dispatch(clearErrorMessageN());
  }, [dispatch]);

  const hideExitWithOutSaveModal = useCallback(
    (isOnSuccess: boolean) => () => {
      if (data.id !== NEW_NOTE_ID && !isOnSuccess) {
        dispatch(setEditMode(false));
      }

      dispatch(setNoteData(oldData));

      dispatch(setIsShowNoSaveExitModal(false));

      setTimeout(() => {
        clickOnElement({
          x: clickCoordinates.clientX,
          y: clickCoordinates.clientY,
          overlayElement: ref.current,
        });
      }, 50);
    },
    [clickCoordinates.clientX, clickCoordinates.clientY, oldData, data, dispatch],
  );

  const isNoteNotChanged = isEqual(data, oldData);

  const saveNote = useCallback(
    (noteData: TNote) => () => {
      const isFindNoteByTitle = notesList.find(note => note.header === noteData.title);

      if (isNoteNotChanged) {
        dispatch(setEditMode(false));
        return;
      }

      if (isFindNoteByTitle && noteData.id === NEW_NOTE_ID) {
        dispatch(showNotUniqueTitleError());
        return;
      }
      if (noteData.id === NEW_NOTE_ID) {
        dispatch(
          createNoteThunk({
            noteData,
            activeFolder,
            modalCloseCallback: hideExitWithOutSaveModal(true),
          }),
        );
      } else {
        dispatch(
          updateNoteById({
            noteData,
            activeFolder,
            modalCloseCallback: hideExitWithOutSaveModal(true),
          }),
        );
      }
    },
    [activeFolder, hideExitWithOutSaveModal, notesList, isNoteNotChanged, dispatch],
  );

  const isSaveButtonDisabled =
    !data.title ||
    !data.description ||
    data.title.length > MAX_TITLE_LENGTH ||
    data.description.length > MAX_DESCRIPTION_LENGTH;

  const isShowOverlay = isNoteEdit && !isNoteNotChanged && !isSaveButtonDisabled;

  const closeElementCallback = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent> | undefined) => {
      if (isShowOverlay && event) {
        setClickCoordinates({
          clientX: event.clientX,
          clientY: event.clientY,
        });

        ref.current = event.currentTarget;

        dispatch(setIsShowNoSaveExitModal(true));
      }
    },
    [isShowOverlay, dispatch],
  );

  return (
    <>
      {!isError && isAccessDenied ? <NoAccess className='h-[50vh]' /> : null}
      {isError && !isAccessDenied ? <ErrorMessage className='h-[50vh]' /> : null}
      {isShowOverlay && !isError && !isAccessDenied
        ? createPortal(
            <Overlay onClickCallback={closeElementCallback} className='!z-[1]' />,
            document.body,
          )
        : null}
      {!isError && !isAccessDenied ? (
        <div className='flex flex-col'>
          <NotePath
            noteTitle={data.title}
            activeFolderName={data.folder?.title || COMMON_FOLDER.title}
            className='mb-[0.875rem]'
          />
          <NoteTemplate
            data={data}
            tags={tags}
            newTag={newTag}
            status={status}
            isLoading={isLoading}
            isNewNote={isNewNote}
            folderList={folderList}
            isNoteEdit={isNoteEdit}
            taskInfo={taskInfoData}
            activeFolder={activeFolder}
            saveNoteHandler={saveNote(data)}
            attachedTemplates={attachedTemplates}
            taskListToAttachNote={taskListToAttachNote}
            isSaveButtonDisabled={isSaveButtonDisabled}
            selectedFilialAccId={selectedFilial?.accId || ''}
            className='relative z-[2]'
          />
          {isShowNoSaveExitModal ? (
            <NoteSuccessModal
              status={status}
              isOpen={isShowNoSaveExitModal}
              successButtonText='Сохранить заметку'
              cancelButtonText='Выйти без сохранения'
              title='Сохраните заметку перед выходом'
              description='Вы пытаетесь выйти без сохранения заметки.'
              successButtonCallback={saveNote(data)}
              cancelButtonCallback={hideExitWithOutSaveModal(false)}
            />
          ) : null}
          <ToastsMessages
            errorMessage={errorMessage}
            successMessage={successMessage}
            isShowErrorToast={!!errorMessage}
            isShowSuccessToast={!!successMessage}
            clearErrorCallback={clearAllErrorMessages}
            clearSuccessStatusCallback={clearAllSuccessStatuses}
          />
        </div>
      ) : null}
    </>
  );
});

Note.displayName = 'Note';

export default Note;
