import React from 'react';
import { Icon } from '@blueprintjs/core';
//
import { Button } from '@uikit/Button';
import { numWord } from '@helpers/index';
import { Callout } from '@uikit/Callout';
import { useAppSelector } from '@hooks/index';
import { getAppData } from '@redux/selectors';
import { churnByMasterNumWords } from '@const/settings';
import { PeriodOfReturnStatisticsExample } from '@components/periodOfReturnStatisticsExample';

type TPeriodOfReturnStatisticsInfoProps = {
  /**
   * Текущий период
   * @param {number}
   */
  endRate: number;
  /**
   * Прошлый период
   * @param {number}
   */
  startRate: number;
  /**
   * Тогл для закрытия drawer
   * @param {() => void}
   */
  toggleInfoDrawer: () => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const PeriodOfReturnStatisticsInfo = ({
  startRate,
  endRate,
  toggleInfoDrawer,
  className = '',
}: TPeriodOfReturnStatisticsInfoProps) => {
  const { appSelectedLanguage } = useAppSelector(getAppData);

  const startRateText = `${startRate} ${numWord(
    startRate,
    churnByMasterNumWords[appSelectedLanguage] || 'text not found',
  )}`;

  const endRateText = `${endRate} ${numWord(
    endRate,
    churnByMasterNumWords[appSelectedLanguage] || 'text not found',
  )}`;

  return (
    <div
      className={`flex flex-col p-6 whitespace-pre-line overflow-auto dropdown-list-scrollbar ${className}`}>
      <p className='text-black tracking-[0.0275em] leading-6 mb-6'>
        Вы можете смотреть <span className='text-green1'>отчет о возвращаемости</span> в разрезе по
        мастерам, по новым клиентам, по общей возвращаемости в салон.
      </p>
      <p className='font-bold leading-[1.875rem] tracking-[0.0075em]'>
        По общей возвращаемости в салон
      </p>
      <p className='text-black tracking-[0.0275em] leading-6 mb-0.5'>
        Для расчёта берётся предыдущий период, который можно настроить в зависимости от специфики
        вашего салона.
      </p>
      <p className='text-black tracking-[0.0275em] leading-6 mb-0.5'>
        Указанные в <span className='text-green1'>настройках</span> периоды для расчета
        возвращаемости:
      </p>
      <ul className='list-disc pl-6 mb-2'>
        <li>
          Заданный прошлый период (период потери) —{' '}
          <span className='text-green1'>{startRateText}</span>
        </li>
        <li>
          Текущий период — <span className='text-green1'>{endRateText}</span>
        </li>
      </ul>
      <div className='flex justify-end'>
        <Button
          dense
          type='action'
          view='outlined'
          color='default'
          text='Изменить настройки'
          onClick={toggleInfoDrawer}
        />
      </div>
      <PeriodOfReturnStatisticsExample
        isBlockInDrawer
        endRate={endRate}
        startRate={startRate}
        className='mt-6 mb-[3.5rem]'
      />
      <div className='mb-6'>
        <p className='font-medium text-blackText leading-6 tracking-[0.022em]'>Формула расчета:</p>
        <p className='text-darkGray leading-6 tracking-[0.0275em]'>
          (Количество повторившихся клиентов в текущем и прошлом периодах / Количество клиентов за
          прошлый период ) * 100
        </p>
      </div>
      <div className='mb-6'>
        <p className='font-medium text-blackText leading-6 tracking-[0.022em]'>
          Пример расчета возвращаемости:
        </p>
        <p className='text-darkGray leading-6 tracking-[0.0275em]'>
          {`Клиент выбрал прошлый период 2 месяца, а текущий период — 1 месяц. \n В прошлом периоде пришло 12 клиентов, а в текущем периоде 3 из них пришли снова. Возвращаемость на выбранную дату будет рассчитана по формуле (3/12)*100=25%`}
        </p>
      </div>
      <Callout
        type='def'
        view='smooth'
        color='primary'
        title={null}
        icon={<Icon icon='info-sign' iconSize={20} />}
        className='mb-6'>
        <ul className='list-disc pl-6 mb-2'>
          <li className='text-blackText'>Общий период расчета может быть от 3 до 12 месяцев.</li>
          <li className='text-blackText'>
            Прошлый период равен текущему или больше его (до x2 раз)
          </li>
        </ul>
      </Callout>
      <p className='text-black leading-6 tracking-[0.0275em] mb-6'>
        На странице с показателями клиент может выбрать любой период для отображения показателя. На
        каждый период детализации возвращаемость будет рассчитана за период, предшествующий
        текущему.
      </p>
      <div className='mb-6'>
        <p className='font-medium text-blackText leading-6 tracking-[0.022em]'>Пример:</p>
        <p className='text-darkGray leading-6 tracking-[0.0275em]'>
          Клиент выбрал прошлый период 2 месяца, а текущий период — 1 месяц. Клиент выбирает для
          отображения возвращаемости неделю с 1 по 7 августа с детализацией 1 день. В данном случае
          за 1 августа будет показано значение возвращаемости для расчета которого взят период с 3
          мая по 31 июля (предыдущие 90 дней). За 2 августа будет показано значение, для расчета
          которого взят период с 4 мая по 1 августа (предыдущие 90 дней).
        </p>
      </div>
      <div className='mb-6'>
        <p className='font-bold leading-[1.875rem] tracking-[0.0075em]'>По мастерам</p>
        <p className='text-black tracking-[0.0275em] leading-6'>
          Показывает количество вернувшихся клиентов, которые ранее были у данного сотрудника.
        </p>
      </div>
      <div>
        <p className='font-bold leading-[1.875rem] tracking-[0.0075em]'>По новым клиентам</p>
        <p className='text-black tracking-[0.0275em] leading-6'>
          Для расчёта берутся только клиенты, пришедшие в предыдущем периоде впервые и вернувшиеся в
          текущем периоде.
        </p>
      </div>
    </div>
  );
};
