import React from 'react';
//
import { useAppSelector } from '@hooks/index';
import { NoAccess } from '@components/noAccess';
import { ErrorMessage } from '@blocks/errorMessage';
import { AdminsTable } from '@templates/adminsTable';
import { getAccountsData, getDashboardPageData, getSettingsData } from '@redux/selectors';

const AdminsTablePage = () => {
  const { selectedFilial } = useAppSelector(getAccountsData);
  const selectedFilialAccId = selectedFilial?.accId || '';

  // Получаем и деструктурезируем данные для таблицы
  const {
    isAccessDenied,
    telephonyTableData: data,
    isError: telephonyGetDataError,
  } = useAppSelector(getSettingsData);

  // Получаем и деструктурезируем данные для графика
  const { isError, isLoading, isFetchingMeta } = useAppSelector(getDashboardPageData);

  return (
    <>
      {!isLoading && !isError && !isFetchingMeta && !isAccessDenied && !telephonyGetDataError ? (
        <AdminsTable telephonyTableData={data} selectedFilialAccId={selectedFilialAccId} />
      ) : null}
      {isAccessDenied ? <NoAccess /> : null}
      {isError || telephonyGetDataError ? <ErrorMessage /> : null}
    </>
  );
};

export default AdminsTablePage;
