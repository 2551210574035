import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Menu } from '@uikit/Menu';
import { Chips } from '@uikit/Chips';
import { TInNoteTag } from '@models/Note';
import { useAppDispatch } from '@hooks/index';
import { TNoteTag } from '@redux/noteList/zod';
import { EVENT_KEY_NAMES } from '@const/common';
import { MenuItem } from '@uikit/Menu/MenuItem';
import { MAX_TAG_IN_NOTES } from '@const/notes';
import { TextInput } from '@uikit/Inputs/DefaultInput';
import { addTag, deleteTag, setNewTag } from '@redux/note/slice';
import { TKeyboardClickEvent, TOnChangeHandler } from '@shared/types';
import { useCloseByOutSideClick } from '@hooks/useCloseByOutSideClick';

type TProps = {
  /**
   * Флаг режима редактирования заметки
   * @param {boolean}
   */
  isNoteEdit: boolean;
  /**
   * Значение поля ввода для добавления новых тегов
   * @param {string}
   */
  newTag: string;
  /**
   * Список тэгов добавленных в заметку
   * @param {TInNoteTag[]}
   */
  addedTags: TInNoteTag[];
  /**
   * Список всех тегов на филиале
   * @param {TNoteTag[]}
   */
  tags: TNoteTag[];
  /**
   * Создает новый тэг и добавляет его в заметку
   * @param {(closeModalCallback: () => void) => void}
   */
  createNewTagHandler: (closeModalCallback: () => void) => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const NoteAddTag = memo(
  ({ isNoteEdit, newTag, tags, addedTags, createNewTagHandler, className = '' }: TProps) => {
    const dispatch = useAppDispatch();
    const ref = useRef(null);
    const [filteredTags, setFilteredTags] = useState<TNoteTag[]>(tags);
    const [isShowDropdown, setIsShowDropdown] = useState(false);

    const disableTagAdd = addedTags.length >= MAX_TAG_IN_NOTES;

    const closeElementCallback = useCallback(() => {
      setIsShowDropdown(false);
    }, []);

    useCloseByOutSideClick({ ref, closeElementCallback });

    useEffect(() => {
      setFilteredTags(tags);
    }, [tags]);

    useEffect(() => {
      return () => {
        dispatch(setNewTag(''));
        setIsShowDropdown(false);
      };
    }, [dispatch]);

    useEffect(() => {
      const tagsWithoutAdded = tags.filter(tag => {
        return !addedTags.find(addedTag => addedTag.id === tag.id);
      });

      if (newTag) {
        setFilteredTags(tagsWithoutAdded.filter(tag => tag.title.includes(newTag)));
      } else {
        setFilteredTags(tagsWithoutAdded);
      }
    }, [addedTags, newTag, tags]);

    useEffect(() => {
      if (!isShowDropdown && !filteredTags.length && newTag) {
        setIsShowDropdown(true);
      }
      if (!isShowDropdown && !filteredTags.length && !newTag) {
        setIsShowDropdown(false);
      }
      if (disableTagAdd) {
        setIsShowDropdown(false);
      }
    }, [isShowDropdown, filteredTags.length, disableTagAdd, newTag]);

    const tagDeleteHandler = useCallback(
      (tag: string) => () => {
        if (isNoteEdit) {
          dispatch(deleteTag(tag));
        }
      },
      [isNoteEdit, dispatch],
    );

    const addTagHandler = useCallback(
      (tag: TNoteTag) => () => {
        dispatch(addTag(tag));
        setIsShowDropdown(false);
      },
      [dispatch],
    );

    const showDropdownHandler = useCallback(() => {
      if (isNoteEdit && filteredTags.length && !disableTagAdd) {
        setIsShowDropdown(true);
      }
    }, [isNoteEdit, filteredTags.length, disableTagAdd]);

    const hideDropdownHandler = useCallback(() => {
      setIsShowDropdown(false);
    }, []);

    const setNewTagName: TOnChangeHandler = useCallback(
      event => {
        const { value } = event.currentTarget;
        dispatch(setNewTag(value));
      },
      [dispatch],
    );

    const createNewTagInnerHandler = useCallback(() => {
      createNewTagHandler(hideDropdownHandler);
    }, [createNewTagHandler, hideDropdownHandler]);

    const addTagOnKeyUpHandler: TKeyboardClickEvent = useCallback(
      event => {
        const { key } = event;

        const tagInFormList = tags.find(item => item.title === newTag);

        if (key === EVENT_KEY_NAMES.ENTER && !disableTagAdd) {
          if (tagInFormList) {
            dispatch(addTag(tagInFormList));
          } else if (newTag.length >= MAX_TAG_IN_NOTES) {
            createNewTagInnerHandler();
          }
        }
      },
      [newTag, tags, createNewTagInnerHandler, disableTagAdd, dispatch],
    );

    const itemToRender = filteredTags.map(item => {
      return (
        <MenuItem key={item.id} onClick={addTagHandler(item)} text={<span>{item.title}</span>} />
      );
    });

    const createNewTag = (
      <MenuItem
        key='new'
        onClick={createNewTagInnerHandler}
        text={<span>{`Создать новый тэг ${newTag}`}</span>}
      />
    );

    const menuItemsToRender = itemToRender.length
      ? itemToRender
      : newTag.length > 2
      ? [createNewTag]
      : null;

    const menuToRender = (
      <Menu
        view='raised'
        className={`relative dropdown-list-scrollbar ${
          itemToRender.length < 2 ? `one_item_ul` : ''
        } max-h-[25vh] overflow-y-auto z-[3]`}>
        {itemToRender.length ? itemToRender : newTag.length > 2 ? [createNewTag] : null}
      </Menu>
    );

    const tagsToRender = addedTags.map(({ id, title }) => {
      return (
        <Chips
          round
          noClick
          key={id}
          tag={title}
          type='dense'
          color='success'
          disabled={false}
          withTick={false}
          withNumber={null}
          withAvatar={null}
          removable={isNoteEdit}
          onRemove={tagDeleteHandler(id)}
          className='mr-2 mb-2'
        />
      );
    });

    return (
      <div ref={ref} className={`flex flex-col z-[2] ${className}`}>
        <div className='flex flex-wrap sm:flex-nowrap items-center'>
          <div className={`relative mb-2 w-full 'sm:!max-w-[29rem]'`}>
            <TextInput
              fill
              type='text'
              clearButton
              error={false}
              value={newTag}
              view='outlined'
              color='default'
              success={false}
              onChange={setNewTagName}
              placeholder='Добавьте теги'
              onFocus={showDropdownHandler}
              onKeyUp={addTagOnKeyUpHandler}
              disabled={!isNoteEdit || disableTagAdd}
              clearSearchString={() => dispatch(setNewTag(''))}
              className={newTag ? '' : 'chevron_in_after'}
            />
            {menuItemsToRender?.length ? (
              <div className={`${isShowDropdown ? 'block' : 'hidden'} absolute top-[3rem]`}>
                {menuToRender}
              </div>
            ) : null}
          </div>
        </div>
        <div className='flex items-center flex-wrap w-full'>{tagsToRender}</div>
      </div>
    );
  },
);

NoteAddTag.displayName = 'NoteAddTag';
