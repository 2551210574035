import React from 'react';
//
import { Chips } from '@uikit/Chips';
import { Loader } from '@blocks/loader';
import { Table } from '@uikit/Table/Table';
import { useAppDispatch } from '@hooks/index';
import { LinkButtonLeftIcon } from '@blocks/linkButtonLeftIcon';
import { TTelephonyTableData, TTelephonyTablePageData } from '@models/Settings';
import { setCurrentPage, setPaginationSize, setSortedColumnAndOrder } from '@redux/Settings/slice';

interface ITelephonyTableProps {
  /**
   * Флаг ошибки загрузки данных
   * @param {boolean}
   */
  isError: boolean;
  /**
   * Флаг загрузки
   * @param {boolean}
   */
  isLoading: boolean;
  /**
   * Строка поиска в инпут
   * @param {string}
   */
  phoneSearchString: string;
  /**
   * обрабатывает нажатия на номер телефона в таблице
   * @param {(phone: string) => () => void}
   */
  setSearchByClickOnNumber: (phone: string) => () => void;
  /**
   * Данные для таблицы
   * @param {TTelephonyTablePageData}
   */
  data: TTelephonyTablePageData;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
}

export const TelephonyTable = ({
  data,
  isError,
  isLoading,
  phoneSearchString,
  setSearchByClickOnNumber,
  className = '',
}: ITelephonyTableProps) => {
  const dispatch = useAppDispatch();
  const { data: telephonyTableData, paginator } = data;
  const telephonyTableDataLength = telephonyTableData.length;

  const getCallTypeChips = (type: string) => {
    return (
      <Chips
        round
        noClick
        tag={type}
        type='dense'
        color={
          type === 'Исходящий' ? 'success_alt' : type === 'Входящий' ? 'primary_alt' : 'warning_alt'
        }
      />
    );
  };

  const getCallStatusChips = (status: string) => {
    return (
      <Chips
        round
        noClick
        type='dense'
        color={status === 'Состоялся' ? 'success' : 'danger'}
        tag={status === 'Состоялся' ? 'Успешно' : 'Недозвон'}
      />
    );
  };

  const getStylingDate = (fullDate: string | null) => {
    if (!fullDate) return '-';
    const [date, time] = fullDate.split(' ');
    return (
      <div className='w-fit'>
        <span className='text-grayText'>{date} </span>
        <span> {time.slice(0, 5)}</span>
      </div>
    );
  };

  const getPhoneNumber = (phoneHumber: string) => {
    return (
      <span onClick={setSearchByClickOnNumber(phoneHumber)} className='cursor-pointer'>
        {phoneHumber}
      </span>
    );
  };

  // TODO Изменить поля "Имя" на "Имя клиента", "Создана запись" на "Клиент записан" или "Клиент записался"
  const Head = [
    { id: 'date', label: 'Дата и время' },
    { id: 'name', label: 'Имя' }, // { id: 'name', label: 'Имя клиента' },
    { id: 'phone', label: 'Номер' },
    { id: 'type', label: 'Тип' },
    { id: 'status', label: 'Статус' },
    { id: 'link', label: 'Запись звонка', noSort: true },
    { id: 'record', label: 'Создана запись' }, // { id: 'name', label: 'Клиент записан' }, { id: 'name', label: 'Клиент записался' },
  ];

  const getTableData = (tableData: TTelephonyTableData[]) => {
    const emptyData = [
      {
        date: ' ',
        phone: ' ',
        name: ' ',
        type: ' ',
        status: ' ',
        link: ' ',
        record: ' ',
      },
    ];
    if (!tableData.length) {
      return emptyData;
    }
    return tableData.map(item => {
      return {
        date: getStylingDate(item.date),
        phone: getPhoneNumber(item.phone),
        name: item.name,
        type: getCallTypeChips(item.type),
        status: getCallStatusChips(item.status),
        link: item.link ? <LinkButtonLeftIcon link={item.link} /> : '',
        record: getStylingDate(item.record),
      };
    });
  };

  const setSortTypeHandler = (column: string, order: string) => {
    if (!isLoading) {
      dispatch(setSortedColumnAndOrder({ columnName: column, order }));
    }
  };

  const onPageChangeHandler = (newPageNumber: number) => {
    if (!isLoading) {
      dispatch(setCurrentPage(newPageNumber));
    }
  };

  const onChangeRowsPerPageHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    dispatch(setPaginationSize(+value));
  };

  const fallThrowText = phoneSearchString ? 'Ничего не найдено' : 'Нет данных за выбранный период';

  return (
    <>
      <div className={`${isLoading ? 'cursor-not-allowed' : ''} ${className}`}>
        {telephonyTableDataLength && !isError && !isLoading ? (
          <Table
            name={Head}
            data={getTableData(telephonyTableData)}
            position={['left', 'left', 'left', 'left', 'left', 'left', 'left']}
            type='def'
            view='flat'
            color='default'
            pages
            labelRowsPerPage='Показать строк:'
            rowsPerPageOptions={[5, 8, 25]}
            defRows={paginator.size || 25}
            checkboxes={false}
            paperClassName='no_shadow'
            style={{ boxShadow: 'none', marginLeft: '6px' }}
            currentPage={paginator.currentPage}
            onSortCallback={setSortTypeHandler}
            totalCounter={data.paginator.count}
            onChangePageHandler={onPageChangeHandler}
            onChangeRowsPerPageHandler={onChangeRowsPerPageHandler}
          />
        ) : null}
        {!telephonyTableDataLength && !isError && !isLoading ? (
          <p className='text-center'>{fallThrowText}</p>
        ) : null}
        {isLoading && telephonyTableDataLength ? <Loader className='h-[50Vh]' /> : null}
      </div>
      {isLoading && !telephonyTableDataLength ? <Loader className='h-[50Vh]' /> : null}
      {isLoading ? (
        <div className='absolute cursor-not-allowed top-0 right-0 bottom-0 left-0 z-[100]' />
      ) : null}
    </>
  );
};
