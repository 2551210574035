import React, { useState } from 'react';
import { Icon } from '@blueprintjs/core';
//
import { useCopyToClipboard } from '@hooks/useClipboard';

interface ILinkInTableWithCopyButtonProps {
  /**
   * Текст в компонент
   * @param {string}
   */
  text: string;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
}

export const LinkInTableWithCopyButton = ({
  text,
  className = '',
}: ILinkInTableWithCopyButtonProps) => {
  const [, copy] = useCopyToClipboard();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const copyToClipboard = () => {
    copy(text)
      .then(() => {
        setIsSuccess(true);
        setTimeout(() => setIsSuccess(false), 2000);
      })
      .catch(() => setIsSuccess(false));
  };

  return (
    <div className={`flex items-center ${className}`}>
      <span className='leading-6 tracking-[0.0275em] mr-1'>{text}</span>
      {isSuccess ? (
        <Icon icon='small-tick' color='green' size={16} />
      ) : (
        <Icon
          icon='duplicate'
          size={16}
          onClick={copyToClipboard}
          className='cursor-pointer'
          title='Скопировать ссылку'
        />
      )}
    </div>
  );
};
