import React, { memo, useCallback, useEffect } from 'react';
import { Icon } from '@blueprintjs/core';
//
import { Loader } from '@blocks/loader';
import { TOOLTIPS_TEXT } from '@data/dict';
import { NoAccess } from '@components/noAccess';
import { getSettingsData } from '@redux/selectors';
import { ErrorMessage } from '@blocks/errorMessage';
import { setSortTypeDataSP } from '@redux/Settings/slice';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { getSmartScheduleTableData } from '@redux/Settings/thunk';
import { SettingsStaffTable } from '@components/settingsStaffTable';
import { TOnOpenDrawerHandler, TSortCallback } from '@shared/types';
import { setAppDrawerData, setShowAppDrawer } from '@redux/App/slice';
import { TSettingStaff } from '@models/Settings';

type TSettingsMastersProps = {
  /**
   * Флаг повторного запрашивания данных страницы
   * @param {boolean}
   */
  isRetries: boolean;
  /**
   * Id выбранного филиала
   * @param {string}
   */
  selectedFilialAccId: string;
  /**
   * Флаг ошибки при получении данных
   * @param {boolean}
   */
  isGetTableDateError: boolean;
  /**
   * Флаг успешного получения данных для таблицы
   * @param {boolean}
   */
  isGettingTableDataSuccess: boolean;
  /**
   * Данные для таблицы мастеров
   * @param {TAdminsListItem[]}
   */
  tableData: TSettingStaff[];
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const SettingsMasters = memo(
  ({
    isRetries,
    tableData,
    selectedFilialAccId,
    isGetTableDateError,
    isGettingTableDataSuccess,
    className = '',
  }: TSettingsMastersProps) => {
    const dispatch = useAppDispatch();
    const { isAccessDenied } = useAppSelector(getSettingsData);

    // Запрашивает данные страницы умного расписания и админов повторно при ошибке токена
    useEffect(() => {
      if (isRetries) {
        setTimeout(() => {
          dispatch(getSmartScheduleTableData());
        }, 100);
      }
    }, [dispatch, isRetries]);

    // Запрашивает данные страницы умного расписания при первом рендеренге и при смене филиала в меню
    useEffect(() => {
      if (selectedFilialAccId) {
        dispatch(getSmartScheduleTableData());
      }
    }, [dispatch, selectedFilialAccId]);

    // функция сортировки, получает название столбца отправляет в редакс
    const setSortTypeHandler: TSortCallback = (collumName: string, order: string) => {
      dispatch(setSortTypeDataSP({ collumName, order }));
    };

    const onOpenDrawerHandler: TOnOpenDrawerHandler = useCallback(
      ({ title, description }) =>
        () => {
          dispatch(setShowAppDrawer(true));
          dispatch(setAppDrawerData({ title, description }));
        },
      [dispatch],
    );

    return (
      <div className={className}>
        {isAccessDenied ? <NoAccess /> : ''}
        {isGetTableDateError ? <ErrorMessage /> : null}
        {!isGetTableDateError && !isAccessDenied ? (
          <div>
            <div className='flex items-center mb-2'>
              <h2 className='text-h3Mobile text-black font-medium tracking-[0.004em] leading-8'>
                Список сотрудников мастеров
              </h2>
              <div className='flex items-center justify-center cursor-pointer w-10'>
                <Icon
                  icon='info-sign'
                  color='#37833B'
                  onClick={onOpenDrawerHandler({
                    title: 'Список сотрудников мастеров',
                    description: TOOLTIPS_TEXT.masters,
                  })}
                />
              </div>
            </div>
            {isGettingTableDataSuccess ? (
              <SettingsStaffTable
                data={tableData}
                sortCallback={setSortTypeHandler}
                className='w-screen sm:w-full overflow-x-auto dropdown-list-scrollbar pb-4'
              />
            ) : (
              <Loader className='h-[50Vh]' />
            )}
          </div>
        ) : null}
      </div>
    );
  },
);
