import React, { useMemo } from 'react';
//
import { Menu } from '@uikit/Menu';
import { Button } from '@uikit/Button';
import { REG_EXP } from '@const/common';
import { Dropdown } from '@uikit/Dropdown';
import { useAppDispatch } from '@hooks/index';
import { MenuItem } from '@uikit/Menu/MenuItem';
import { TOnChangeHandler } from '@shared/types';
import { capitaliseString } from '@helpers/index';
import { InputWithLabel } from '@blocks/inputWithLabel';
import { SETTINGS_TELEPHONY_DATA_KEYS_NAME, TOperatorsData } from '@models/Settings';
import { setSelectedOperator, setTelephonyNumber } from '@redux/Settings/slice';
import { OPERATORS_NAME_TO_ADD_DESCRIPTION } from '@const/settings';

type TSettingsTelephonyOperatorProps = {
  /**
   * Значение поля телефон
   * @param {string}
   */
  phoneNumber: string;
  /**
   * Имя выбранного оператора
   * @param {string}
   */
  selectedOperator: string;
  /**
   * Данные операторов
   * @param {string}
   */
  operators: TOperatorsData[];
  /**
   * Иконка для кнопки
   * @param {string}
   */
  buttonIcon: string;
  /**
   * Флаг отключения кнопки
   * @param {boolean}
   */
  disabledButton: boolean;
  /**
   * Обрабатывает нажатия на кнопку подтверждения/редактирования
   * @param {() => void}
   */
  confirmButtonHandler: () => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const SettingsTelephonyOperator = ({
  operators,
  phoneNumber,
  buttonIcon,
  disabledButton,
  selectedOperator,
  confirmButtonHandler,
  className = '',
}: TSettingsTelephonyOperatorProps) => {
  const dispatch = useAppDispatch();

  // Обрабатывает изменение имени оператора
  const setSelectedOperatorNameHandler = (operatorName: string) => () => {
    dispatch(setSelectedOperator(operatorName));
  };

  // Обрабатывает изменение значений в поле phoneNumber
  const setPhoneNumberHandler: TOnChangeHandler = event => {
    const { value } = event.currentTarget;
    dispatch(setTelephonyNumber(value.replace(REG_EXP.onlyNumbers, '')));
  };

  // Очищает поле phoneNumber
  const clearPhoneNumberHandler = () => {
    dispatch(setTelephonyNumber(''));
  };

  // Подготавливаем массив элементов для отображения списка
  const dropdownItems = operators.map(({ name }) => {
    const description = OPERATORS_NAME_TO_ADD_DESCRIPTION[name];
    const operatorNameWithDescription = description
      ? `${description} (${capitaliseString(name)})`
      : name;

    const operatorName =
      operatorNameWithDescription.length <= 4
        ? operatorNameWithDescription.toUpperCase()
        : capitaliseString(operatorNameWithDescription);
    return (
      <MenuItem
        key={name}
        text={<span>{operatorName}</span>}
        onClick={setSelectedOperatorNameHandler(name)}
      />
    );
  });

  const inputFieldsDisabled = buttonIcon === 'edit';

  const selectedOperatorToDropdown = useMemo(() => {
    const description = OPERATORS_NAME_TO_ADD_DESCRIPTION[selectedOperator];

    return description
      ? `${capitaliseString(description)} (${capitaliseString(selectedOperator)})`
      : capitaliseString(selectedOperator);
  }, [selectedOperator]);

  return (
    <div className={`flex flex-wrap sm:flex-nowrap w-full ${className}`}>
      <div className='mb-4 w-full'>
        <p className='font-inter font-medium text-grayText text-body_text mb-1 leading-6 tracking-[0.0275em]'>
          Оператор
        </p>
        <Dropdown
          color='default'
          view='outlined'
          position='bottom-left'
          disabled={inputFieldsDisabled}
          text={<span>{selectedOperatorToDropdown}</span>}
          content={
            <Menu className={dropdownItems.length === 1 ? `one_item_ul` : ''} view='raised'>
              {dropdownItems}
            </Menu>
          }
          className='when_send_dropdown !m-0 w-full'
        />
      </div>
      <div className='flex items-end mb-4 sm:ml-4 w-full'>
        <InputWithLabel
          value={phoneNumber}
          disabled={inputFieldsDisabled}
          onChangeHandler={setPhoneNumberHandler}
          clearSearchString={clearPhoneNumberHandler}
          htmlFor={SETTINGS_TELEPHONY_DATA_KEYS_NAME.PHONE_NUMBER}
          variant={SETTINGS_TELEPHONY_DATA_KEYS_NAME.PHONE_NUMBER}
          className='w-full'>
          Номер телефона
        </InputWithLabel>
        <div className='mb-0.5 ml-4 h-[2.25rem] w-[2.659rem]'>
          <Button
            type='icon'
            color='default'
            view='outlined'
            icon={buttonIcon}
            disabled={disabledButton}
            onClick={confirmButtonHandler}
            className='h-[2.25rem] w-[2.25rem]'
          />
        </div>
      </div>
    </div>
  );
};
