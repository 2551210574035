import { RootState } from '@store/store';

// app selector
export const getAppData = (state: RootState) => state.app;

// auth selector
export const getAuthData = (state: RootState) => state.auth;

// accounts selector
export const getAccountsData = (state: RootState) => state.accounts;

// task
export const getTaskPageData = (state: RootState) => state.task;

// blackList
export const getBlackListData = (state: RootState) => state.blackList;

// report
export const getReportPageData = (state: RootState) => state.report;

// settings selector
export const getSettingsData = (state: RootState) => state.settings;

// pay selector
export const getPayPageState = (state: RootState) => state.pay;

// dashboard selector
export const getDashboardPageData = (state: RootState) => state.dashboard;

export const getEmojiDate = (state: RootState) => state.emoji;

// analytics selector
export const getAnalyticsData = (state: RootState) => state.analytics;

// noteList selector
export const getNoteListData = (state: RootState) => state.noteList;

// note selector
export const getNoteData = (state: RootState) => state.note;
