import React, { useCallback } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
//
import { Button } from '@uikit/Button';
import { Icon } from '@blueprintjs/core';
import { PATH_NAMES } from '@const/httpConst';
import { useAppSelector } from '@hooks/index';
import { getAccountsData } from '@redux/selectors';
import { QUERY_PARAMS } from '@const/apiConstants';
import { ReactComponent as FolderIcon } from '@img/notes/folder_close.svg';

type TProps = {
  /**
   * Имя выбранной папки
   * @param {string}
   */
  activeFolderName: string;
  /**
   * Заголовок заметки
   * @param {string}
   */
  noteTitle: string;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const NotePath = ({ activeFolderName, noteTitle, className = '' }: TProps) => {
  const navigate = useNavigate();

  const { selectedFilial } = useAppSelector(getAccountsData);

  const taskReturnButtonHandler = useCallback(() => {
    navigate({
      pathname: PATH_NAMES.NOTE_LIST,
      search: createSearchParams({
        [QUERY_PARAMS.ACC_ID]: selectedFilial?.accId || '',
      }).toString(),
    });
  }, [selectedFilial, navigate]);

  return (
    <div className={`flex flex-wrap items-center ${className}`}>
      <Button
        dense
        text='Заметки'
        type='default'
        view='outlined'
        color='default'
        className='!px-3 mb-2.5'
        onClick={taskReturnButtonHandler}
      />
      <Icon icon='chevron-right' size={20} color='#4A4B57' className='ml-4 mr-3 mb-2.5' />
      <div className='flex items-center mb-2.5 mr-3'>
        <FolderIcon fill='#B4B4BB' className='mr-2' />
        <span className='text-gray2 leading-6 tracking-[0.0275em]'>{activeFolderName}</span>
      </div>
      <Icon icon='chevron-right' size={20} color='#4A4B57' className='mr-3 mb-2.5' />
      <div className='flex items-center mb-2.5'>
        <Icon icon='document' size={20} color='#19191D' className='mr-2' />
        <span className='font-medium text-gray2 leading-6 tracking-[0.0275em] break-all'>
          {noteTitle}
        </span>
      </div>
    </div>
  );
};
