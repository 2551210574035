export const ConfigENV = {
  BASE_URL: process.env.REACT_APP_API_URL,
};

export enum REQUEST_STATUS {
  SUCCESS = 200,
  ACCESS_DENIED = 401,
  TOKEN_EXPIRED = 403,
}

export enum REQUEST_TEXT_ERROR_STATUS {
  ACCESS_DENIED = 'Access denied',
  TOKEN_EXPIRED = 'Expired token',
  USER_NOT_FOUND = 'User not found',
}

export type TCommonSearchParams = {
  user_id: string;
  acc_id: string;
};

/**
 * Список путей для роутов по страницам приложения
 */
export enum PATH_NAMES {
  START_PAGE = '/',
  ACCOUNTS = '/accounts',
  // Аналитика
  ANALYTICS = '/analytics',
  ANALYTICS_ADMINS = '/analytics/admins',
  ADMINS_TABLE = '/analytics/admins/table',
  DASHBOARD = '/dashboard',
  FINANCES = '/dashboards/finances',
  FINANCES_TABLE = '/dashboards/finances/table',
  MASTERS = '/dashboards/masters',
  MASTERS_TABLE = '/dashboards/masters/table',
  ADMINS = '/dashboards/admins',
  TASKS_ALL = '/tasks/all',
  TASKS_REGULAR = '/tasks/regular',
  TASKS_EVENT = '/tasks/event',
  TASKS_CLIENTS = '/tasks/clients',
  ONE_TASK = '/task',
  NEW_TASK = '/new_task',
  NOTE_LIST = '/notes/list',
  NEW_NOTE = '/notes/new_note',
  ONE_NOTE = '/notes/note',
  REPORTS = '/reports',
  ONE_REPORTS_TEMPLATE = '/report_template',
  NEW_REPORT_TEMPLATE = '/new_report_template',
  // Настройки
  SETTINGS = '/settings',
  BLACKLIST = '/settings/blacklist',
  SETTINGS_NOTIFICATIONS = '/settings/notifications',
  TELEPHONY = '/settings/telephony',
  SETTINGS_ADMINS = '/settings/admins',
  SETTINGS_MASTERS = '/settings/masters',
  SETTINGS_PAY = '/settings/deposit',
  STAFF = '/staff',
  PAY = '/pay',
  PAGE_404 = '/*',
}
