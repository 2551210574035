import React, { memo, useCallback, useState } from 'react';
import { Menu } from '@uikit/Menu';
import { Button } from '@uikit/Button';
import { Drawer } from '@uikit/Drawer';
import { Dialog } from '@uikit/Dialog';
import { Icon } from '@blueprintjs/core';
import { Dropdown } from '@uikit/Dropdown';
import { MenuItem } from '@uikit/Menu/MenuItem';
import { TFolderItem } from '@redux/noteList/zod';
import { TNonePageStatus } from '@redux/note/zod';
import { useWindowWidth } from '@hooks/useWindowWidth';
import { TSetActiveFolderHandler } from '@shared/types';
import { ReactComponent as FolderIcon } from '@img/notes/folder_close.svg';

type TProps = {
  /**
   * Статус запросов на страницы
   * @param {TNonePageStatus}
   */
  status: TNonePageStatus;
  /**
   * Флаг показа модалки
   * @param {boolean}
   */
  isOpen: boolean;
  /**
   * Активная папка
   * @param {TFolderItem}
   */
  activeFolder: TFolderItem;
  /**
   * Список папок дла дропдауна
   * @param {TFolderItem[]}
   */
  folderList: TFolderItem[];
  /**
   * Закрывает модальное окно
   * @param {() =. void}
   */
  closeModalHandler: () => void;
  /**
   * Перемещает заметку в указанную папку
   * @param {TSetActiveFolderHandler}
   */
  moveToFolder: TSetActiveFolderHandler;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const NoteMoveToFolderModal = memo(
  ({
    isOpen,
    status,
    folderList,
    activeFolder,
    moveToFolder,
    closeModalHandler,
    className = '',
  }: TProps) => {
    const width = useWindowWidth();
    const [selectedFolder, setSelectedFolder] = useState<TFolderItem>(activeFolder);

    const setSelectedFolderHandler = useCallback(
      (folder: TFolderItem) => () => {
        setSelectedFolder(folder);
      },
      [],
    );

    const moveToFolderHandler = useCallback(() => {
      moveToFolder(selectedFolder);
    }, [moveToFolder, selectedFolder]);

    const disableMoveButton = activeFolder.id === selectedFolder.id;

    const buttons = (
      <>
        <Button
          dense
          type='action'
          view='outlined'
          color='default'
          text='Отмена'
          onClick={closeModalHandler}
          className='mb-2 sm:mb-0 sm:mr-6'
        />
        <Button
          dense
          type='action'
          view='filled'
          color='success'
          text='Перенести'
          onClick={moveToFolderHandler}
          loading={status === 'moveToFolder'}
          disabled={disableMoveButton || status !== 'success'}
        />
      </>
    );

    const itemsToRender = folderList.map(folder => (
      <MenuItem
        key={folder.id}
        text={
          <div className='flex'>
            <FolderIcon fill='#4A4B57' className='mr-2' />
            <span className='text-blackText leading-6 tracking-[0.0275em]'>{folder.title}</span>
          </div>
        }
        onClick={setSelectedFolderHandler(folder)}
        className='flex items-center h-10'
      />
    ));

    const menuToRender = (
      <Menu className={`${itemsToRender.length === 1 ? `one_item_ul` : ''} w-full`} view=''>
        {itemsToRender}
      </Menu>
    );

    return (
      <div className={className}>
        {width < 641 ? (
          <Drawer size='100%' position='right' isOpen={isOpen} style={{ borderRadius: 0 }}>
            <div className='flex flex-col grow pt-4 px-1'>
              <div className='flex items-center justify-between mb-4'>
                <span className='font-bold text-myrtle text-[1.25rem] leading-8 tracking-[0.0075em]'>
                  Переместить заметку в папку
                </span>
                <button
                  aria-label='close'
                  onClick={closeModalHandler}
                  className='bg-none border-none flex items-start self-start justify-end h-9 w-9'>
                  <Icon icon='cross' size={16} color='#4A4B57' className='m-[0.3125rem]' />
                </button>
              </div>
              <div className='flex flex-col grow'>{menuToRender}</div>
              <div className='flex flex-col justify-between pb-4'>
                <div className='flex flex-col sm:flex-row sm:items-center justify-end'>
                  {buttons}
                </div>
              </div>
            </div>
          </Drawer>
        ) : (
          <Dialog
            type='default'
            view='raised'
            isOpen={isOpen}
            backdropOpacity={40}
            rightButton={buttons}
            title='Переместить заметку в папку'
            text={
              <Dropdown
                color='default'
                view='outlined'
                position='bottom-left'
                className={`mt-2 when_send_dropdown w-full ${className}`}
                text={
                  <div className='flex items-center'>
                    <FolderIcon fill='#B4B4BB' className='mr-3' />
                    <span className='text-blackText leading-6 tracking-[0.0275em]'>
                      {selectedFolder.title}
                    </span>
                  </div>
                }
                content={
                  <Menu
                    className={`${itemsToRender.length === 1 ? `one_item_ul` : ''} w-full`}
                    view='raised'>
                    {itemsToRender}
                  </Menu>
                }
              />
            }
            onClose={closeModalHandler}
          />
        )}
      </div>
    );
  },
);

NoteMoveToFolderModal.displayName = 'NoteMoveToFolderModal';
