import { Chart as ChartJS, TooltipItem, Plugin } from 'chart.js';
//
import { REG_EXP } from '@const/common';
import { emptyWeekDaysData } from '@data/index';
import { QUERY_PARAMS } from '@const/apiConstants';
import { TFocusEventOnInputElement } from '@shared/types';
import { adminsKey, clientsKey, financesKey, staffKey, taskKey } from '@const/report';
import {
  chartColors,
  NO_CHART_DATA,
  DASHBOARD_KEYS,
  RETURN_MASTER_RATE_FILTERS_NAME,
  TELEPHONY_CALL_TYPE_FILTER_NAME,
} from '@const/dashboard';
import {
  TSort,
  TNumWord,
  THelpKeys,
  TGetDataset,
  TValidateValue,
  TGetRotationAnge,
  TSimpleStringObj,
  TChatMultiLineData,
  TGetAllAmountDataSet,
  TSelectedDashboardList,
  TGetEaredByOutCallsData,
  TGetOneLineChartDataset,
  TGetDataForDoughnutChart,
  TGetDoughnutChartDataset,
  TGetStackedBarChartDataset,
  TGetDataForDoughnutChartTData,
  FormattedData,
} from '@models/index';
import { TEarnedByOutCalls } from '@api/dashboard/types';
import { TWeekDays } from '@models/Tasks';

import { TChartData } from 'models/Analytics';

/**
 * @module helpers
 */

// eslint-disable-next-line
export const log = (...arg: unknown[]) => console.log(arg);

// eslint-disable-next-line
export const customPrompt = (message: string) => prompt(message);

/**
 * Функция выбирает слова из предоставленного в параметрах массива с правильным окончанием, например: 2 днЯ, 5 днЕЙ
 * является вспомогательной для getParsedXlsxFileInfo
 * @function numWord
 * @param value {number} значение
 * @param words {string[]} массив слов с разными окончаниями
 * @return {string}
 */
export const numWord: TNumWord = (value, words): string => {
  value = Math.abs(value) % 100;
  const num = value % 10;
  if (value > 10 && value < 20) return words[2];
  if (num > 1 && num < 5) return words[1];
  if (num === 1) return words[0];
  return words[2];
};

/**
 * Функция преобразует дату в европейский формат
 * @function getEUDateFromString
 * @param date {string} дата в формате YYYY-MM-DD
 * @param separator {string} разделитель между числами даты по умолчанию '-' (опциональный параметр)
 * @return {string} дату в формате DD-MM-YYYY
 */
export const getEUDateFromString = (date: string, separator?: string): string => {
  const year = date.slice(0, 4);
  const month = date.slice(5, 7);
  const day = date.slice(8, 10);

  return `${day}${separator || '-'}${month}${separator || '-'}${year}`;
};

/**
 * Обрезает длинное название филиала до 21 символа и добавляет ...
 * @function getFilialName
 * @param name {string} строка с именем
 * @return {string} обрезанная до 21 символа строка
 */
export const getFilialName = (name: string): string => {
  return name && name.length > 21 ? `${name.slice(0, 21)}...` : name;
};

/**
 * Создает ссылку на таск-трекер - https://${domainName}.kaiten.ru/
 * @function getTakTrackerLink
 * @param domainName {string} строка с именем домена
 * @return {string}
 */
export const getTakTrackerLink = (domainName: string): string => {
  return `https://${domainName}.kaiten.ru/`;
};

/**
 * Функция из переданных параметров формирует строку с обрамленным в спец символы участком текста и возвращает эту строку
 * @function getWrapperText
 * @param oldText {string} текст в котором нужно обернуть выделенный участок в спецсимволы
 * @param wrappedSymbol {string} символ в который нужно обернуть текст
 * @param startIndex {number} индекс начала выделения
 * @param endIndex {number} индекс окончания выделения
 * @return {string}
 */
export const getWrapperText = (
  oldText: string,
  wrappedSymbol: string,
  startIndex: number,
  endIndex: number,
): string => {
  if (startIndex === endIndex || startIndex > endIndex) {
    return oldText;
  }
  // получаем выделенный текст из переданной строки
  const selectedText = oldText.substring(startIndex, endIndex);

  // оборачиваем выделенный текст в спецсимволы
  const textWithTags = `${wrappedSymbol}${selectedText}${wrappedSymbol}`;
  // В переданную строку вставляем новый текст вместо выделенного
  return oldText.substring(0, startIndex) + textWithTags + oldText.substring(endIndex);
};

/**
 * Функция из переданного объекта Date выделяет день, месяц, год,
 * объединят все в строку с разделителем "." и возвращает ее
 * @function getDateString
 * @param date {Date} объект Date
 * @return {string} строка с датой в формате DD.MM.YYYY
 */
export const getDateString = (date: Date): string => {
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
};

/**
 * Функция из переданного объекта Date выделяет год, месяц, день,
 * объединят все в строку с разделителем "-" добавляет 00:00:00
 * и возвращает ее
 * @function getDateString
 * @param date {Date} объект Date
 * @return {string} строка даны в формате YYYY-MM-DD HH:MM:SS
 */
export const getDateStringToGetData = (date: Date): string => {
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const year = date.getFullYear();
  return `${year}-${month}-${day} 00:00:00`;
};

export const getDateStringToISOString = (date: Date): string => {
  return date.toISOString();
};

/**
 * Функция из переданной строки даты в формате DD.MM.YYYY создает объект Date
 * парсит строку на DD, MM, YYYY записывает эти данные в Date
 * возвращает объект Date
 * @function getDateFromProps
 * @param dateS {string} дата в формате DD.MM.YYYY
 * @return {Date} объект Date
 */
export const getDateFromProps = (dateS: string): Date => {
  const day = dateS.slice(0, 2);
  const month = dateS.slice(3, 5);
  const year = dateS.slice(6);
  const newDate = new Date();
  newDate.setDate(+day);
  newDate.setMonth(+month);
  newDate.setFullYear(+year);
  return newDate;
};

/**
 * Функция получает время строкой в формате HH:MM создает объект Date
 * парсит переданную строку на часы и минуты
 * записывает эти данные в Date
 * возвращает Date с новым временем * @param time {string}
 * @function getTime
 * @param time {string} время в формате HH:MM
 * @return {Date} объект Date
 */
export const getTime = (time: string): Date => {
  const newDate = new Date();
  const hours = Number(time.slice(0, 2));
  const minutes = Number(time.slice(3));
  newDate.setHours(hours);
  newDate.setMinutes(minutes);
  return newDate;
};

/**
 * Функция принимает объект Data получает из него часы и минуты
 * минуты округляет до 15 мин если isSetOnlyHour true округляет минуты до 00
 * возвращает строку формата HH:MM
 * @function getRoundedTime
 * @param date {Date}
 * @param isSetOnlyHour {boolean}
 * @return {string} время в формате HH:MM
 */
export const getRoundedTime = (date: Date, isSetOnlyHour: boolean): string => {
  const min = 15;
  const ms = 1000 * 60 * min;

  const roundedDate = new Date(Math.round(date.getTime() / ms) * ms);

  const h = roundedDate.getHours();
  const m = roundedDate.getMinutes();

  const hours = h < 10 ? `0${h}` : h;
  const minutes = m < 10 ? `0${m}` : m;

  return `${hours}:${isSetOnlyHour ? '00' : minutes}`;
};

/**
 * Функция по переданному ключу keyGroupName находит нужный объект-словарь
 * и возвращает его
 * @function getSelectedKeyDict
 * @param keyGroupName {string}
 * @return {TSimpleStringObj} словарь
 */
export const getSelectedKeyDict = (keyGroupName: string): TSimpleStringObj => {
  switch (keyGroupName) {
    case 'staff':
      return staffKey;
    case 'admins':
      return adminsKey;
    case 'clients':
      return clientsKey;
    case 'finances':
      return financesKey;
    case 'task':
      return taskKey;
    default:
      return financesKey;
  }
};

/**
 * Выделяет текст в инпуте при фокусе на нем
 * @function selectTextContentOnFocus
 */
export const selectTextContentOnFocus: TFocusEventOnInputElement = event => {
  event.currentTarget.select();
};

/**
 * Функция по переданному ключу keyGroupName находит нужный объект-словарь
 * в нем ищет ключ совпадающий с keyName
 * возвращает значение ключа из найденного объекта
 * если совпадений не найдено возвращает keyName
 * @function getKeyDescription
 * @param keyGroupName {string}
 * @param keyName {string}
 * @return {string}
 */
export const getKeyDescription = (keyGroupName: string, keyName: string): string => {
  return getSelectedKeyDict(keyGroupName)[keyName]
    ? getSelectedKeyDict(keyGroupName)[keyName]
    : getSelectedKeyDict(keyGroupName).filial_name;
};

/**
 * Функция из переданной строки, с помощью регулярки, убирает пробелы
 * @function getStringWithoutSpaces
 * @param string {string} строка с числами, разделенными запятой
 * @return {string}
 */
export const getStringWithoutSpaces = (string: string): string => {
  return string.replace(REG_EXP.removeAllSpace, ''); // match(/\d+,?) забирает только цифры и запятые
};

/**
 * Вспомогательная функция используется в getSortedStringWithoutDoubleValuesAndComaOnEnd и getStringWithoutDoubleValues
 * для удаления повторяющихся значений
 * @function removeDoubleValues
 * @param array {string[]}
 * @return {string[]}
 */
export const removeDoubleValues = (array: string[]): string[] => {
  const set = new Set(array);
  const setValues = set.values();
  return Array.from(setValues);
};

/**
 * Функция сортирует переданную строку, убирает повторяющиеся значения и запятую в конце если она есть
 * @function getSortedStringWithoutDoubleValuesAndComaOnEnd
 * @param string {string} строка с числами, разделенными запятой
 * @return {string}
 */
export const getSortedStringWithoutDoubleValuesAndComaOnEnd = (string: string): string => {
  const array = string.split(',');
  const arrayWithoutEmptyElement = array.filter(item => !!item).map(item => item.trim());
  const sortedArrayWithoutDouble = removeDoubleValues(arrayWithoutEmptyElement).sort(
    (a, b) => +a - +b,
  );
  return sortedArrayWithoutDouble.join(', ').trim();
};

/**
 * Функция убирает повторяющиеся значения
 * @function getStringWithoutDoubleValues
 * @param string {string} строка с числами, разделенными запятой
 * @return {string}
 */
export const getStringWithoutDoubleValues = (string: string): string => {
  const array = string.split(',');
  const lastElement = array.pop();
  const arrayWithoutDouble = removeDoubleValues(array);
  if (!lastElement) {
    arrayWithoutDouble.push('');
  } else {
    arrayWithoutDouble.push(lastElement);
  }
  return arrayWithoutDouble.join(',');
};

/**
 * Функция удаляет из переданного массива последний элемент
 * @function getArrayWithoutLastElement
 * @param array
 */
export const getArrayWithoutLastElement = <T>(array: T[]): T[] => {
  return array.slice(0, array.length - 1);
};

/**
 * Функция принимает в параметрах мини/макс значения и значение по умолчанию
 * сравнивает значение переменной value c lowLimit, upLimit.
 * если значение больше/меньше upLimit/lowLimit возвращаем upLimit/lowLimit
 * если значение не определено или при преобразовании в число возвращается NaN возвращает значение по умолчанию
 * @function validateValue
 * @param lowLimit {number} - числовое значение, которое определяет нижний предел допустимого диапазона.
 * @param upLimit {number} - числовое значение, которое определяет верхний предел допустимого диапазона.
 * @param value {string} - строковое значение, которое требуется проверить.
 * @param initialValue {string} - строковое значение, которое будет возвращено в случае, если value не удовлетворяет условиям валидации.
 */
export const validateValue: TValidateValue = ({ lowLimit, upLimit, value, initialValue }) => {
  if (value === '') {
    return initialValue;
  }
  if (Number.isNaN(Number(value))) {
    return initialValue;
  }
  if (Number(value) < lowLimit) {
    return initialValue;
  }
  if (upLimit && Number(value) > upLimit) {
    return String(upLimit);
  }
  return value;
};

/**
 * функция создает объект данных для графиков ChartJS.
 * принимает массив данных и массив цветов
 * проходя по массиву данных, разбивает элемент массива на описание и значение
 * так же присваивает цвет столбцу/линии на графике
 * @function getDataset
 * @param valuesList {Record<string, any[]>} массив данных
 * @param colorsArrayToChart {string[]} массив цветов
 * @return {TDatasetWithColors}
 */
export const getDataset: TGetDataset = (valuesList, colorsArrayToChart) => {
  return Object.entries(valuesList).map(([label, values], index) => ({
    label,
    data: values,
    backgroundColor: colorsArrayToChart[index],
    borderColor: colorsArrayToChart[index],
  }));
};

/**
 * Функция принимает данные и создает dataset для MultilineChart(конкурентная диаграмма)
 * @function chatMultiLineData
 * @param data {TReturn_master_rate[]} объект с данными
 * @param keyName {string} имя ключа для получения массива данных
 * @param colors {TSimpleStringObj} массив цветов, для "разукрашивания" графика
 * @return {FormattedData}
 */
export const chatMultiLineData: TChatMultiLineData = ({ data, keyName, colors }) => {
  const chatLabels: string[] = [];
  const valuesList: { [key: string]: number[] } = {};
  const colorsArrayToChart = Object.values(colors);

  (data || []).forEach((dataItem, index) => {
    chatLabels.push(getEUDateFromString(String(dataItem.date), '.'));

    const dayData =
      keyName === RETURN_MASTER_RATE_FILTERS_NAME.RETURN_CLIENT_TO_MASTER_RATE
        ? dataItem?.masters
        : dataItem?.filters[keyName];

    (dayData || [])?.forEach(master => {
      const masterName = Object.keys(master)[0];
      const masterValue = +Object.values(master)[0];

      if (!valuesList[masterName]) {
        valuesList[masterName] = Array(data.length).fill(0);
      }

      valuesList[masterName][index] = masterValue;
    });
  });

  return {
    labels: chatLabels,
    datasets: getDataset<number>(valuesList, colorsArrayToChart),
  };
};

/**
 * Функция принимает данные и создает dataset для StackedBarChart(столбчатая составная диаграмма)
 * @function getAllAmountDataSet
 * @param data {TData[]} объект с данными
 * @param keyName {string} имя ключа для получения массива данных
 * @param colors {TSimpleStringObj} массив цветов, для "разукрашивания" графика
 * @return {FormattedData}
 */
export const getAllAmountDataSet: TGetAllAmountDataSet = ({ data, keyName, colors }) => {
  const chatLabels: string[] = [];
  const valuesObj: { [key: string]: number[] } = {};
  const colorsArrayToChart = Object.values(colors);

  (data || []).forEach((dataItem, index) => {
    chatLabels.push(getEUDateFromString(dataItem.date, '.'));
    (dataItem?.filters[keyName] || []).forEach(master => {
      const masterName = Object.keys(master)[0];
      const masterValue = Object.values(master)[0];

      if (!valuesObj[masterName]) {
        valuesObj[masterName] = Array(data.length).fill('0');
      }
      valuesObj[masterName][index] = +masterValue;
    });
  });

  return {
    labels: chatLabels,
    datasets: getDataset<number>(valuesObj, colorsArrayToChart),
  };
};

/**
 * Функция принимает данные и создает dataset для StackedBarChart(столбчатая составная диаграмма)
 * @function getEaredByOutCallsData
 * @param data {TEaredByOutCallsData[]} объект с данными
 * @param keyName {string} имя ключа для получения массива данных
 * @param colors {TSimpleStringObj} массив цветов, для "разукрашивания" графика
 * @return {FormattedData}
 */
export const getEaredByOutCallsData: TGetEaredByOutCallsData = ({
  data,
  keyName,
  colors,
}: {
  data: Array<TEarnedByOutCalls>;
  keyName: keyof TEarnedByOutCalls['filters'];
  colors: TSimpleStringObj;
}): FormattedData => {
  const chatLabels: string[] = [];
  const valuesObj: { [key: string]: number[] } = {};
  const colorsArrayToChart = Object.values(colors);

  const zeroArray = new Array(data.length).fill(0);

  (data || []).forEach((dataItem, index) => {
    chatLabels.push(getEUDateFromString(dataItem.date, '.'));

    const filteredData = dataItem.filters[keyName] ?? [];
    if (typeof filteredData === 'string') return;

    filteredData.forEach(filterItem => {
      if (keyName === 'masters') {
        const { name = '', total: value = '' } = filterItem;
        if (!valuesObj[name]) {
          valuesObj[name] = [...zeroArray];
        }

        valuesObj[name][index] = +value;
      }

      if (keyName === 'service_category') {
        Object.keys(filterItem).forEach(item => {
          if (!valuesObj[item]) {
            valuesObj[item] = [...zeroArray];
          }

          valuesObj[item][index] = +filterItem[item as keyof typeof filterItem];
        });
      }
    });
  });

  const datasets = Object.entries(valuesObj).map(([label, values], index) => {
    return {
      label,
      data: values,
      backgroundColor: colorsArrayToChart[index],
      borderColor: colorsArrayToChart[index],
    };
  });

  return {
    labels: chatLabels,
    datasets,
  };
};

/**
 * Функция принимает данные и создает dataset для LineChart, график строится в одну линию
 * @function getOneLineChartDataset
 * @param chartData {any[]} объект с данными
 * @param key {string} имя ключа для получения массива данных
 * @param chartLabel {string} Подпись данных в графике
 * @param borderColor {string} Цвет бордера линии/столбца
 * @param backgroundColor {string} Цвет заливки линии/столбца
 * @return {FormattedData}
 */
export const getOneLineChartDataset: TGetOneLineChartDataset = ({
  chartData,
  key,
  chartLabel,
  borderColor,
  backgroundColor,
}) => {
  const values: number[] = [];

  const chatLabels = (chartData || []).map(item => {
    const keysArray = key?.split('.');

    if (keysArray.length > 1) {
      values.push(item[key?.split('.')[0]][key?.split('.')[1]]);
    } else {
      values.push(item.value || 0);
    }
    return getEUDateFromString(item.date, '.');
  });

  return {
    labels: chatLabels,
    datasets: [
      {
        label: chartLabel,
        data: values,
        borderColor,
        backgroundColor,
      },
    ],
  };
};
/**
 * Функция принимает данные и создает dataset для StackedBarChart
 * @function getStackedBarChartDataset
 * @param chartData {any[]} объект с данными
 * @param label {string} Подпись данных в графике
 * @return {FormattedData}
 */
export const getStackedBarChartDataset: TGetStackedBarChartDataset = ({ chartData, label }) => {
  const values: number[] = [];
  // const name = chartData[0]?.name;
  const labels = (chartData || []).map(item => {
    values.push(item.value);
    return getEUDateFromString(item.date, '.');
  });

  const colorsArrayToChart = Object.values(chartColors)[0];

  return {
    labels,
    datasets: [
      {
        label,
        data: values,
        backgroundColor: colorsArrayToChart,
        borderColor: colorsArrayToChart,
      },
    ],
  };
};

/**
 * Сортирует преданный массив объектов по убыванию значения
 * @function getSortedArray
 * @param valuesMap {Record<string, number>}
 * @return {Record<string, number>}
 */
export const getSortedArray = (valuesMap: { [key: string]: number }) => {
  const sortedArr = Object.entries(valuesMap).sort((a, b) => b[1] - a[1]);
  return Object.fromEntries(sortedArr);
};

/**
 * Функция высчитывает поворот DoughnutChart для размещения самого большого сегмента графика наверху
 * @param chartData {number[]} Данные для расчетов
 * @return {number}
 */
export const getRotationAnge: TGetRotationAnge = chartData => {
  const sumOfData = [...chartData].reduce(
    (previousValue, currentValue) => previousValue + currentValue,
    0,
  );
  const largestItem = [...chartData].sort((a, b) => b - a)[0];
  return (-largestItem / 2 / sumOfData) * 360;
};

/**
 * Функция подготавливает объект dataset для DoughnutChart
 * @function getDataForDoughnutChart
 * @param data {TData[]} Данные для графика
 * @param keyName {string} имя ключа для получения массива данных
 * @param chartTitle {string} Заголовок графика
 * @return {TDoughnutChartDataset}
 */
export const getDataForDoughnutChart: TGetDataForDoughnutChartTData = ({
  data,
  keyName,
  chartTitle,
}) => {
  const valuesMap: { [key: string]: number } = {};

  (data || []).forEach(dateItem => {
    (dateItem.filters[keyName] || []).forEach(filterItem => {
      const filterItemName = Object.keys(filterItem)[0];
      const filterItemValue = +Object.values(filterItem)[0];

      if (filterItemName in valuesMap) {
        valuesMap[filterItemName] += filterItemValue;
      } else {
        valuesMap[filterItemName] = filterItemValue;
      }
    });
  });

  const sortedObj = getSortedArray(valuesMap);

  const dataArray = Object.entries(sortedObj).map(([key, value]) => ({ [key]: value }));

  const values: number[] = [];

  const chatLabels = (dataArray || []).map(item => {
    return Object.entries(item).map(item2 => {
      values.push(item2[1] as number);
      return item2[0];
    });
  });

  return {
    labels: chatLabels,
    datasets: [
      {
        label: `${chartTitle || 'Диапазон дат'}`,
        data: values,
        backgroundColor: Object.values(chartColors),
        borderColor: Object.values(chartColors),
        rotation: getRotationAnge(values),
      },
    ],
  };
};

/**
 * Функция подготавливает объект dataset для DoughnutChart
 * @function getDataForDoughnutChartFromEaredByOutCalls
 * @param data {any[]} Данные для графика
 * @param keyName {string} Имя ключа для получения массива данных
 * @param chartTitle {string} Заголовок графика
 * @return {TDoughnutChartDataset}
 */
export const getDataForDoughnutChartFromEaredByOutCalls: TGetDataForDoughnutChart = ({
  data,
  keyName,
  chartTitle,
}) => {
  const valuesMap: { [key: string]: number } = {};

  (data || []).forEach(dateItem => {
    // eslint-disable-next-line
    (dateItem.filters[keyName] || []).forEach((filterItem: any) => {
      if (keyName === 'masters') {
        const { name: filterName, total, title, amount } = filterItem;
        const name = filterName || title;
        const value = total || amount;
        const parsedValue = typeof value === 'number' ? value : 0;

        if ((name as string) in valuesMap) {
          valuesMap[name as string] += parsedValue;
        } else {
          valuesMap[name as string] = parsedValue;
        }
      }

      if (keyName === 'service_category') {
        Object.keys(filterItem).forEach(item => {
          if (item in valuesMap) {
            valuesMap[item] += filterItem[item];
          } else {
            valuesMap[item] = filterItem[item];
          }
        });
      }
    });
  });

  const sortedObj = getSortedArray(valuesMap);

  const dataArray = Object.entries(sortedObj).map(([key, value]) => ({ [key]: value }));

  const values: number[] = [];

  const chatLabels = (dataArray || []).map(item => {
    return Object.entries(item).map(item2 => {
      values.push(item2[1] as number);
      return item2[0];
    });
  });

  return {
    labels: chatLabels,
    datasets: [
      {
        label: `${chartTitle || 'Диапазон дат'}`,
        data: values,
        backgroundColor: Object.values(chartColors),
        borderColor: Object.values(chartColors),
        rotation: getRotationAnge(values),
      },
    ],
  };
};

// TODO Проверить возможность использовать эту функцию вместо
/**
 * Функция подготавливает объект dataset для DoughnutChart
 * @function getDoughnutChartDataset
 * @param chartData {any[]} Данные для графика
 * @param chartTitle {string} Заголовок графика
 * @param getRotationAngeCallback {TGetRotationAnge} Callback для расчета угла поворота графика
 * @return {TDoughnutChartDataset}
 */
export const getDoughnutChartDataset: TGetDoughnutChartDataset = ({
  chartData,
  chartTitle,
  getRotationAngeCallback,
}) => {
  const values: number[] = [];
  const chatLabels = (chartData || []).map(item => {
    return Object.entries(item).map(item2 => {
      values.push(item2[1] as number);
      return item2[0];
    });
  });

  return {
    labels: chatLabels,
    datasets: [
      {
        label: `${chartTitle || 'Диапазон дат'}`,
        data: values,
        backgroundColor: Object.values(chartColors),
        borderColor: Object.values(chartColors),
        rotation: getRotationAngeCallback(values),
      },
    ],
  };
};

/**
 * Функция возвращает даты начала и конца текущего квартала
 * @function getCurrentQuarter
 * @return [Date, Date];
 */
export const getCurrentQuarter = () => {
  // Get Today's date()
  const today = new Date();
  // Get current quarter
  const currentQuarter = Math.floor(today.getMonth() / 3);
  // Get current year
  const year = today.getFullYear();
  // Get current quarter's start and end date
  const quarterStart = new Date(year, currentQuarter * 3, 1);
  const quarterEnd = new Date(year, (currentQuarter + 1) * 3, 0);
  return [quarterStart, quarterEnd];
};

/**
 * Функция создает объект кастомных ссылок для компонента DateRangeDashboardPicker
 * Возвращает массив объектов { label: текст ссылки, date: массив из двух дат(начало и конец периода) }[]
 * @function getCustomShortcuts
 */
export const getCustomShortcuts = () => {
  const now = new Date();
  const tenMinutesAgo = new Date();
  tenMinutesAgo.setMinutes(tenMinutesAgo.getMinutes() - 10);
  const tenHoursAgo = new Date();
  tenHoursAgo.setHours(tenHoursAgo.getHours() - 10);
  const oneDayAgo = new Date();
  oneDayAgo.setDate(oneDayAgo.getDate() - 1);
  const sevenDayAgo = new Date();
  sevenDayAgo.setDate(sevenDayAgo.getDate() - 7);
  const fourteenDaysAgo = new Date();
  fourteenDaysAgo.setDate(fourteenDaysAgo.getDate() - 14);
  const currentMonth = new Date();
  currentMonth.setDate(1);
  const prevMonthStart = new Date();
  prevMonthStart.setMonth(prevMonthStart.getMonth() - 1);
  prevMonthStart.setDate(1);
  const prevMonthEnd = new Date();
  prevMonthEnd.setDate(1);
  prevMonthEnd.setDate(prevMonthEnd.getDate() - 1);
  const [currentQuarterStart] = getCurrentQuarter();
  const quarter = Math.floor(now.getMonth() / 3);
  const startFullQuarter = new Date(now.getFullYear(), quarter * 3 - 3, 1);
  const endFullQuarter = new Date(
    startFullQuarter.getFullYear(),
    startFullQuarter.getMonth() + 3,
    0,
  );
  const currentYear = new Date(now.getFullYear(), 0, 1);
  const tenDaysAgo = new Date();
  tenDaysAgo.setDate(tenDaysAgo.getDate() - 10);

  return [
    // {
    //   dateRange: [now, now],
    //   label: 'Сегодня',
    // },
    // {
    //   dateRange: [oneDayAgo, now],
    //   label: 'Вчера',
    // },
    {
      dateRange: [sevenDayAgo, now],
      label: 'Последние 7 дней',
    },
    {
      dateRange: [fourteenDaysAgo, now],
      label: 'Последние 14 дней',
    },
    {
      dateRange: [currentMonth, now],
      label: 'Текущий месяц',
    },
    {
      dateRange: [prevMonthStart, prevMonthEnd],
      label: 'Прошлый месяц',
    },
    {
      dateRange: [currentQuarterStart, now],
      label: 'Текущий квартал',
    },
    {
      dateRange: [startFullQuarter, endFullQuarter],
      label: 'Прошлый квартал',
    },
    {
      dateRange: [currentYear, now],
      label: 'Текущий год',
    },
  ];
};

/**
 * Функция получает на вход строку с датой и временем форматом YYYY-MM-DDTHH:MM:SS.458Z
 * отделяет дату от времени, выделяет из даты год, месяц, день
 * и возвращает дату DD MONTH_NAME YYYY HH:MM:SS
 * возвращенное значение используется для получения new Date на конкретную дату
 * @function getDateFromString
 * @param dateS {string} строка с датой и временем
 * @return {string}
 */
export const getDateFromString = (dateS: string): string => {
  const splitDateTime = dateS.split('T');
  const time = splitDateTime[1].slice(0, 8);
  const splitDate = splitDateTime[0];

  return `${splitDate} ${time}`;
};

/**
 *  Функция получает на вход объект со списком дашбордов в формате Record<string, boolean>
 *  преобразует объект в массив ключей объекта, возвращаются только ключи со значением true
 *  @function getDashboardList
 *  @param list {TSelectedDashboardList} Объект со списком дашбордов
 *  @return {string[]}
 */
export const getDashboardList = (list: TSelectedDashboardList): Array<DASHBOARD_KEYS> => {
  return Object.entries(list)
    .map(item => {
      if (item[1]) {
        return item[0];
      }
      return '';
    })
    .filter(item => !!item) as Array<DASHBOARD_KEYS>;
};

/**
 *  Функция формирует корректное описание количества звонков в таблице с телефонией
 *  в зависимости от выбранного фильтра
 *  @function getCallDescription
 *  @param firstFilterValue {string}
 *  @param callsCount {number}
 *  @return {string}
 */
export const getCallDescription = (firstFilterValue: string, callsCount: number) => {
  const callText = numWord(callsCount, ['звонок', 'звонка', 'звонков']);
  const incomingText = numWord(callsCount, ['входящий', 'входящих', 'входящих']);
  const outgoingText = numWord(callsCount, ['исходящий', 'исходящих', 'исходящих']);
  const newText = numWord(callsCount, ['новый', 'новых', 'новых']);

  switch (firstFilterValue) {
    case TELEPHONY_CALL_TYPE_FILTER_NAME.ALL:
      return `${callText}`;
    case TELEPHONY_CALL_TYPE_FILTER_NAME.INBOX:
      return `${incomingText} ${callText}`;
    case TELEPHONY_CALL_TYPE_FILTER_NAME.OUTGOING:
      return `${outgoingText} ${callText}`;
    case TELEPHONY_CALL_TYPE_FILTER_NAME.NEW_INBOX:
      return `${newText} ${incomingText} ${callText}`;
    case TELEPHONY_CALL_TYPE_FILTER_NAME.NEW_OUTGOING:
      return `${newText} ${outgoingText} ${callText}`;
    default:
      return `${callText}`;
  }
};

/**
 * Функция сортирует данные по убыванию во всплывающей легенде в графиках
 * @param a {TooltipItem<'bar' | 'line'>}
 * @param b {TooltipItem<'bar' | 'line'>}
 * @return {number}
 */
export const itemSort = (a: TooltipItem<'bar' | 'line'>, b: TooltipItem<'bar' | 'line'>) => {
  return Number(b.raw) - Number(a.raw);
};

// функция сортирует массив по возрастанию/убыванию, возвращает отсортированный массив,
// используется SuperResTableConnection, SuperResTableMailing, SuperResTableStatistics.
export const simpleSort: TSort = (unsortedData, fieldName, sortIndex) => {
  const newArray = [...unsortedData];
  // eslint-disable-next-line
  const sortHelper = (a: any, b: any, order: 'asc' | 'desc') => {
    if (typeof a[fieldName] === 'number' && typeof b[fieldName] === 'number') {
      return order === 'asc'
        ? (a[fieldName] as number) - (b[fieldName] as number)
        : (b[fieldName] as number) - (a[fieldName] as number);
    }
    return order === 'asc'
      ? String(a[fieldName]).localeCompare(String(b[fieldName]))
      : String(b[fieldName]).localeCompare(String(a[fieldName]));
  };

  if (sortIndex === 'asc') {
    return newArray.sort((a, b) => sortHelper(a, b, 'asc'));
  }
  if (sortIndex === 'desc') {
    return newArray.sort((a, b) => sortHelper(a, b, 'desc'));
  }
  return unsortedData;
};

// export const onRerender = (
//   id: string, // the "id" prop of the Profiler tree that has just committed
//   phase: string, // either "mount" (if the tree just mounted) or "update" (if it re-rendered)
//   actualDuration: number, // time spent rendering the committed update
//   baseDuration: number, // estimated time to render the entire subtree without memoization
//   startTime: number, // when React began rendering this update
//   commitTime: number, // when React committed this update
//   interactions: any,
// ) => {
//   console.log('id', id);
//   console.log('phase', phase);
//   console.log('actualDuration', actualDuration);
//   console.log('baseDuration', baseDuration);
//   console.log('startTime', startTime);
//   console.log('commitTime', commitTime);
//   console.log('interactions', interactions);
// };

/**
 * Функция для получения списка значений выпадающего списка.
 *
 * Функция возвращает объект с ключами 'add' и 'delete', содержащими соответствующие значения для указанного
 * выпадающего списка. Если переданный ключ не соответствует ни одному из предопределенных значений, функция
 * возвращает объект с ключами 'add' и 'delete', содержащими значения 'add' и 'delete' соответственно.
 *
 * @param {THelpKeys} dropDownName - Ключ, определяющий название выпадающего списка.
 * @returns {TSimpleStringObj} - Объект с значениями выпадающего списка.
 */
export const getDropdownList = (dropDownName: THelpKeys): TSimpleStringObj => {
  switch (dropDownName) {
    case 'staff': {
      return {
        add: 'Добавить сотрудника',
      };
    }
    case 'employeesToIgnore': {
      return {
        add: 'Добавить сотрудника',
      };
    }
    case 'services': {
      return {
        add: 'Добавить услугу',
      };
    }
    default: {
      return {
        add: 'add',
        delete: 'delete',
      };
    }
  }
};

/**
 * Функция для получения заголовка второго выпадающего списка.
 *
 * Функция возвращает строку - заголовок второго выпадающего списка в зависимости от переданного ключа. Если
 * переданный ключ не соответствует ни одному из предопределенных значений, функция возвращает строку 'add'.
 *
 * @param {THelpKeys} dropDownName - Ключ, определяющий название выпадающего списка.
 * @returns {string} - Заголовок второго выпадающего списка.
 */
export const getSecondDropdownListTitle = (dropDownName: THelpKeys): string => {
  switch (dropDownName) {
    case 'services': {
      return 'Выберите услугу';
    }
    case 'staff': {
      return 'Выберите сотрудника';
    }
    case 'employeesToIgnore': {
      return 'Выберите сотрудника';
    }
    default: {
      return 'add';
    }
  }
};

/**
 * Сортирует объект по ключам в алфавитном порядке.
 *
 * @param {TSimpleStringObj} obj - Исходный объект, который нужно отсортировать.
 * @returns {TSimpleStringObj} - Отсортированный объект.
 */
export const sortObj = (obj: TSimpleStringObj): TSimpleStringObj => {
  return Object.fromEntries(Object.entries(obj).sort());
};

export const getWeekDaysFromArray = (data: number[] | null): TWeekDays => {
  const weekday = { ...emptyWeekDaysData };

  if (data) {
    data.forEach(key => {
      weekday[key as unknown as keyof TWeekDays] = true;
    });
  }

  return weekday;
};

/**
 * Проверяет и форматирует российский номер телефона.
 * Если номер телефона начинается с '7', он возвращает номер как есть.
 * Если он начинается с '8', первый символ заменяется на '7'.
 * Если он начинается с любой другой цифры, номеру предшествует префикс '7'.
 *
 * @param {string} phoneNumber - Номер телефона для проверки.
 * @returns {string} Проверенный и отформатированный номер телефона.
 */
export const validatePhoneNumber = (phoneNumber: string): string => {
  const isSevenOnFirstChar = phoneNumber.startsWith('7');
  const isEightOnFirstChar = phoneNumber.startsWith('8');

  return isSevenOnFirstChar
    ? phoneNumber
    : isEightOnFirstChar
    ? `7${phoneNumber.slice(1)}`
    : `7${phoneNumber}`;
};

/**
 * Принимает сроку и возвращает строку с большой буквы
 * @param text
 * @return {string} Текст с большой буквы
 */
export const capitaliseString = (text: string): string => {
  const firstLetterCapitalise = text[0].toUpperCase();
  return `${firstLetterCapitalise}${text.slice(1)}`;
};

export const noDataChartPlugin: Plugin<'doughnut' | 'line' | 'bar'> = {
  id: 'noDataChartPlugin',

  afterDraw(chart: ChartJS<'doughnut' | 'line' | 'bar'>) {
    if (chart.data.labels?.length === 0 || chart.data.datasets?.length === 0) {
      const { ctx, width, height } = chart;
      chart.clear();

      ctx.save();
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.font = "24px normal 'Inter, sans-serif'";
      ctx.fillText(NO_CHART_DATA, width / 2, height / 2);
      ctx.restore();
    }
  },
};

/**
 * Функция из созданного объекта Date отнимает 14 дней выделяет выделяет год, месяц, день,
 * объединят все в строку с разделителем "-" добавляет 00:00:00
 * и возвращает ее
 * @function getDateString
 * @param date {Date} объект Date
 * @return {string} строка даны в формате YYYY-MM-DD HH:MM:SS
 */
export const getFourteenDaysBackData = () => {
  const fourteenDaysAgo = new Date();
  fourteenDaysAgo.setDate(fourteenDaysAgo.getDate() - 14);

  const day =
    fourteenDaysAgo.getDate() < 10 ? `0${fourteenDaysAgo.getDate()}` : fourteenDaysAgo.getDate();
  const month =
    fourteenDaysAgo.getMonth() + 1 < 10
      ? `0${fourteenDaysAgo.getMonth() + 1}`
      : fourteenDaysAgo.getMonth() + 1;
  const year = fourteenDaysAgo.getFullYear();

  return {
    fourteenDaysAgo: `${year}-${month}-${day} 00:00:00`,
  };
};

/**
 * Скачивает файл по ссылке не перезагружая страницу
 * @param href {string} путь для скачивания
 * @param isTargetSelf {boolean}
 */
export const downloadByLink = (href: string, isTargetSelf?: boolean) => {
  const link = document.createElement('a');
  link.download = 'file';
  link.target = `${isTargetSelf ? '_self' : '_blank'}`;

  link.href = href;

  link.click();
};

/**
 * Формирует ссылку с query параметром acc_id для использования в navigate и Link
 * @param {string} route Строка пути для формирования ссылки
 * @param {string} accId Id филиала для добавления в query параметр
 */
export const getLinkHref = ({ route, accId }: { route: string; accId: string }): string => {
  return `${route}?${QUERY_PARAMS.ACC_ID}=${accId}`;
};

/**
 * Вычисляет максимальное значение для заданного ключа в массиве данных.
 * @param {TChartData[]} data - Массив данных для расчета.
 * @param {keyof TChartData} key - Ключ для вычисления максимального значения.
 * @returns {number} - Максимальное значение для заданного ключа.
 */
export const calculateMaxValue = (data: TChartData[], key: keyof TChartData): number => {
  if (key !== 'name') return Math.max(...data.map(item => item[key] ?? 0));
  return 0;
};

/**
 * Открывает новую страницу по клику на кнопку
 * @param isTargetBlank {boolean} флаг открытия страницы в новом окне
 * @param href {string} ссылка по которой откроется окно
 */
export const changePageOnClick = ({
  isTargetBlank,
  href,
}: {
  isTargetBlank: boolean;
  href: string;
}) => {
  const target = isTargetBlank ? '_blank' : '_self';
  const newWindow = window.open(href, target, 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

/**
 * Находит элемент в доме и если он найден и это не overlay имитирует клик по нему
 * @param {{ x: number; y: number }}
 * @return {void}
 */
export const clickOnElement = ({
  x,
  y,
  overlayElement,
}: {
  x: number;
  y: number;
  overlayElement?: (EventTarget & Element) | undefined;
}) => {
  const element = document.elementFromPoint(x, y);

  if (element && element !== overlayElement) {
    // eslint-disable-next-line
    // @ts-ignore
    element.click();
  }
};
