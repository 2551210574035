import React, { memo, useCallback, useEffect } from 'react';
import { Icon } from '@blueprintjs/core';
//
import { Button } from '@uikit/Button';
import { Loader } from '@blocks/loader';
import { TOOLTIPS_TEXT } from '@data/dict';
import { getAppData } from '@redux/selectors';
import { AppDrawer } from '@components/appDrawer';
import { ErrorMessage } from '@blocks/errorMessage';
import { ToastsMessages } from '@components/toastsMessages';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { AdminSettingsDrawer } from '@blocks/adminSettingsDrawer';
import { SettingsAdminsTable } from '@components/settingsAdminsTable';
import { setAppDrawerData, setShowAppDrawer } from '@redux/App/slice';
import { getAdminsTableData, saveAdminsTableData } from '@redux/Settings/thunk';
import {
  TSortCallback,
  TOnOpenDrawerHandler,
  TSelectAdminRightHandler,
  TChangeAdminStatusCallback,
} from '@shared/types';
import {
  changeAdminStatus,
  setSelectedRights,
  setSortTypeDataSPAdmins,
  clearAdminsTableStatuses,
} from '@redux/Settings/slice';
import { TAdminsListItem } from '@models/Settings';

type TSettingsAdminProps = {
  /**
   * Флаг повторного запрашивания данных страницы
   * @param {boolean}
   */
  isRetries: boolean;
  /**
   * Id выбранного филиала
   * @param {string}
   */
  selectedFilialAccId: string;
  /**
   * Данные для таблицы админов
   * @param {TAdminsListItem[]}
   */
  adminsTableData: TAdminsListItem[];
  /**
   * Флаг ошибки при получении данных
   * @param {boolean}
   */
  isAdminsTableDataError: boolean;
  /**
   * Флаг состояния процесса получения данных
   * @param {boolean}
   */
  isAdminsTableDataLoading: boolean;
  /**
   * Флаг успешного получения данных для таблицы
   * @param {boolean}
   */
  isAdminsTableDataSuccess: boolean;
  /**
   * Флаг состояния процесса сохранения данных
   * @param {boolean}
   */
  isAdminsTableDataSaving: boolean;
  /**
   * Текст сообщения об ошибке
   * @param {string}
   */
  errorToastMessage: string;
  /**
   * Текст сообщения об успешной операции
   * @param {string}
   */
  successToastMessage: string;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const SettingsAdmin = memo(
  ({
    isRetries,
    adminsTableData,
    errorToastMessage,
    successToastMessage,
    selectedFilialAccId,
    isAdminsTableDataError,
    isAdminsTableDataSaving,
    isAdminsTableDataLoading,
    isAdminsTableDataSuccess,
    className = '',
  }: TSettingsAdminProps) => {
    const dispatch = useAppDispatch();
    const { showAppDrawer } = useAppSelector(getAppData);

    // Запрашивает данные страницы умного расписания и админов повторно при ошибке токена
    useEffect(() => {
      if (isRetries) {
        setTimeout(() => {
          dispatch(getAdminsTableData());
        }, 100);
      }
    }, [dispatch, isRetries]);

    // Запрашивает данные списка админов при первом рендеренге и при смене филиала в меню
    useEffect(() => {
      if (selectedFilialAccId) {
        dispatch(getAdminsTableData());
      }
    }, [dispatch, selectedFilialAccId]);

    // функция сортировки, получает название столбца отправляет в редакс
    const setSortTypeHandler: TSortCallback = (collumName, order) => {
      dispatch(setSortTypeDataSPAdmins({ collumName, order }));
    };

    // функция изменяет статус админа и запрашиваеданные таблицы заново
    const changeAdminStatusHandler: TChangeAdminStatusCallback = data => () => {
      dispatch(changeAdminStatus(data));
    };

    // Обрабатывает выбор прав админа в таблице
    const setSelectedRightHandler: TSelectAdminRightHandler = (selectedRight, name) => event => {
      const { checked } = event.currentTarget;
      dispatch(setSelectedRights({ checked, name, selectedRight }));
    };

    // функция сохраняет изменения на странице
    const postSettingHandler = () => {
      dispatch(saveAdminsTableData(adminsTableData));
    };

    // Скрывает сообщения об ошибках и успехах в запросах
    const clearAllToastMessages = () => {
      dispatch(clearAdminsTableStatuses());
    };

    const onCloseDrawerHandler = () => {
      dispatch(setShowAppDrawer(false));
      dispatch(setAppDrawerData({ title: '', description: '' }));
    };

    const onOpenDrawerHandler: TOnOpenDrawerHandler = useCallback(
      ({ title, description }) =>
        () => {
          dispatch(setShowAppDrawer(true));
          dispatch(setAppDrawerData({ title, description }));
        },
      [dispatch],
    );

    return (
      <div className={className}>
        {!isAdminsTableDataError ? (
          <div>
            <div className='flex items-center mb-2'>
              <h2 className='text-h3Mobile text-black font-medium tracking-[0.004em] leading-8'>
                Админы
              </h2>
              <div className='flex items-center justify-center cursor-pointer w-10'>
                <Icon
                  icon='info-sign'
                  color='#37833B'
                  onClick={onOpenDrawerHandler({
                    title: 'Админы',
                    description: TOOLTIPS_TEXT.admins,
                  })}
                />
              </div>
            </div>
            {isAdminsTableDataSuccess && !isAdminsTableDataLoading ? (
              <SettingsAdminsTable
                sortCallback={setSortTypeHandler}
                setSelectedRightHandler={setSelectedRightHandler}
                changeAdminStatusCallback={changeAdminStatusHandler}
                data={adminsTableData}
                className='w-screen lg:w-full overflow-x-auto dropdown-list-scrollbar pb-4'
              />
            ) : (
              <Loader className='h-[50Vh]' />
            )}
            <Button
              dense
              type='action'
              view='filled'
              color='success'
              text='Сохранить настройки'
              onClick={postSettingHandler}
              loading={isAdminsTableDataSaving}
              className='mt-6 w-fit'
            />
          </div>
        ) : (
          <ErrorMessage />
        )}
        <ToastsMessages
          errorMessage={errorToastMessage}
          successMessage={successToastMessage}
          isShowErrorToast={!!errorToastMessage}
          isShowSuccessToast={!!successToastMessage}
          clearErrorCallback={clearAllToastMessages}
          clearSuccessStatusCallback={clearAllToastMessages}
        />
        <AppDrawer
          showAppDrawer={showAppDrawer}
          onCloseHandler={onCloseDrawerHandler}
          displayElement={<AdminSettingsDrawer />}
        />
      </div>
    );
  },
);
