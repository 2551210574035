export const COMMON_NOTE_FOLDER = 'Все заметки';

export const NEW_NOTE_ID = '0';

export const TAG_PREFIX = '#';

export const MAX_TITLE_LENGTH = 250;

export const MAX_FOLDER_LENGTH = 250;

export const MAX_NOTES_IN_TASK = 3;

export const MAX_TAG_IN_NOTES = 6;

export const MAX_DESCRIPTION_LENGTH = 10000;

export const SUCCESS_TOAST_MESSAGES = {
  FOLDER_CREATED: 'Папка создана.',
  FOLDER_RENAMED: 'Папка переименована',
  FOLDER_DELETED: 'Папка удалена',
  TAG_CREATED: 'Тег создан',
  TAG_DELETED: 'Тег удален',
  NOTE_CREATED: 'Заметка создана',
  NOTE_UPDATED: 'Заметка обновлена',
  NOTE_MOVED: 'Заметка перемещена',
  NOTE_DELETED: 'Заметка удалена',
  NOTE_ATTACHED: 'Заметка добавлена в задачу',
  NOTE_UNPIN_FROM_TASK: 'Заметка удалена из задачи',
};

export const WARNING_TOAST_MESSAGES = {
  FOLDER_NAME_TO_LONG: 'Имя папки превышает 250 символов\n пожалуйста сократите название папки',
  FOLDER_NAME_NOT_UNIQUE: 'Папка с таким именем уже создана',
  TAG_NAME_NOT_UNIQUE: 'Тег с таким именем уже создана',
  TAG_MAX_QUANTITY: 'Тегов не может быть больше 6',
  NOT_UNIQUE_TITLE: 'Заметка с таким заголовком уже существует',
  ALREADY_ADDED: 'Заметка уже добавлена в задачу',
};
