import { z } from 'zod';
import { TASK_TYPE } from '@const/task';
import { NoteItemSchema } from '@redux/noteList/zod';
import { NoteResponseDataSchema } from '@models/Note';

export const TasksListRequestDataSchema = z.object({
  rt: z.boolean(),
  et: z.boolean(),
  ct: z.boolean(),
});

export const MarkSchema = z.object({
  id: z.number().nullable(),
  type: z.string(),
});
export const TaskCardDataSchema = z.object({
  id: z.number(),
  state: z.boolean(),
  title: z.string(),
  type: z.string(),
  description: z.string(),
  mark: MarkSchema.nullable(),
  notes: z.number(),
});

export const TaskOperationRequestDataSchema = z.object({
  id: z.string(),
});

export const TaskDataResponseSchema = z.object({
  id: z.number(),
  active: z.boolean(),
  title: z.string(),
  body: z.string(),
  time: z.string().nullable(),
  mark: z
    .object({
      type: z.nativeEnum(TASK_TYPE),
    })
    .nullable(),
  checklist: z.string().array().nullable(),
  frequency: z.number().nullable(),
  week: z.number().array().nullable(),
  month: z.number().array().nullable(),
  event: z.string().nullable(),
  last_visits: z.number().nullable(),
  days_after_record: z.number().nullable(),
  not_record: z.number().nullable(),
  late_reply: z.number().nullable(),
  services: z.number().array().nullable(),
  staff: z.number().array().nullable(),
  visits: z.number().array().nullable(),
  proof_of_work: z.boolean(),
  notes: NoteResponseDataSchema.array(),
});

export const TaskDataToBackSchema = z.object({
  id: z.number(),
  active: z.boolean(),
  title: z.string(),
  body: z.string(),
  time: z.string().nullable(),
  mark: z
    .object({
      type: z.nativeEnum(TASK_TYPE),
    })
    .nullable(),
  checklist: z.string().array().nullable(),
  frequency: z.number().nullable(),
  week: z.number().array().nullable(),
  month: z.number().array().nullable(),
  event: z.string().nullable(),
  last_visits: z.number().nullable(),
  days_after_record: z.number().nullable(),
  not_record: z.number().nullable(),
  late_reply: z.number().nullable(),
  services: z.number().array().nullable(),
  staff: z.number().array().nullable(),
  visits: z.number().array().nullable(),
  proof_of_work: z.boolean(),
  notes: z.string().array(),
});

export const SwitchedTaskDataSchema = z.object({
  tasks_id: z.number(),
  acc_id: z.string(),
  state: z.boolean(),
});

export const WeekDaysSchema = z.object({
  1: z.boolean(),
  2: z.boolean(),
  3: z.boolean(),
  4: z.boolean(),
  5: z.boolean(),
  6: z.boolean(),
  7: z.boolean(),
});

export const TaskDataSchema = z.object({
  id: z.string(),
  isActive: z.boolean(),
  taskType: z.nativeEnum(TASK_TYPE),
  whenSend: z.string(),
  sendingPeriod: z.string(),
  sendingTime: z.string(),
  frequency: z.number(),
  monthSendDays: z.string(),
  sendOnLastDayOfMonth: z.boolean(),
  daysWithoutVisiting: z.string(),
  selectedEventType: z.string(),
  selectedTelephonyType: z.string(),
  selectedYclientsType: z.string(),
  selectedCrmType: z.string(),
  discountAmount: z.string(),
  clientDelayed: z.string(),
  taskName: z.string(),
  taskDescription: z.string(),
  isAddChecklist: z.boolean(),
  checklistItems: z.string().array(),
  selectedBBIntegrationType: z.string(),
  selectedWorkStatisticsType: z.string(),
  missedCallsPercents: z.string(),
  thresholdWhatsappResponseRate: z.string(),
  thresholdLowEnrolledPercentage: z.string(),
  selectedClientEvent: z.string(),
  notRecord: z.string(),
  lateReply: z.string(),
  daysAfterVisit: z.string(),
  services: z.string().array(),
  staff: z.string().array(),
  visits: z.string().array(),
  report: z.boolean(),
  sendWeekDays: WeekDaysSchema,
  notes: NoteItemSchema.array(),
});

export const TaskInfoSchema = z.object({
  active: z.boolean(),
  id: z.string(),
  name: z.string(),
  type: z.string(),
  isChecklist: z.boolean(),
  description: z.string(),
  link: z.string(),
  whenSend: z.string(),
  notes: z.number(),
});

export type TTaskInfo = z.infer<typeof TaskInfoSchema>;
export type TWeekDays = z.infer<typeof WeekDaysSchema>;
export type TTaskData = z.infer<typeof TaskDataSchema>;
export type TTaskDataToBack = z.infer<typeof TaskDataToBackSchema>;
export type TSwitchedTaskData = z.infer<typeof SwitchedTaskDataSchema>;
export type TTaskDataResponse = z.infer<typeof TaskDataResponseSchema>;
export type TTaskOperationRequestData = z.infer<typeof TaskOperationRequestDataSchema>;
export type TTaskCardData = z.infer<typeof TaskCardDataSchema>;
export type TTasksListRequestData = z.infer<typeof TasksListRequestDataSchema>;
