import React, { memo, useCallback, useEffect, useState } from 'react';
import { Dialog } from '@uikit/Dialog';
import { Button } from '@uikit/Button';
import { NoteCard } from '@components/noteCard';
import { MAX_NOTES_IN_TASK } from '@const/notes';
import { addNotesToTask } from '@redux/Task/slice';
import { getNoteListData } from '@redux/selectors';
import { NotesSearch } from '@components/notesSearch';
import { NoteFolderItem } from '@blocks/noteFolderItem';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { TFolderItem, TNoteItem, TNoteTag } from '@redux/noteList/zod';
import { ReactComponent as FolderIcon } from '@img/notes/folder_close.svg';
import { setActiveFolder, setSearchStringNL, setSelectedTagToSearch } from '@redux/noteList/slice';
import {
  TOnChangeHandler,
  TSetTagInSearchString,
  TSetActiveFolderByIdHandler,
} from '@shared/types';

type TProps = {
  /**
   * Флаг открытия модального окна
   * @param {boolean}
   */
  isOpen: boolean;
  /**
   * Теги доступные для добавления в заметку
   * @param {TNoteTag[]}
   */
  tags: TNoteTag[];
  /**
   * Список добавленных заметок
   * @param {TNoteItem[]}
   */
  notes: TNoteItem[];
  /**
   * Заметки отфильтрованне по поисковой строке и выбранной папке
   * @param {TNoteItem[]}
   */
  filteredNotesList: TNoteItem[];
  /**
   * Список папок
   * @param {TFolderItem[]}
   */
  folderList: TFolderItem[];
  /**
   * Закрывает модалку добавления заметок
   * @param {() => void}
   */
  closeModalHandler: () => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const AddNoteToTaskModal = memo(
  ({
    tags,
    notes,
    isOpen,
    folderList,
    filteredNotesList,
    closeModalHandler,
    className = '',
  }: TProps) => {
    const dispatch = useAppDispatch();
    const [addedNotes, setAddedNotes] = useState<TNoteItem[]>(notes);
    const [pageName, setPageName] = useState<'list' | 'folders'>('list');
    const { searchString, activeFolder } = useAppSelector(getNoteListData);

    // Обновляет стейт при каждом открытии модалки
    useEffect(() => {
      setAddedNotes(notes);
    }, [notes]);

    // Обрабатывает клики по тегам в карточке, добавляет их в поиск
    const setTagInSearchString: TSetTagInSearchString = useCallback(
      tagId => () => {
        dispatch(setSelectedTagToSearch(tagId));
      },
      [dispatch],
    );

    // Обрабатывает изменения в инпуте поисковой строки
    const setSearchHandler: TOnChangeHandler = useCallback(
      event => {
        const { value } = event.currentTarget;

        dispatch(setSearchStringNL({ value }));
      },
      [dispatch],
    );

    // очищает поисковую строку
    const clearSearchStringHandler = useCallback(() => {
      dispatch(setSearchStringNL({ value: '' }));
    }, [dispatch]);

    // устанавливает активную папку
    const setActiveFolderHandler: TSetActiveFolderByIdHandler = useCallback(
      folderId => () => {
        dispatch(setActiveFolder(folderId));
        setPageName('list');
      },
      [dispatch],
    );

    // переключает модалку на "страницу" с папками
    const setFolderSelectPage = useCallback(() => {
      setPageName('folders');
    }, []);

    // переключает модалку на "страницу" со списком заметок
    const setNoteListPage = useCallback(() => {
      setPageName('list');
    }, []);

    // добавляет/удаляет заметку в массив addedNotes
    const selectNoteHandler = useCallback(
      (note: TNoteItem) => () => {
        const isSelected = addedNotes.find(addedNote => addedNote.id === note.id);

        if (isSelected) {
          setAddedNotes(addedNotes.filter(addedNote => addedNote.id !== note.id));
        }
        if (!isSelected && addedNotes.length < MAX_NOTES_IN_TASK) {
          setAddedNotes(prevState => [...prevState, note]);
        }
      },
      [addedNotes],
    );

    // закрывает модалку и удалялет вновь выбранные заметки
    const closeModal = useCallback(() => {
      closeModalHandler();
      setAddedNotes(notes);
    }, [notes, closeModalHandler]);

    // добавляет все заметки из addedNotes в задачу и закрывает модалку
    const addSelectedNotesToTask = useCallback(
      (notesToTask: TNoteItem[]) => () => {
        closeModalHandler();
        dispatch(addNotesToTask(notesToTask));
      },
      [closeModalHandler, dispatch],
    );

    // Кнопки в модалку
    const buttons = (
      <>
        <Button
          dense
          type='action'
          view='outlined'
          color='default'
          text='Отмена'
          onClick={closeModal}
          className='mr-6'
        />
        <Button
          dense
          type='action'
          view='filled'
          color='success'
          text='Прикрепить'
          onClick={addSelectedNotesToTask(addedNotes)}
        />
      </>
    );

    // список карточек заметок
    const notesList = filteredNotesList.map(note => {
      if (note.isPrivate) {
        return null;
      }

      const isSelected = addedNotes.find(addedNote => addedNote.id === note.id);

      return (
        <NoteCard
          key={note.id}
          cardData={note}
          isSelected={!!isSelected}
          searchString={searchString}
          selectNoteCard={selectNoteHandler(note)}
          setTagInSearchString={setTagInSearchString}
        />
      );
    });

    // список папок
    const foldersListToRender = folderList.map(folder => (
      <NoteFolderItem
        key={folder.id}
        folderData={folder}
        isActive={folder.id === activeFolder?.id}
        setActiveFolder={setActiveFolderHandler(folder.id)}
        className='mb-2'
      />
    ));

    // стили меток
    const labelStyle = 'font-medium text-gray3 text-small_text leading-4 tracking-[0.168em]';

    // контент для отображения в модалке
    const content = (
      <div className='flex flex-col'>
        {pageName === 'list' ? (
          <>
            <NotesSearch
              tags={tags}
              status='success'
              selectedTags={[]}
              isSearchByTag={false}
              isTagDeleteMode={false}
              searchString={searchString}
              setSearchStringHandler={setSearchHandler}
              setTagInSearchString={setTagInSearchString}
              clearSearchStringHandler={clearSearchStringHandler}
              className='mb-4'
            />
            <span className={`${labelStyle} mb-2`}>ПОИСК В ПАПКЕ</span>
            <div className='flex flex-col sm:flex-row sm:items-center justify-between mb-4'>
              <div className='bg-varden rounded-lg flex items-center px-4 py-3 grow sm:mr-2 mb-2 sm:mb-0'>
                <FolderIcon fill='#D6981B' className='mr-2' />
                <span className='font-medium text-black text-tooltips leading-5'>
                  {activeFolder.title}
                </span>
              </div>
              <Button
                dense
                type='action'
                text='Выбрать'
                view='outlined'
                color='default'
                onClick={setFolderSelectPage}
                className='w-full sm:w-fit'
              />
            </div>
            <span className={`${labelStyle} mb-2`}>
              {`ВЫБЕРИТЕ ЗАМЕТКИ ${addedNotes.length} из 3`}
            </span>
            <div className='flex flex-wrap sm:grid gap-[0.5rem] sm:grid-cols-2notes lg:grid-cols-3notes overflow-y-auto task-drawer max-h-[45vh] h-fit'>
              {notesList}
            </div>
          </>
        ) : (
          <>
            <Button
              dense
              text='Назад'
              type='default'
              view='outlined'
              color='default'
              icon='chevron-left'
              onClick={setNoteListPage}
              className='mb-6 w-fit'
            />
            <span className={`${labelStyle} mb-2`}>ПАПКИ</span>
            <div>{foldersListToRender}</div>
          </>
        )}
      </div>
    );

    return (
      <div className={className}>
        <Dialog
          type='default'
          view='raised'
          isOpen={isOpen}
          backdropOpacity={40}
          onClose={closeModal}
          rightButton={buttons}
          title='Прикрепить заметку'
          text={content}
          className='max-w-[61.25rem] w-full'
        />
      </div>
    );
  },
);

AddNoteToTaskModal.displayName = 'AddNoteToTaskModal';
