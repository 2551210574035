import { TSimpleStringObj } from '@models/index';

/**
 * Константы для API
 * @module apiConstants
 */

/**
 * Список методов HTTP запросов
 * @enum {string} HTTP_METHODS
 */

export enum HTTP_METHODS {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export enum HTTP_STATUS_CODE {
  ACCESS_DENIED = '403',
  NOT_FOUND = '404',
}

/**
 * Список URL для EP
 * @enum {Record<string, string>} END_POINTS_URL
 */

export enum END_POINTS_URL {
  CHECK_PIN = '/tg/check_pin',
  GET_PIN = '/tg/get_pin',
  GET_TOKEN = '/get_token',
  UPDATE_TOKEN = '/upd_token',
  ACCOUNTS = '/accounts',
  TASK_LIST = '/tasks',
  ONE_TASK = '/tasks_one',
  DELETE_TASK = '/delete_tasks',
  SWITCH_TASK_ACTIVITY = '/switch_tasks',
  SAVE_TASK = '/save_task',
  REPORT_LIST = '/stats',
  ONE_REPORT = '/stats_one',
  SAVE_REPORT = '/save_stats',
  DELETE_REPORT = '/delete_stats',
  SWITCH_REPORT_ACTIVITY = '/switch_stats',
  STATISTICS = '',
  // note
  ALL_NOTE_PAGE_DATA = '/notes_page',
  TAGS = '/tags',
  FOLDERS = '/folders',
  NOTES = '/notes',
  ATTACH_NOTE_TO_TASK = '/attach_note',
  UNPIN_NOTE_FROM_TASK = '/notes/unpin',
  //
  BLACKLIST = '/settings/blacklist',
  SETTINGS_GENERAL = '/settings/general',
  SETTINGS_TELEPHONY = '/settings/telephony',
  SETTINGS_TELEPHONY_TEST = '/settings/telephony/test',
  SETTINGS_MASTERS = '/settings/masters',
  SETTINGS_NOTIFICATIONS = '/settings/notifications',
  SETTINGS_SET_ORDER = '/settings/set_order',
  STAFF = '/staff',
  PAY = '/pay',
  PAY_LINK = '/pay_link',
  PAY_INVOICE = '/invoice',
  PAYER_DATA = '/payer_data',
  DASHBOARD = '/dashboard',
  DASHBOARD_SAVE = '/dashboard/save',
  TELEPHONY_TABLE = '/telephony',
  CRM_TG = '/settings/admins',
  // Analytics
  ADMINS = '/analytics/admins',
}

export enum CATEGORY_NAME {
  SERVICE = 'services',
  STAFF = 'staff',
  EMPLOYEES_TO_IGNORE = 'employeesToIgnore',
}

/**
 * Список имен EP для получения списка категорий
 * @const CATEGORIES_EP_NAME
 */
export const CATEGORIES_EP_NAME: TSimpleStringObj = {
  [CATEGORY_NAME.SERVICE]: '/services',
  [CATEGORY_NAME.STAFF]: '/staff',
  [CATEGORY_NAME.EMPLOYEES_TO_IGNORE]: '/staff',
};

/**
 * Список query параметров
 * @enum QUERY_PARAMS
 */
export enum QUERY_PARAMS {
  ID = 'id',
  USER_ID = 'user_id',
  STATE = 'state',
  ACC_ID = 'acc_id',
  EVENT_TEMPLATE = 'em',
  TIME_TEMPLATE = 'tm',
  SEND_OUT_ACCOUNT = 'sa',
  TEMPLATE_ID = 'template_id',
  NEW_TEMPLATE = 'new_template',
  STEP_NUMBER = 'step_number',
  SWITCH = 'switch',
  SENDOUT_ID = 'sendout_id',
  TEMPL_ID = 'temp_id',
  GET_EXEL_FILE = 'excel',
  METHOD = 'method',
  PROMO_CODE = 'promo_code',
  PHONE = 'phone',
  BRANCH_NAME = 'branch_name',
  BRANCH = 'branch',
  COMMENT = 'comment',
  AUTO_RESPONSE_WORD = 'word',
  AUTO_RESPONSE_RESPONSE = 'reply',
  AUTO_RESPONSE_CAPTION = 'caption',
  AUTO_RESPONSE_ACTIVE = 'active',
  TABLE_NAME = 'table_name',
  TAG_ID = 'tag_id',
  FOLDER_ID = 'folder_id',
  NOTE_ID = 'note_id',
  TASK_ID = 'task_id',
  STATS_ID = 'stats_id',
}
