import React, { useCallback, useMemo, useRef, useState } from 'react';
//
import { Button } from '@uikit/Button';
import { CONFIG } from '@const/config';
import { Dialog } from '@uikit/Dialog';
import { Loader } from '@blocks/loader';
import { getAppData } from '@redux/selectors';
import { TReportData } from '@models/Report';
import { EVENT_KEY_NAMES } from '@const/common';
import { saveReport } from '@redux/Report/thunk';
import { AppDrawer } from '@components/appDrawer';
import { ErrorMessage } from '@blocks/errorMessage';
import { ToggleSwitch } from '@blocks/toggleSwitch';
import { ReportWarning } from '@blocks/reportWarning';
import { RecipientBlock } from '@components/recipientBlock';
import { ReportPageHeader } from '@blocks/reportPageHeader';
import { ToastsMessages } from '@components/toastsMessages';
import { WhenSendReport } from '@components/whenSendReport';
import { TaskDescription } from '@components/taskDescription';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { PreviewComponent } from '@components/previewComponent';
import { CalculateDataPeriod } from '@blocks/calculateDataPeriod';
import { ReportAdditionalInfo } from '@blocks/reportAdditionalInfo';
import { setAppDrawerData, setShowAppDrawer } from '@redux/App/slice';
import { ReportPageNavLinkBlock } from '@blocks/reportPageNavLinkBlock';
import { DATA_CALCULATION_PERIOD, REPORT_DATA_KEY_NAMES, REPORT_SEND_PERIOD } from '@const/report';
import {
  TRPNameList,
  TClickHandler,
  TOnChangeHandler,
  TAddBalloonOnBlur,
  TDeleteBalloonSet,
  TKeyboardClickEvent,
  TOnChangeHandlerInputTextarea,
} from '@shared/types';
import {
  onChange,
  wrapText,
  setActive,
  setSendDate,
  addBalloonRP,
  clearErrorsRP,
  deleteBalloonRP,
  addKeyInTextarea,
  setReportSendingTime,
  setNewBalloonValueRP,
  clearNewBalloonValueRP,
  setAbandonedTextareaData,
  setDataCalculatingPeriod,
  setEveryMonthSendingPeriod,
  setEveryWeekSendingPeriod,
  setSelectedReportSendPeriod,
} from '@redux/Report/slice';

interface IReportTemplateProps {
  /**
   *Id выбранного филиала
   * @param {string}
   */
  accId: string;
  /**
   * Флаг наличия ошибки при работе с API
   * @param {boolean}
   */
  isError: boolean;
  /**
   * Флаг состояния процесса сохранения
   * @param {boolean}
   */
  isSaving: boolean;
  /**
   * Флаг загрузки данных для страницы
   * @param {boolean}
   */
  isLoading: boolean;
  /**
   * Значения инпута для ввода нового номера для отправки отчетов в whatsapp
   * @param {string}
   */
  /**
   * Флаг ошибки при сохранении данных
   * @param {boolean}
   */
  isSavingError: boolean;
  /**
   * Флаг успешного сохранения данных
   * @param {string}
   */
  isSavingSuccess: boolean;
  /**
   * Значения инпута для ввода нового номера для отправки отчетов в whatsapp
   * @param {string}
   */
  whatsappRecipientsNewValue: string;
  /**
   * Значения инпута для ввода нового номера для отправки отчетов в telegram
   * @param {string}
   */
  telegramRecipientsNewValue: string;
  /**
   * Текст сообщения об ошибке
   * @param {string}
   */
  errorToastMessage: string;
  /**
   * Текст сообщения об успешной операции
   * @param {string}
   */
  successToastMessage: string;
  /**
   * Данные отчета
   * @param {string}
   */
  data: TReportData;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const ReportTemplate = ({
  accId,
  isError,
  isSaving,
  isLoading,
  isSavingError,
  isSavingSuccess,
  errorToastMessage,
  successToastMessage,
  telegramRecipientsNewValue,
  whatsappRecipientsNewValue,
  data,
  className = '',
}: IReportTemplateProps) => {
  const dispatch = useAppDispatch();
  const { showAppDrawer } = useAppSelector(getAppData);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const modalToggle = useCallback(() => setIsOpen(prevState => !prevState), []);

  // Реф на textarea используется для возвращения фокуса
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  // Деструктуризация data
  const {
    id,
    isActive,
    reportName,
    sendingTime,
    sendingDate,
    reportDescription,
    whatsappRecipients,
    telegramRecipients,
    selectedReportSendPeriod,
    selectedDataCalculatingPeriod,
    selectedEveryWeekSendingPeriod,
    selectedEveryMonthSendingPeriod,
  } = data;

  // Обработчик изменений в инпутах
  const onChangeHandler: TOnChangeHandlerInputTextarea = useCallback(
    event => {
      const { name, value } = event.currentTarget;
      dispatch(
        onChange({
          name: name as
            | REPORT_DATA_KEY_NAMES.REPORT_NAME
            | REPORT_DATA_KEY_NAMES.REPORT_DESCRIPTION,
          value,
        }),
      );
    },
    [dispatch],
  );

  // const onChangeCheckboxHandler: TOnChangeHandler = useCallback(
  //   event => {
  //     const { name, checked } = event.currentTarget;
  //
  //     dispatch(
  //       onChangeCheckbox({ name: name as REPORT_DATA_KEY_NAMES.SEND_TO_ALL_STAFF, checked }),
  //     );
  //   },
  //   [dispatch],
  // );

  // Обработчик изменения состояния активности отчета
  const reportActivityChangeHandler: TOnChangeHandler = useCallback(
    event => {
      const { name, checked } = event.currentTarget;
      dispatch(setActive({ name: name as REPORT_DATA_KEY_NAMES.IS_ACTIVE, checked }));
    },
    [dispatch],
  );

  // Обработчик изменений периода отправки отчета
  const setSendPeriod = useCallback(
    (sendPeriod: string) => () => {
      dispatch(setSelectedReportSendPeriod({ sendPeriod }));
    },
    [dispatch],
  );

  // Обработчик изменения времени отправки
  const setSendingTime = useCallback(
    (time: string) => {
      dispatch(setReportSendingTime({ time }));
    },
    [dispatch],
  );

  // Обработчик изменения даты отправки
  const setDataCalculatingPeriodHandler = useCallback(
    (period: string) => () => {
      dispatch(setDataCalculatingPeriod({ period }));
    },
    [dispatch],
  );

  // Обработчик изменения дня отправки недельного отчета
  const setEveryWeekSendingPeriodHandler = useCallback(
    (period: string) => () => {
      dispatch(setEveryWeekSendingPeriod({ period }));
    },
    [dispatch],
  );

  // Обработчик изменения дня отправки отчета за месяц
  const setEveryMonthSendingPeriodHandler = useCallback(
    (period: string) => () => {
      dispatch(setEveryMonthSendingPeriod({ period }));
    },
    [dispatch],
  );

  // Записывает введенные данные в store
  const newBalloonValueOnChangeHandler: TOnChangeHandler = useCallback(
    event => {
      const { name, value } = event.currentTarget;
      const keyName = name as TRPNameList;
      dispatch(setNewBalloonValueRP({ value, name: keyName }));
    },
    [dispatch],
  );

  // Очищает введенные данные в store
  const clearNewBalloonValueHandler = useCallback(
    (name: string) => {
      dispatch(clearNewBalloonValueRP({ name }));
    },
    [dispatch],
  );

  // Удаляет 'шарик' по его значению
  const deleteBalloon: TDeleteBalloonSet = useCallback(
    (value, keyName) => () => {
      const tKeyName = keyName as TRPNameList;
      dispatch(deleteBalloonRP({ keyName: tKeyName, value }));
    },
    [dispatch],
  );

  // Добавляет новый по событию Blur шарик и очищает инпут
  const addBalloonOnBlur: TAddBalloonOnBlur = useCallback(
    event => {
      const { name, value } = event.currentTarget;
      const keyName = name as TRPNameList;
      dispatch(addBalloonRP({ keyName, value }));
    },
    [dispatch],
  );

  // Добавляет новый по событию KeyUp шарик и очищает инпут
  const addBalloonByEnterKey: TKeyboardClickEvent = useCallback(
    event => {
      if (event.key === EVENT_KEY_NAMES.ENTER) {
        addBalloonOnBlur(event);
      }
    },
    [addBalloonOnBlur],
  );

  // Callback передается в компонент <TemplateAdditionalInfo />
  // добавляет спец ключ в текс 'выбранной' textarea
  const setKeyInTextarea = useCallback(
    (keyName: string, group: string) => {
      dispatch(addKeyInTextarea({ keyName, group }));
      textareaRef.current?.focus();
    },
    [dispatch],
  );

  // Оборачивает выделенный текст в выбранные спецсимволы
  const wrapSelectedTextInTextarea: TClickHandler = useCallback(
    event => {
      const { innerText } = event.currentTarget; // получаем выбранный спецсимвол вместе с примером текстом в функцию для формирования новой строки передается первый символ
      dispatch(wrapText({ innerText }));
      textareaRef.current?.focus();
    },
    [dispatch],
  );

  // Обработчик устанавливает значения при потери фокуса с textarea
  const onTextareaBlurHandler = useCallback(
    (event: React.FocusEvent<HTMLTextAreaElement>): void => {
      const {
        selectionStart: startOfSelectText,
        selectionEnd: endOfSelectText,
        name,
      } = event.currentTarget;
      dispatch(
        setAbandonedTextareaData({
          name,
          selectionStart: startOfSelectText,
          selectionEnd: endOfSelectText,
        }),
      );
    },
    [dispatch],
  );

  // Обработчик устанавливает дату отправки отчета
  const setSendDayHandler = useCallback(
    (dateString: string): void => {
      dispatch(setSendDate({ dateString }));
    },
    [dispatch],
  );

  // Проверяет время отправки отчета за сегодня, если пользователь желает получить отчет раньше 18:00
  // выводится предупреждающая надпись о получении не полного отчета
  const isTooEarlyToSendReport = useCallback(
    (reportSendPeriod: string, dataCalculatingPeriod: string, time: string) => {
      return (
        reportSendPeriod === REPORT_SEND_PERIOD.EVERY_DAY &&
        dataCalculatingPeriod === DATA_CALCULATION_PERIOD.TODAY &&
        Number(time.slice(0, 2)) < 18
      );
    },
    [],
  );

  // Обработчик сохранения отчета
  const saveReportDataHandler = useCallback(() => {
    dispatch(saveReport(data));
  }, [data, dispatch]);

  // Проверяем на активные ошибки
  const isHasError = useMemo(() => isError || isSavingError, [isError, isSavingError]);

  // Проверяем на заполнение обязательные поля в задаче
  const isReportNoValidToSave = useMemo(
    () =>
      !reportDescription || reportDescription.length > Number(CONFIG.REPORT_DESCRIPTION_MAX_LENGTH),
    [reportDescription],
  );

  const memoizedShowPreviewButton = useMemo(
    () => (
      <Button
        dense
        type='action'
        color='default'
        view='outlined'
        onClick={modalToggle}
        text='Показать превью'
        className='mt-6 w-[11rem]'
      />
    ),
    [modalToggle],
  );

  const memoizedDialog = useMemo(
    () => (
      <Dialog
        type='default'
        view='raised'
        color='default'
        isOpen={isOpen}
        backdropOpacity={40}
        title='Превью отчёта'
        onClose={modalToggle}
        text={isOpen ? <PreviewComponent description={reportDescription} /> : <span />}
        className='whitespace-pre-line break-words report-preview'
      />
    ),
    [isOpen, modalToggle, reportDescription],
  );

  const hideSaveErrorHandler = useCallback(() => {
    dispatch(clearErrorsRP());
  }, [dispatch]);

  const onCloseDrawerHandler = () => {
    dispatch(setShowAppDrawer(false));
    dispatch(setAppDrawerData({ title: '', description: '' }));
  };

  return (
    <div
      className={`block ${!isError && !isLoading ? `xl:grid xl:grid-cols-[1fr_32%] gap-1.5` : ''}`}>
      <div className={`flex flex-col h-full w-full ${className}`}>
        {isError ? (
          <ErrorMessage />
        ) : (
          <>
            <ReportPageNavLinkBlock id={id} accId={accId} isLoading={isLoading} className='mb-10' />
            <ReportPageHeader className='mb-4' id={id} isLoading={isLoading} />
            {isLoading && !isError ? (
              <Loader className='grow' />
            ) : (
              <>
                <div className='flex items-center mb-10'>
                  <ToggleSwitch
                    name={REPORT_DATA_KEY_NAMES.IS_ACTIVE}
                    checked={isActive}
                    onChangeHandler={reportActivityChangeHandler}
                    className='mr-4'
                  />
                  <div>
                    <p className='font-medium text-blackText m-0'>Действующий шаблон</p>
                  </div>
                </div>
                <p className='mb-6 text-gray2 tracking-[0.0275em]'>
                  Обязательные поля отмечены звёздочкой
                  <span className='text-[red] ml-[0.2em]'>*</span>
                </p>
                <div className='flex flex-col mb-10'>
                  <TaskDescription
                    type='report'
                    disabled={false}
                    taskName={reportName}
                    descriptionIsRequired
                    nameIsRequired={false}
                    taskDescription={reportDescription}
                    setTaskNameHandler={onChangeHandler}
                    templateTextareaPlaceholder='Введите текст'
                    setTaskDescriptionHandler={onChangeHandler}
                    onTextareaBlurHandler={onTextareaBlurHandler}
                    templateInputPlaceholder='Придумайте название отчета'
                    templateInputName={REPORT_DATA_KEY_NAMES.REPORT_NAME}
                    templateDescription={REPORT_DATA_KEY_NAMES.REPORT_DESCRIPTION}
                    textareaValueMaxLength={Number(CONFIG.REPORT_DESCRIPTION_MAX_LENGTH)}
                    className='mb-4 max-w-[47rem]'
                  />
                  <ReportAdditionalInfo
                    forMobile
                    setKeyInTextarea={setKeyInTextarea}
                    wrapSelectedTextInTextarea={wrapSelectedTextInTextarea}
                    className='xl:hidden w-full max-w-[47rem]'
                  />
                  {memoizedShowPreviewButton}
                </div>
                <CalculateDataPeriod
                  selectedDataCalculatingPeriod={selectedDataCalculatingPeriod}
                  setDataCalculatingPeriodHandler={setDataCalculatingPeriodHandler}
                  className='mb-6'
                />
                <WhenSendReport
                  sendingTime={sendingTime}
                  sendingDate={sendingDate}
                  tooltipVariant='whenSendReports'
                  setSendPeriod={setSendPeriod}
                  setSendingTime={setSendingTime}
                  setSendingDate={setSendDayHandler}
                  selectedReportSendPeriod={selectedReportSendPeriod}
                  selectedDataCalculatingPeriod={selectedDataCalculatingPeriod}
                  selectedEveryWeekSendingPeriod={selectedEveryWeekSendingPeriod}
                  selectedEveryMonthSendingPeriod={selectedEveryMonthSendingPeriod}
                  setEveryWeekSendingPeriodHandler={setEveryWeekSendingPeriodHandler}
                  setEveryMonthSendingPeriodHandler={setEveryMonthSendingPeriodHandler}
                />
                {isTooEarlyToSendReport(
                  selectedDataCalculatingPeriod,
                  selectedReportSendPeriod,
                  sendingTime,
                ) ? (
                  <ReportWarning
                    period={selectedDataCalculatingPeriod}
                    className='mt-6 w-full max-w-[47rem]'
                  />
                ) : null}
                <RecipientBlock
                  deleteBalloon={deleteBalloon}
                  addBalloonOnBlur={addBalloonOnBlur}
                  telegramRecipients={telegramRecipients}
                  whatsappRecipients={whatsappRecipients}
                  addBalloonByEnterKey={addBalloonByEnterKey}
                  telegramRecipientsNewValue={telegramRecipientsNewValue}
                  whatsappRecipientsNewValue={whatsappRecipientsNewValue}
                  clearNewBalloonValueHandler={clearNewBalloonValueHandler}
                  newBalloonValueOnChangeHandler={newBalloonValueOnChangeHandler}
                  className='mt-10 mb-4'
                />
                {/* <SettingsCheckbox
                  isShowInfoIcon
                  checked={sendToAllStaff}
                  onChangeHandler={onChangeCheckboxHandler}
                  htmlFor={REPORT_DATA_KEY_NAMES.SEND_TO_ALL_STAFF}
                  variant={REPORT_DATA_KEY_NAMES.SEND_TO_ALL_STAFF}
                  className='my-4'
                /> */}
                <Button
                  dense
                  type='action'
                  view='filled'
                  color='success'
                  icon='small-tick'
                  text='Сохранить шаблон'
                  loading={isLoading || isSaving}
                  onClick={saveReportDataHandler}
                  disabled={isHasError || isReportNoValidToSave}
                  className='mt-10 w-[13.5rem]'
                />
              </>
            )}
            {memoizedDialog}
            <ToastsMessages
              errorMessage={errorToastMessage}
              isShowErrorToast={isSavingError}
              successMessage={successToastMessage}
              isShowSuccessToast={isSavingSuccess}
              clearErrorCallback={hideSaveErrorHandler}
            />
          </>
        )}
      </div>
      {!isError && !isLoading ? (
        <ReportAdditionalInfo
          forMobile={false}
          setKeyInTextarea={setKeyInTextarea}
          wrapSelectedTextInTextarea={wrapSelectedTextInTextarea}
          className='hidden xl:block mt-[13.75rem] w-full max-w-[26.5rem]'
        />
      ) : null}
      <AppDrawer showAppDrawer={showAppDrawer} onCloseHandler={onCloseDrawerHandler} />
    </div>
  );
};
