import React, { useCallback, useState } from 'react';
//
import { useAppDispatch } from '@hooks/index';
import { NoteFolderItem } from '@blocks/noteFolderItem';
import { setNewFolderName } from '@redux/noteList/slice';
import { TSetActiveFolderByIdHandler } from '@shared/types';
import { TFolderItem, TNoteListStatuses } from '@redux/noteList/zod';
import { NoteCreateFolderModal } from '@components/noteCreateFolderModal';
import { ReactComponent as NewFolderIcon } from '@img/notes/folder_new.svg';

type TModalNames = 'create' | '';

type TProps = {
  /**
   * Статус запросов на страницы
   * @param {TNoteListStatuses}
   */
  status: TNoteListStatuses;
  /**
   * Флаг отображения блока с папками
   * @param {boolean}
   */
  isShowFolders: boolean;
  /**
   * Значение инпута в модалку создания новой папки
   * @param {string}
   */
  newFolderName: string;
  /**
   * Массив с данными по паккам с заметками
   * @param {TFolderItem[]}
   */
  folders: TFolderItem[];
  /**
   * Данные выбранной папки
   * @param {TFolderItem | null | undefined}
   */
  activeFolder: TFolderItem | null | undefined;
  /**
   * Создает новую папку
   * @param {closeModalCallback: () => void}
   */
  crateFolderHandler: (closeModalCallback: () => void) => () => void;
  /**
   * Устанавливает активную папку с заметками
   * @param {TSetActiveFolderByIdHandler}
   */
  setActiveFolder: TSetActiveFolderByIdHandler;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const NotesFolders = ({
  status,
  folders,
  activeFolder,
  isShowFolders,
  newFolderName,
  setActiveFolder,
  crateFolderHandler,
  className = '',
}: TProps) => {
  const dispatch = useAppDispatch();
  const [modalName, setModalName] = useState<TModalNames>('');

  const showRenameModal = useCallback(() => {
    setModalName('create');
  }, []);

  const hideAllModal = useCallback(() => {
    setModalName('');
  }, []);

  const setNewFolderNameHandler = useCallback(
    (folderName: string) => {
      dispatch(setNewFolderName(folderName));
    },
    [dispatch],
  );

  const foldersToRender = folders.map(folder => (
    <NoteFolderItem
      key={folder.id}
      folderData={folder}
      isActive={folder.id === activeFolder?.id}
      setActiveFolder={setActiveFolder(folder.id)}
      className='mb-2'
    />
  ));

  const disable = status !== 'success';

  return (
    <div className={`flex flex-col ${className}`}>
      {isShowFolders ? (
        <>
          <span className='font-medium text-gray3 text-small_text leading-4 tracking-[0.168em] mb-2'>
            ПАПКИ
          </span>
          <div className={`flex flex-col ${disable ? 'pointer-events-none' : ''}`}>
            {foldersToRender}
          </div>
          <button
            disabled={disable}
            onClick={showRenameModal}
            className='bg-white border border-gray4 rounded-lg flex flex-col items-center justify-center min-h-[5.125rem] w-full'>
            <NewFolderIcon />
            <span className='text-darkGray leading-6'>Создать папку</span>
          </button>
          {modalName === 'create' ? (
            <NoteCreateFolderModal
              status={status}
              newFolderName={newFolderName}
              isOpen={modalName === 'create'}
              closeModalHandler={hideAllModal}
              setNewFolderName={setNewFolderNameHandler}
              createNewFolder={crateFolderHandler(() => setModalName(''))}
            />
          ) : null}
        </>
      ) : null}
    </div>
  );
};
