import React, { memo, useCallback, useRef, useState } from 'react';
import { Icon } from '@blueprintjs/core';
//
import { Menu } from '@uikit/Menu';
import { Chips } from '@uikit/Chips';
import { Button } from '@uikit/Button';
import { Drawer } from '@uikit/Drawer';
import { TTaskInfo } from '@models/Tasks';
import { getFilialName } from '@helpers/index';
import { MenuItem } from '@uikit/Menu/MenuItem';
import { TOnChangeHandler } from '@shared/types';
import { TextInput } from '@uikit/Inputs/DefaultInput';
import { useWindowWidth } from '@hooks/useWindowWidth';
import { useCloseByOutSideClick } from '@hooks/useCloseByOutSideClick';

type TProps = {
  /**
   * Флаг открытия drawer
   * @param {boolean}
   */
  isOpen: boolean;
  /**
   * Флаг режима редактирования заметки
   * @param {boolean}
   */
  isNoteEdit: boolean;
  /**
   * Добавляет задачц в список
   * @param {(task: TTaskInfo) => void}
   */
  addTask: (task: TTaskInfo) => void;
  /**
   * Удаляет добавленную задачу из списка
   * @param {(taskId: string) => () => void}
   */
  taskDeleteHandler: (taskId: string) => () => void;
  /**
   * Обработчик ввода значений в поисковом инпуте
   * @param {TOnChangeHandler}
   */
  setSearchStringHandler: TOnChangeHandler;
  /**
   * Очbишает строку поиска
   * @param {() => void}
   */
  clearSearchString: () => void;
  /**
   * Строка поиска в инпут
   * @param {string}
   */
  searchString: string;
  /**
   * Список добавленных задач
   * @param {TTaskInfo[]}
   */
  addedTasks: TTaskInfo[];
  /**
   * Данные о задачах
   * @param {TTaskInfo[]}
   */
  taskInfo: TTaskInfo[];
  /**
   * Закрывает drawer
   * @param {() => void}
   */
  closeDrawerHandler: () => void;
  /**
   * Прикрепляет заметку к задаче
   * @param {() => void}
   */
  attachNoteToTask: () => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const NoteAddTaskDropdown = memo(
  ({
    isOpen,
    addTask,
    taskInfo,
    isNoteEdit,
    addedTasks,
    searchString,
    attachNoteToTask,
    clearSearchString,
    taskDeleteHandler,
    closeDrawerHandler,
    setSearchStringHandler,
    className = '',
  }: TProps) => {
    const ref = useRef(null);
    const width = useWindowWidth();

    const [isShowDropdown, setIsShowDropdown] = useState(false);

    const showDropdownHandler = useCallback(() => {
      if (isNoteEdit && taskInfo.length) {
        setIsShowDropdown(true);
      }
    }, [isNoteEdit, taskInfo.length]);

    const hideDropdownHandler = useCallback(() => {
      setIsShowDropdown(false);
    }, []);

    const addTaskHandler = useCallback(
      (task: TTaskInfo) => () => {
        addTask(task);
        setIsShowDropdown(false);
      },
      [addTask],
    );

    useCloseByOutSideClick({ ref, closeElementCallback: hideDropdownHandler });

    const itemToRender = taskInfo.map(item => {
      return (
        <MenuItem
          key={item.id}
          onClick={item.notes < 3 ? addTaskHandler(item) : undefined}
          text={
            <div
              className={`flex justify-between ${
                item.notes > 2 ? 'cursor-not-allowed text-gray3' : ''
              }`}>
              <span className='mr-4 overflow-hidden w-full'>{item.name}</span>
              <div className='flex flex-col text-gray3 text-small_text'>
                <span>Заметки</span>
                <span>{item.notes ? `${item.notes} из 3` : 'нет'}</span>
              </div>
            </div>
          }
        />
      );
    });

    const notFoundItem = <MenuItem key='id' text={<span>Ничего не найдено</span>} />;

    const menuToRender = (
      <Menu
        view='raised'
        className={`dropdown-list-scrollbar ${
          itemToRender.length < 2 ? `one_item_ul` : ''
        } max-h-[25vh] overflow-y-auto`}>
        {itemToRender.length ? itemToRender : [notFoundItem]}
      </Menu>
    );

    const taskRender = addedTasks.map(({ id, name }) => {
      const title = `${getFilialName(name)} - ${id}`;
      return (
        <Chips
          round
          noClick
          key={id}
          tag={title}
          type='dense'
          color='default'
          disabled={false}
          withTick={false}
          withNumber={null}
          withAvatar={null}
          removable={isNoteEdit}
          onRemove={taskDeleteHandler(id)}
          className='mr-2 mb-2'
        />
      );
    });

    const button = (
      <Button
        fill
        dense
        type='action'
        view='filled'
        color='success'
        icon='paperclip'
        disabled={!isNoteEdit}
        text='Прикрепить к задаче'
        onClick={attachNoteToTask}
      />
    );

    const component = (
      <>
        <div ref={ref} className='flex flex-col px-0.5 mb-4'>
          <div className='flex flex-wrap sm:flex-nowrap items-center'>
            {width < 1 ? (
              <span className='font-normal text-[1rem] text-gray2 leading-6 tracking-[0.022em] mb-2'>
                Вы можете прикрепить заметку к выбранным задачам.
              </span>
            ) : null}
            <div className={`relative mb-2 w-full '!sm:max-w-[29rem]'`}>
              <span className='font-normal text-[1rem] text-stormGray leading-6 tracking-[0.0275em] mb-0.5'>
                Выберите задачу
              </span>
              <TextInput
                fill
                type='text'
                clearButton
                error={false}
                view='outlined'
                color='default'
                success={false}
                placeholder='Найти'
                value={searchString}
                onFocus={showDropdownHandler}
                onChange={setSearchStringHandler}
                clearSearchString={clearSearchString}
                disabled={!isNoteEdit || addedTasks.length}
                className={`${isShowDropdown ? '!z-[60]' : ''} ${
                  searchString ? '' : 'chevron_in_after'
                }`}
              />
              <div
                className={`${
                  isShowDropdown ? 'block' : 'hidden'
                } absolute top-[5rem] w-full !z-[61]`}>
                {menuToRender}
              </div>
            </div>
          </div>
          {isShowDropdown ? (
            <div className='h-[25vh]' />
          ) : (
            <div className='flex items-center flex-wrap w-full'>{taskRender}</div>
          )}
        </div>
        {width > 641 ? (
          <Button
            fill
            dense
            type='action'
            view='filled'
            color='success'
            icon='paperclip'
            text='Прикрепить к задаче'
            onClick={attachNoteToTask}
            disabled={!isNoteEdit || !addedTasks.length}
          />
        ) : null}
      </>
    );

    return (
      <div className={`flex flex-col ${className}`}>
        {width < 641 ? (
          <Drawer size='100%' position='right' isOpen={isOpen} style={{ borderRadius: 0 }}>
            <div className='flex flex-col grow p-1'>
              <div className='flex items-center justify-between mb-4'>
                <span className='font-bold text-myrtle text-[1.25rem] leading-8 tracking-[0.0075em]'>
                  Прикрепление к задаче
                </span>
                <button
                  aria-label='close'
                  onClick={closeDrawerHandler}
                  className='bg-none border-none flex items-start justify-end h-9 w-9'>
                  <Icon icon='cross' size={16} color='#4A4B57' className='m-[0.3125rem]' />
                </button>
              </div>
              <div className='flex flex-col grow justify-between pb-4'>
                {component}
                <div className='flex items-center justify-end'>{button}</div>
              </div>
            </div>
          </Drawer>
        ) : (
          component
        )}
      </div>
    );
  },
);

NoteAddTaskDropdown.displayName = 'NoteAddTaskDropdown';
