import React, { memo, useCallback, useEffect, useState } from 'react';
//
import { Chips } from '@uikit/Chips';
import { Icon } from '@blueprintjs/core';
import { TTaskInfo } from '@models/Tasks';
import { useAppDispatch } from '@hooks/index';
import { TOnChangeHandler } from '@shared/types';
import { TAttachedTemplates, TNonePageStatus } from '@redux/note/zod';
import { useWindowWidth } from '@hooks/useWindowWidth';
import { setTaskListToAttachNote } from '@redux/note/slice';
import { NoteAddTaskDropdown } from '@components/noteAddTaskDropdown';
import { ReactComponent as WarningIcon } from '@img/notes/warning_icon.svg';
import { attachNoteToTaskThunk, deleteNoteFromTaskThunk } from '@redux/note/thunk';
import { ReactComponent as AddNoteToTaskIcon } from '@img/notes/add_note_to_task.svg';

type TProps = {
  /**
   * Флаг видимости заметки всем
   * @param {boolean}
   */
  isPrivate: boolean;
  /**
   * Флаг режима редактирования заметки
   * @param {boolean}
   */
  isNoteEdit: boolean;
  /**
   * Данные о задачах
   * @param {TTaskInfo[]}
   */
  taskInfo: TTaskInfo[];
  /**
   * Список задач для прикрепления заметки
   * @param {TTaskInfo[]}
   */
  taskListToAttachNote: TTaskInfo[];
  /**
   * Идентификатор заметки
   * @param {string}
   */
  noteId: string;
  /**
   * Список задач к котрым прикреплена заметка
   * @param {TAttachedTemplates[]}
   */
  attachedTemplates: TAttachedTemplates[];
  /**
   * Статус запросов на страницы
   * @param {TNonePageStatus}
   */
  status: TNonePageStatus;
  /**
   * Флаг отображения блока для мобильных устройств
   * @param {boolean}
   */
  isMobile?: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const NoteAddToTask = memo(
  ({
    noteId,
    status,
    isMobile,
    taskInfo,
    isPrivate,
    isNoteEdit,
    attachedTemplates,
    taskListToAttachNote,
    className = '',
  }: TProps) => {
    const width = useWindowWidth();
    const dispatch = useAppDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const [searchString, setSearchString] = useState('');
    const [filteredTask, setFilteredTask] = useState<TTaskInfo[]>(taskInfo);

    useEffect(() => {
      const tasksWithoutAdded = taskInfo.filter(task => {
        return !attachedTemplates.find(addedTask => addedTask.id === task.id);
      });

      if (searchString) {
        setFilteredTask(
          tasksWithoutAdded.filter(
            task => task.id.includes(searchString) || task.name.includes(searchString),
          ),
        );
      } else {
        setFilteredTask(tasksWithoutAdded);
      }
    }, [attachedTemplates, searchString, taskInfo]);

    const setSearchStringHandler: TOnChangeHandler = useCallback(event => {
      const { value } = event.currentTarget;
      setSearchString(value);
    }, []);

    const addToTaskToggle = useCallback(() => {
      setIsOpen(prevState => !prevState);
    }, []);

    const taskDeleteHandler = useCallback(
      (taskId: string) => () => {
        const newList = taskListToAttachNote.filter(task => task.id !== taskId);
        dispatch(setTaskListToAttachNote(newList));
      },
      [taskListToAttachNote, dispatch],
    );

    const deleteNoteFromTask = useCallback(
      (id: string) => () => {
        if (status === 'success') {
          dispatch(deleteNoteFromTaskThunk({ noteId, templateId: id }));
        }
      },
      [noteId, status, dispatch],
    );

    const addTaskHandler = useCallback(
      (task: TTaskInfo) => {
        dispatch(setTaskListToAttachNote([...taskListToAttachNote, task]));
      },
      [taskListToAttachNote, dispatch],
    );

    const attachNoteToTaskHandler = useCallback(
      (tasks: TTaskInfo[]) => () => {
        dispatch(
          attachNoteToTaskThunk({
            noteId,
            taskInfo: { id: tasks[0].id, title: tasks[0].name },
            count: tasks[0].notes,
          }),
        );
      },
      [noteId, dispatch],
    );

    const attachedTasksToRender = attachedTemplates.map(({ id, title }, index) => {
      const isLastElement = index === attachedTemplates.length - 1;
      return (
        <Chips
          key={id}
          type='dense'
          color='default'
          disabled={false}
          withTick={false}
          withNumber={null}
          withAvatar={null}
          tag={`${title} ${id}`}
          removable={isNoteEdit}
          onRemove={deleteNoteFromTask(id)}
          className={isLastElement ? '' : 'mb-2'}
        />
      );
    });

    return (
      <div className={className}>
        <div className='border border-quartz rounded-lg flex flex-col px-4 py-3 items-stretch h-fit w-full'>
          <AddNoteToTaskIcon fill='#4A4B57' className='hidden lg:flex mb-5' />
          <div
            onClick={addToTaskToggle}
            className={`flex items-start justify-between ${isMobile ? 'cursor-pointer' : ''} ${
              isOpen ? 'mb-2' : 'mb-0'
            }`}>
            <span className='font-bold text-blackText text-[1.25rem] leading-8 tracking-[0.0075em]'>
              Прикрепление к задаче
            </span>
            {isMobile ? (
              <button aria-label='toggle' className='bg-none border-none'>
                <Icon
                  size={24}
                  icon='caret-up'
                  className={`${isOpen ? '' : 'rotate-180'} translate-all ease-out duration-300`}
                />
              </button>
            ) : null}
          </div>
          {isPrivate ? (
            <div className='bg-varden rounded-lg px-4 py-3'>
              <div className='flex'>
                <div className='flex flex-col items-center justify-center mr-3'>
                  <WarningIcon />
                </div>
                <span className='font-medium text-goldenBrown leading-6 tracking-[0.022em]'>
                  Заметку со статусом «видна только мне» нельзя прикрепить к задачам, так как она
                  видна только вам
                </span>
              </div>
            </div>
          ) : (
            <div
              className={`flex flex-col ${
                isOpen || width > 641 ? 'max-h-max' : 'max-h-0'
              } overflow-hidden`}>
              <span className='text-gray2 leading-6 tracking-[0.022em]'>
                Заметка прикреплена к задачам:
              </span>
              {attachedTasksToRender.length ? (
                <div className='flex flex-col mt-6'>{attachedTasksToRender}</div>
              ) : null}
              <NoteAddTaskDropdown
                isOpen={isOpen}
                taskInfo={filteredTask}
                isNoteEdit={isNoteEdit}
                addTask={addTaskHandler}
                searchString={searchString}
                addedTasks={taskListToAttachNote}
                closeDrawerHandler={addToTaskToggle}
                taskDeleteHandler={taskDeleteHandler}
                setSearchStringHandler={setSearchStringHandler}
                clearSearchString={() => setSearchString('')}
                attachNoteToTask={attachNoteToTaskHandler(taskListToAttachNote)}
                className='mt-6'
              />
            </div>
          )}
        </div>
      </div>
    );
  },
);

NoteAddToTask.displayName = 'NoteAddToTask';
