import { z } from 'zod';
import { InNoteFolderItemSchema, InNoteTagItemSchema } from '@models/Note';

const StatusesSchema = z.union([
  z.literal('error'),
  z.literal('loading'),
  z.literal('success'),
  z.literal('tagLoader'),
  z.literal('tagDeleting'),
  z.literal('folderDelete'),
  z.literal('folderCreate'),
  z.literal('folderRename'),
  z.literal('folderLoader'),
  z.literal('allNotesLoader'),
]);

export type TNoteListStatuses = z.infer<typeof StatusesSchema>;

export const FolderItemSchema = z.object({
  title: z.string(),
  id: z.string(),
  companyId: z.number(),
  updatedAt: z.string().nullish(),
  createdAt: z.string(),
  numberOfNotes: z.number(),
});

export const NoteTagSchema = z.object({
  title: z.string(),
  id: z.string(),
  companyId: z.number(),
  updatedAt: z.string().nullish(),
  createdAt: z.string(),
});

export type TNoteTag = z.infer<typeof NoteTagSchema>;

export const NoteItemSchema = z.object({
  id: z.string(),
  text: z.string(),
  header: z.string(),
  isPrivate: z.boolean(),
  tags: InNoteTagItemSchema.array(),
  folder: InNoteFolderItemSchema.nullish(),
});

const NoteItemWthOutFullTagsSchema = z.object({
  id: z.string(),
  text: z.string(),
  header: z.string(),
  isPrivate: z.boolean(),
  tagsId: z.string().array(),
  folder: InNoteFolderItemSchema.nullish(),
});

const InitialStateSchema = z.object({
  isError: z.boolean(),
  isLoading: z.boolean(),
  isAccessDenied: z.boolean(),
  errorMessage: z.string(),
  successMessage: z.string(),
  isShowFolderList: z.boolean(),
  isTagDeleteMode: z.boolean(),
  status: StatusesSchema,
  isShowNotes: z.boolean(),
  searchString: z.string(),
  newFolderName: z.string(),
  tags: NoteTagSchema.array(),
  activeFolder: FolderItemSchema,
  isShowSearchByTag: z.boolean(),
  notesList: NoteItemSchema.array(),
  selectedTags: NoteTagSchema.array(),
  folderList: FolderItemSchema.array(),
  isShowCreateFolderModal: z.boolean(),
  filteredNotesList: NoteItemSchema.array(),
  filteredByFolderNotesList: NoteItemSchema.array(),
});

export type TFolderItem = z.infer<typeof FolderItemSchema>;

export type TNoteItem = z.infer<typeof NoteItemSchema>;
export type TNoteItemWthOutFullTags = z.infer<typeof NoteItemWthOutFullTagsSchema>;

export type TInitialState = z.infer<typeof InitialStateSchema>;
