import { createSlice } from '@reduxjs/toolkit';
import { initialState } from '@redux/noteList/state';
import {
  deleteTagThunk,
  createFolderThunk,
  deleteFolderThunk,
  renameFolderThunk,
  getAllFoldersThunk,
  getNoteListPageData,
} from '@redux/noteList/thunk';
import {
  reducers,
  createFolderReducer,
  deleteFolderReducer,
  renameFolderReducer,
  deleteTagByIdReducer,
  getAllFoldersReducer,
  getNoteListPageDataReducer,
} from '@redux/noteList/reducers';

export const noteListSlice = createSlice({
  name: 'noteList',
  initialState,
  reducers,
  extraReducers: builder => {
    builder.addCase(getNoteListPageData.pending, getNoteListPageDataReducer.pending);
    builder.addCase(getNoteListPageData.fulfilled, getNoteListPageDataReducer.fulfilled);
    builder.addCase(getNoteListPageData.rejected, getNoteListPageDataReducer.rejected);
    builder.addCase(getAllFoldersThunk.pending, getAllFoldersReducer.pending);
    builder.addCase(getAllFoldersThunk.fulfilled, getAllFoldersReducer.fulfilled);
    builder.addCase(getAllFoldersThunk.rejected, getAllFoldersReducer.rejected);
    builder.addCase(createFolderThunk.pending, createFolderReducer.pending);
    builder.addCase(createFolderThunk.fulfilled, createFolderReducer.fulfilled);
    builder.addCase(createFolderThunk.rejected, createFolderReducer.rejected);
    builder.addCase(renameFolderThunk.pending, renameFolderReducer.pending);
    builder.addCase(renameFolderThunk.fulfilled, renameFolderReducer.fulfilled);
    builder.addCase(renameFolderThunk.rejected, renameFolderReducer.rejected);
    builder.addCase(deleteFolderThunk.pending, deleteFolderReducer.pending);
    builder.addCase(deleteFolderThunk.fulfilled, deleteFolderReducer.fulfilled);
    builder.addCase(deleteFolderThunk.rejected, deleteFolderReducer.rejected);
    builder.addCase(deleteTagThunk.pending, deleteTagByIdReducer.pending);
    builder.addCase(deleteTagThunk.fulfilled, deleteTagByIdReducer.fulfilled);
    builder.addCase(deleteTagThunk.rejected, deleteTagByIdReducer.rejected);
  },
});

export const noteListReducer = noteListSlice.reducer;

export const {
  addNewTag,
  setFoldersList,
  setIsShowNotes,
  setActiveFolder,
  hideSearchByTag,
  setIsShowFolders,
  setNewFolderName,
  setTagDeleteMode,
  showErrorMessage,
  setSearchStringNL,
  setShowSearchByTag,
  clearErrorMessageNL,
  clearSuccessMessageNL,
  openCreateFolderModal,
  closeCreateFolderModal,
  setSelectedTagToSearch,
  removeSelectedToSearchTag,
  showNoteDeleteSuccessMessage,
} = noteListSlice.actions;
