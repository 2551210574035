import React, { memo, useCallback, useMemo, useRef, useState } from 'react';
import { Icon } from '@blueprintjs/core';
//
import { Menu } from '@uikit/Menu';
import { Button } from '@uikit/Button';
import { MenuItem } from '@uikit/Menu/MenuItem';
import { TFolderItem } from '@redux/noteList/zod';
import { TNonePageStatus } from '@redux/note/zod';
import { useWindowWidth } from '@hooks/useWindowWidth';
import { TSetActiveFolderHandler } from '@shared/types';
import { NoteDeleteModal } from '@components/noteDeleteModal';
import { useCloseByOutSideClick } from '@hooks/useCloseByOutSideClick';
import { NoteMoveToFolderModal } from '@components/noteMoveToFolderModal';
import { ReactComponent as MoveToFolderIcon } from '@img/notes/move_to_folder.svg';

type TModalNames = 'first' | 'move' | 'delete' | '';

type TProps = {
  /**
   * Флаг создания новой заметки
   * @param {boolean}
   */
  isNewNote: boolean;
  /**
   * Статус запросов на страницы
   * @param {TNonePageStatus}
   */
  status: TNonePageStatus;
  /**
   * Активная папка
   * @param {TFolderItem}
   */
  activeFolder: TFolderItem;
  /**
   * Перемещает заметку в указанную папку
   * @param {TSetActiveFolderHandler}
   */
  moveNoteToFolder: TSetActiveFolderHandler;
  /**
   * Список папок дла дропдауна
   * @param {TFolderItem[]}
   */
  folderList: TFolderItem[];
  /**
   * Удаляет заметку по id
   * @param {(closeModalCallback: () => void) => void}
   */
  deleteNote: (closeModalCallback: () => void) => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const NoteControlButtons = memo(
  ({
    status,
    isNewNote,
    folderList,
    deleteNote,
    activeFolder,
    moveNoteToFolder,
    className = '',
  }: TProps) => {
    const width = useWindowWidth();
    const ref = useRef(null);
    const [modalName, setModalName] = useState<TModalNames>('');

    const hideAllModal = useCallback(() => {
      setModalName('');
    }, []);

    const hideFirstModal = useCallback(() => {
      if (modalName === 'first') {
        setModalName('');
      }
    }, [modalName]);

    useCloseByOutSideClick({ ref, closeElementCallback: hideFirstModal });

    const showFirstModal = useCallback(() => {
      setModalName('first');
    }, []);

    const showMoveToFolderModal = useCallback(() => {
      setModalName('move');
    }, []);

    const showDeleteNoteModal = useCallback(() => {
      setModalName('delete');
    }, []);

    const moveNoteToFolderHandler: TSetActiveFolderHandler = useCallback(
      newActiveFolder => {
        hideAllModal();
        moveNoteToFolder(newActiveFolder);
      },
      [hideAllModal, moveNoteToFolder],
    );

    const deleteNodeHandler = useCallback(() => {
      deleteNote(hideAllModal);
    }, [deleteNote, hideAllModal]);

    const menuItemData = useMemo(
      () => [
        {
          name: 'Переместить в папку',
          textColor: '#19191D',
          icon: <MoveToFolderIcon fill='#4A4B57' />,
          handler: () => {
            showMoveToFolderModal();
          },
        },
        {
          name: 'Удалить заметку',
          textColor: '#9C2B23',
          icon: <Icon icon='trash' size={20} color='#C8372D' />,
          handler: () => {
            showDeleteNoteModal();
          },
        },
      ],
      [showMoveToFolderModal, showDeleteNoteModal],
    );

    const menuItemsToRender = menuItemData.map(({ icon, handler, textColor, name }) => (
      <MenuItem
        key={name}
        text={
          <span className={`flex font-medium text-[${textColor}] leading-6 tracking-[0.022em]`}>
            {icon}
            <span className='flex ml-2'>{name}</span>
          </span>
        }
        onClick={handler}
        className='flex items-center h-10'
      />
    ));

    return (
      <div className={className}>
        {width > 641 ? (
          <div className='flex flex-wrap items-center justify-end'>
            <Button
              dense
              type='action'
              view='outlined'
              color='default'
              text='Переместить в папку'
              onClick={showMoveToFolderModal}
              icon={<MoveToFolderIcon fill='#4A4B57' />}
            />
            <Button
              dense
              icon='trash'
              type='action'
              color='danger'
              view='outlined'
              disabled={isNewNote}
              text='Удалить заметку'
              onClick={showDeleteNoteModal}
              className='ml-4'
            />
          </div>
        ) : (
          <div ref={ref} className='relative'>
            <button
              aria-label='more button'
              onClick={showFirstModal}
              className='border-none bg-none flex items-center justify-center h-10 w-10'>
              <Icon icon='more' size={20} color='#4A4B57' className='rotate-90' />
            </button>
            {modalName === 'first' ? (
              <Menu
                className={`absolute top-[3.25rem] right-0 ${
                  menuItemsToRender.length === 1 ? `one_item_ul` : ''
                } z-[51]`}
                view='raised'>
                {menuItemsToRender}
              </Menu>
            ) : null}
          </div>
        )}
        <NoteMoveToFolderModal
          status={status}
          folderList={folderList}
          activeFolder={activeFolder}
          isOpen={modalName === 'move'}
          closeModalHandler={hideAllModal}
          moveToFolder={moveNoteToFolderHandler}
        />
        <NoteDeleteModal
          cancelButtonText='Отмена'
          confirmButtonText='Удалить'
          isOpen={modalName === 'delete'}
          closeModalHandler={hideAllModal}
          disableButton={status !== 'success'}
          callbackToConfirmButton={deleteNodeHandler}
          showLoaderInButton={status === 'noteDeleting'}
          dialogTitle='Вы уверены, что хотите удалить заметку?'
          dialogDescription='Она также будет удалена из всех прикрепленных задачи'
        />
      </div>
    );
  },
);

NoteControlButtons.displayName = 'NoteControlButtons';
