import React, { memo, useCallback, useMemo, useRef, useState } from 'react';
import { Icon } from '@blueprintjs/core';
//
import { Menu } from '@uikit/Menu';
import { useAppDispatch } from '@hooks/index';
import { MenuItem } from '@uikit/Menu/MenuItem';
import { showErrorMessage } from '@redux/noteList/slice';
import { NoteDeleteModal } from '@components/noteDeleteModal';
import { TFolderItem, TNoteListStatuses } from '@redux/noteList/zod';
import { NoteListRenameModal } from '@components/noteListRenameModal';
import { useCloseByOutSideClick } from '@hooks/useCloseByOutSideClick';
import { MAX_FOLDER_LENGTH, WARNING_TOAST_MESSAGES } from '@const/notes';
import { ReactComponent as FolderIcon } from '@img/notes/folder_close.svg';
import { deleteFolderThunk, renameFolderThunk } from '@redux/noteList/thunk';

type TModalNames = 'first' | 'rename' | 'delete' | '';

type TProps = {
  /**
   * Статус запросов на страницы
   * @param {TNoteListStatuses}
   */
  status: TNoteListStatuses;
  /**
   * Строка поиска для выделения найденного текста
   * @param {string}
   */
  searchString: string;
  /**
   * Данные выбранной папки
   * @param {TFolderItem | null | undefined}
   */
  activeFolder: TFolderItem | null | undefined;
  /**
   * Название папки которую нельзя удалять
   * @param {string}
   */
  notDeletableFolderName: string;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const NoteListHeader = memo(
  ({ status, activeFolder, notDeletableFolderName, searchString, className = '' }: TProps) => {
    const ref = useRef(null);
    const dispatch = useAppDispatch();
    const [modalName, setModalName] = useState<TModalNames>('');

    const hideAllModal = useCallback(() => {
      setModalName('');
    }, []);

    const hideFirstModal = useCallback(() => {
      if (modalName === 'first') {
        setModalName('');
      }
    }, [modalName]);

    useCloseByOutSideClick({ ref, closeElementCallback: hideFirstModal });

    const showFirstModal = useCallback(() => {
      setModalName('first');
    }, []);

    const showRenameModal = useCallback(() => {
      setModalName('rename');
    }, []);

    const showDeleteModal = useCallback(() => {
      setModalName('delete');
    }, []);

    const saveNewFolderName = useCallback(
      (folderName: string) => {
        if (folderName.length > MAX_FOLDER_LENGTH) {
          dispatch(showErrorMessage(WARNING_TOAST_MESSAGES.FOLDER_NAME_TO_LONG));
        } else {
          dispatch(
            renameFolderThunk({
              title: folderName,
              folderId: activeFolder?.id || '',
              modalCloseCallback: () => setModalName(''),
            }),
          );
        }
      },
      [activeFolder?.id, dispatch],
    );

    const deleteFolderHandler = useCallback(() => {
      dispatch(
        deleteFolderThunk({
          folderId: activeFolder?.id || '',
          modalCloseCallback: () => setModalName(''),
        }),
      );
    }, [activeFolder?.id, dispatch]);

    const menuItemData = useMemo(
      () => [
        {
          name: 'Переименовать',
          textColor: '#19191D',
          icon: <Icon icon='edit' size={20} color='#4A4B57' />,
          handler: () => {
            showRenameModal();
          },
        },
        {
          name: 'Удалить папку',
          textColor: '#9C2B23',
          icon: <Icon icon='trash' size={20} color='#C8372D' />,
          handler: () => {
            showDeleteModal();
          },
        },
      ],
      [showDeleteModal, showRenameModal],
    );

    const menuItemsToRender = menuItemData.map(({ icon, handler, textColor, name }) => (
      <MenuItem
        key={name}
        text={
          <span className={`flex font-medium text-[${textColor}] leading-6 tracking-[0.022em]`}>
            {icon}
            <span className='flex ml-2'>{name}</span>
          </span>
        }
        onClick={handler}
        className='flex items-center h-10'
      />
    ));

    return (
      <div className={className}>
        {!searchString ? (
          <div className='flex items-center justify-between min-h-[2.5rem]'>
            <div className='flex items-center'>
              <FolderIcon fill='#43A047' className='mr-2 min-w-[1.25rem]' />
              <span className='font-bold text-black text-h4 leading-6 tracking-[0.0075em]'>
                {activeFolder?.title}
              </span>
            </div>
            <div ref={ref} className='relative'>
              {activeFolder?.title !== notDeletableFolderName ? (
                <button
                  aria-label='more button'
                  onClick={showFirstModal}
                  className='border-none bg-none flex items-center justify-center h-10 w-10'>
                  <Icon icon='more' size={20} color='#4A4B57' className='rotate-90' />
                </button>
              ) : null}
              {modalName === 'first' ? (
                <Menu
                  className={`absolute top-[3.25rem] right-0 ${
                    menuItemsToRender.length === 1 ? `one_item_ul` : ''
                  } z-[51]`}
                  view='raised'>
                  {menuItemsToRender}
                </Menu>
              ) : null}
            </div>
          </div>
        ) : (
          <div className='flex items-center min-h-[2.5rem]'>
            <span className='font-bold text-black text-h4 leading-6 tracking-[0.0075em] mr-2'>
              Результаты поиска в папке
            </span>
            <div className='flex items-center'>
              <FolderIcon fill='#43A047' className='mr-2' />
              <span className='font-bold text-black text-h4 leading-6 tracking-[0.0075em]'>
                {activeFolder?.title}
              </span>
            </div>
          </div>
        )}
        {modalName === 'delete' ? (
          <NoteDeleteModal
            cancelButtonText='Отмена'
            confirmButtonText='Удалить'
            isOpen={modalName === 'delete'}
            closeModalHandler={hideAllModal}
            disableButton={status !== 'success'}
            callbackToConfirmButton={deleteFolderHandler}
            showLoaderInButton={status === 'folderDelete'}
            dialogTitle={`Вы уверены, что хотите удалить папку «${activeFolder?.title}»?`}
            dialogDescription={`Все заметки, находящиеся в папке\n будут перемещены в папку «Все заметки».`}
          />
        ) : null}
        {modalName === 'rename' ? (
          <NoteListRenameModal
            status={status}
            isOpen={modalName === 'rename'}
            closeModalHandler={hideAllModal}
            saveFolderName={saveNewFolderName}
            activeFolderName={activeFolder?.title || ''}
          />
        ) : null}
      </div>
    );
  },
);

NoteListHeader.displayName = 'NoteListHeader';
