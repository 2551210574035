import { TInitialState } from '@redux/note/zod';
import { newNote } from '@redux/note/mockData';

export const initialState: TInitialState = {
  isError: false,
  isLoading: true,
  errorMessage: '',
  successMessage: '',
  isAccessDenied: false,
  isShowNoSaveExitModal: false,
  taskListToAttachNote: [],
  newTag: '',
  allTags: [],
  isNoteEdit: false,
  status: 'success',
  data: newNote,
  oldData: newNote,
  attachedTemplates: [],
};
