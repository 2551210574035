import { createSlice } from '@reduxjs/toolkit';
//
import { initialState } from '@redux/Task/state';
import {
  saveTask,
  deleteTask,
  switchTask,
  getTaskData,
  getTasksList,
  saveTasksCopy,
  getListOfServicesTS,
} from '@redux/Task/thunks';
import {
  reducers,
  saveTaskReducer,
  switchTaskReducer,
  deleteTaskReducer,
  getTaskDataReducer,
  getTasksListReducer,
  saveTasksCopyReducer,
  getListOfServicesTSReducer,
} from '@redux/Task/reducers';

export const slice = createSlice({
  name: 'task',
  initialState,
  reducers,
  extraReducers: builder => {
    builder.addCase(getTaskData.pending, getTaskDataReducer.pending);
    builder.addCase(getTaskData.fulfilled, getTaskDataReducer.fulfilled);
    builder.addCase(getTaskData.rejected, getTaskDataReducer.rejected);
    builder.addCase(saveTask.pending, saveTaskReducer.pending);
    builder.addCase(saveTask.fulfilled, saveTaskReducer.fulfilled);
    builder.addCase(saveTask.rejected, saveTaskReducer.rejected);
    builder.addCase(getListOfServicesTS.fulfilled, getListOfServicesTSReducer.fulfilled);
    builder.addCase(getListOfServicesTS.rejected, getListOfServicesTSReducer.rejected);
    // builder.addCase(getListOfStaffTS.fulfilled, getListOfStaffTSReducer.fulfilled);
    // builder.addCase(getListOfStaffTS.rejected, getListOfStaffTSReducer.rejected);
    builder.addCase(saveTasksCopy.pending, saveTasksCopyReducer.pending);
    builder.addCase(saveTasksCopy.fulfilled, saveTasksCopyReducer.fulfilled);
    builder.addCase(saveTasksCopy.rejected, saveTasksCopyReducer.rejected);
    builder.addCase(getTasksList.pending, getTasksListReducer.pending);
    builder.addCase(getTasksList.fulfilled, getTasksListReducer.fulfilled);
    builder.addCase(getTasksList.rejected, getTasksListReducer.rejected);
    builder.addCase(switchTask.pending, switchTaskReducer.pending);
    builder.addCase(switchTask.fulfilled, switchTaskReducer.fulfilled);
    builder.addCase(switchTask.rejected, switchTaskReducer.rejected);
    builder.addCase(deleteTask.pending, deleteTaskReducer.pending);
    builder.addCase(deleteTask.fulfilled, deleteTaskReducer.fulfilled);
    builder.addCase(deleteTask.rejected, deleteTaskReducer.rejected);
  },
});

export const taskReducer = slice.reducer;
export const {
  setData,
  setTaskData,
  setTaskType,
  setTaskName,
  setWhenSend,
  addTPBalloon,
  clearErrorsTP,
  setTaskStatus,
  addNotesToTask,
  clearSuccessTP,
  setIsRefreshTl,
  setSendWeekDay,
  deleteAddedNote,
  setMonthSendDay,
  setFilialsToCopy,
  deleteTPBalloon,
  setClientDelayed,
  setSendingPeriod,
  setVisitQuantity,
  sortMonthSendDay,
  setChecklistItems,
  setDaysAfterVisit,
  setDiscountAmount,
  setIsAddChecklist,
  setNotRecordLimit,
  setLateReplyLimit,
  setSortedTasksList,
  setTaskSendingTime,
  setTaskDescription,
  addKeyInTextareaTP,
  clearFilialsToCopy,
  updateNotesCounter,
  setSelectedCrmType,
  setTaskIdInProgress,
  deleteChecklistItem,
  clearTaskListErrors,
  setSelectedEventType,
  setNewTPBalloonValue,
  setTaskSendFrequency,
  editChecklistItemName,
  clearNewTPBalloonValue,
  setDaysWithoutVisiting,
  setMissedCallsPercents,
  setSelectedClientEvent,
  setSendOnLastDayOfMonth,
  setSelectedYclientsType,
  setNewChecklistItemName,
  setSelectedBBIntegration,
  setSelectedTelephonyType,
  clearTaskListDeleteSuccess,
  setAbandonedTextareaDataTP,
  setSelectedWorkStatisticsType,
  setThresholdWhatsappResponseRate,
  setThresholdLowEnrolledPercentage,
} = slice.actions;
