import { PayloadAction } from '@reduxjs/toolkit';
import { COMMON_TOAST_MESSAGES } from '@const/common';
import { COMMON_FOLDER } from '@redux/noteList/mockData';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { SUCCESS_TOAST_MESSAGES, WARNING_TOAST_MESSAGES } from '@const/notes';
import { TNoteListPageDataToRedux, TRenameFolderData } from '@redux/noteList/types';
import { TNoteItem, TFolderItem, TInitialState, TNoteTag } from '@redux/noteList/zod';
import { REJECT_RESPONSE_2_KEY, REJECT_RESPONSE_KEY, TRejectActionPayload } from '@api/types';

export const reducers = {
  setFoldersList(state: TInitialState, action: PayloadAction<TFolderItem[]>) {
    state.folderList = action.payload;
  },
  addNewTag(state: TInitialState, action: PayloadAction<TNoteTag>) {
    state.tags.push(action.payload);
  },
  setActiveFolder(state: TInitialState, action: PayloadAction<string>) {
    const newActiveFolder = state.folderList.find(folder => folder.id === action.payload);

    if (newActiveFolder && newActiveFolder.id !== COMMON_FOLDER.id) {
      const notes = state.notesList.filter(note => newActiveFolder.id === note.folder?.id);

      state.activeFolder = newActiveFolder;
      state.filteredNotesList = notes;
      state.filteredByFolderNotesList = notes;
    } else {
      state.activeFolder = COMMON_FOLDER;
      state.filteredNotesList = state.notesList;
      state.filteredByFolderNotesList = state.notesList;
    }
  },
  setNewFolderName(state: TInitialState, action: PayloadAction<string>) {
    const newFolderName = action.payload;
    const isNoUnique = state.folderList.find(folder => folder.title === newFolderName);

    if (isNoUnique) {
      state.errorMessage = WARNING_TOAST_MESSAGES.FOLDER_NAME_NOT_UNIQUE;
      state.status = 'error';
    } else {
      state.errorMessage = '';
      state.status = 'success';
    }

    state.newFolderName = action.payload;
  },
  setSearchStringNL(state: TInitialState, action: PayloadAction<{ value: string }>) {
    const searchString = action.payload.value.toLowerCase();

    if (searchString.startsWith('#')) {
      state.filteredNotesList = state.filteredByFolderNotesList.filter(note =>
        note.tags.some(tag => tag.title.toLowerCase().includes(searchString)),
      );
    } else if (searchString) {
      state.filteredNotesList = state.filteredByFolderNotesList.filter(({ text, header }) => {
        return (
          text.toLowerCase().includes(searchString) || header.toLowerCase().includes(searchString)
        );
      });
    } else {
      state.filteredNotesList = state.filteredByFolderNotesList;
    }

    state.searchString = action.payload.value;
  },
  setSelectedTagToSearch(state: TInitialState, action: PayloadAction<string>) {
    const tagsToSearch = [...state.selectedTags];
    const selectedTag = state.tags.find(tag => tag.id === action.payload);
    const isTgAlreadyAdded = state.selectedTags.find(tag => tag.id === action.payload);
    let notesToFilter: TNoteItem[];

    if (tagsToSearch.length || state.searchString) {
      notesToFilter = state.filteredNotesList;
    } else {
      notesToFilter = state.notesList;
    }

    if (selectedTag && !isTgAlreadyAdded) {
      state.filteredNotesList = notesToFilter.filter(note =>
        note.tags.some(tag => tag.id.toLowerCase().includes(selectedTag.id)),
      );

      state.selectedTags.push(selectedTag);
      state.isShowSearchByTag = true;
    }
  },
  removeSelectedToSearchTag(state: TInitialState, action: PayloadAction<string>) {
    const filteredTags = state.selectedTags.filter(tag => tag.id !== action.payload);
    let notesList = [...state.notesList];

    filteredTags.forEach(filteredTag => {
      notesList = notesList.filter(note => note.tags.find(tag => tag.id === filteredTag.id));
    });

    state.filteredNotesList = notesList;

    state.selectedTags = filteredTags;
  },
  setIsShowFolders(state: TInitialState, action: PayloadAction<boolean>) {
    state.isShowFolderList = action.payload;
  },
  setIsShowNotes(state: TInitialState, action: PayloadAction<boolean>) {
    state.isShowNotes = action.payload;
  },
  openCreateFolderModal(state: TInitialState) {
    state.isShowCreateFolderModal = true;
  },
  closeCreateFolderModal(state: TInitialState) {
    state.isShowCreateFolderModal = false;
  },
  setShowSearchByTag(state: TInitialState, action: PayloadAction<boolean>) {
    state.isShowSearchByTag = action.payload;
  },
  setTagDeleteMode(state: TInitialState, action: PayloadAction<boolean>) {
    const newState = action.payload;

    if (newState) {
      state.selectedTags = [];
      state.filteredNotesList = state.filteredByFolderNotesList;
    }

    state.isTagDeleteMode = newState;
  },
  hideSearchByTag(state: TInitialState) {
    if (!state.searchString && !state.selectedTags.length) {
      state.isTagDeleteMode = false;
      state.isShowSearchByTag = false;
    }
  },
  showNoteDeleteSuccessMessage(state: TInitialState) {
    state.successMessage = SUCCESS_TOAST_MESSAGES.NOTE_DELETED;
  },
  showErrorMessage(state: TInitialState, action: PayloadAction<string>) {
    state.errorMessage = action.payload;
  },
  clearErrorMessageNL(state: TInitialState) {
    state.errorMessage = '';
  },
  clearSuccessMessageNL(state: TInitialState) {
    state.successMessage = '';
  },
};

const rejected = (state: TInitialState, action: PayloadAction<TRejectActionPayload>) => {
  if (action.payload) {
    if (REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
      const { detail } = action.payload;
      if (detail === REQUEST_TEXT_ERROR_STATUS.USER_NOT_FOUND) {
        state.isAccessDenied = true;
      } else if (detail !== REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        state.isError = true;
        state.isLoading = false;
      }
    } else if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
      const { status } = action.payload;
      if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
        state.isAccessDenied = true;
      } else {
        state.isError = true;
      }
      state.isLoading = false;
    }
  } else {
    state.isError = true;
    state.isLoading = false;
  }
};

const rejectedNotesListAction = (
  state: TInitialState,
  action: PayloadAction<TRejectActionPayload>,
) => {
  if (action.payload) {
    if (REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
      const { detail } = action.payload;
      if (detail !== REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        state.errorMessage = COMMON_TOAST_MESSAGES.COMMON_ERROR_MESSAGE;
      }
      if (detail === REQUEST_TEXT_ERROR_STATUS.USER_NOT_FOUND) {
        state.isAccessDenied = true;
      }
    } else if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
      const { status } = action.payload;
      if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
        state.isAccessDenied = true;
      } else {
        state.errorMessage = COMMON_TOAST_MESSAGES.COMMON_ERROR_MESSAGE;
      }
    }
  } else {
    state.errorMessage = COMMON_TOAST_MESSAGES.COMMON_ERROR_MESSAGE;
  }
  state.status = 'error';
};

export const getNoteListPageDataReducer = {
  pending: (state: TInitialState) => {
    state.isLoading = true;
  },
  fulfilled: (state: TInitialState, action: PayloadAction<TNoteListPageDataToRedux | void>) => {
    if (action.payload) {
      const { tags, folders, noteList } = action.payload;

      state.isLoading = false;

      state.tags = tags;
      state.folderList = [COMMON_FOLDER, ...folders].map(folder => {
        if (folder.id === COMMON_FOLDER.id) {
          return { ...folder, numberOfNotes: noteList.length };
        }
        const folderNotes = noteList.filter(note => note.folder?.id === folder.id);

        return { ...folder, numberOfNotes: folderNotes.length };
      });

      if (state.activeFolder.id === COMMON_FOLDER.id) {
        state.filteredNotesList = noteList;
        state.filteredByFolderNotesList = noteList;
      } else {
        const notes = noteList.filter(note => state.activeFolder.id === note.folder?.id);
        state.filteredNotesList = notes;
        state.filteredByFolderNotesList = notes;
      }

      state.notesList = noteList;
    }
  },
  rejected,
};

export const getAllFoldersReducer = {
  pending: (state: TInitialState) => {
    state.status = 'loading';
  },
  fulfilled: (state: TInitialState, action: PayloadAction<TFolderItem[] | void>) => {
    if (action.payload) {
      state.folderList = [COMMON_FOLDER, ...action.payload];
    }
  },
  rejected,
};

export const createFolderReducer = {
  pending: (state: TInitialState) => {
    state.status = 'folderCreate';
  },
  fulfilled: (state: TInitialState, action: PayloadAction<TFolderItem | void>) => {
    if (action.payload) {
      state.status = 'success';
      state.folderList.push(action.payload);
      state.successMessage = SUCCESS_TOAST_MESSAGES.FOLDER_CREATED;
    }
  },
  rejected: rejectedNotesListAction,
};

export const renameFolderReducer = {
  pending: (state: TInitialState) => {
    state.status = 'folderRename';
  },
  fulfilled: (state: TInitialState, action: PayloadAction<TRenameFolderData | void>) => {
    if (action.payload && state.activeFolder) {
      const { folderId, title } = action.payload;
      const newFolder = { ...state.activeFolder, title };

      state.status = 'success';
      state.activeFolder = newFolder;
      state.folderList = state.folderList.map(folder => {
        if (folder.id === folderId) {
          return newFolder;
        }
        return folder;
      });
      state.successMessage = SUCCESS_TOAST_MESSAGES.FOLDER_RENAMED;
    }
  },
  rejected: rejectedNotesListAction,
};

export const deleteFolderReducer = {
  pending: (state: TInitialState) => {
    state.status = 'folderDelete';
  },
  fulfilled: (state: TInitialState, action: PayloadAction<string | void>) => {
    if (action.payload) {
      state.status = 'success';
      [state.activeFolder] = state.folderList;
      state.folderList = state.folderList.filter(folder => folder.id !== action.payload);

      state.successMessage = SUCCESS_TOAST_MESSAGES.FOLDER_DELETED;
    }
  },
  rejected: rejectedNotesListAction,
};

export const deleteTagByIdReducer = {
  pending: (state: TInitialState) => {
    state.status = 'tagDeleting';
  },
  fulfilled: (state: TInitialState, action: PayloadAction<string | void>) => {
    const tagId = action.payload;

    if (tagId) {
      const activeFolderId = state.activeFolder.id;

      const notesList = state.notesList.map(note => {
        return { ...note, tags: note.tags.filter(tag => tag.id !== tagId) };
      });

      state.status = 'success';
      state.tags = state.tags.filter(tag => tag.id !== tagId);

      state.notesList = notesList;
      if (activeFolderId !== COMMON_FOLDER.id) {
        state.filteredNotesList = notesList.filter(note => note.folder?.id === activeFolderId);
      } else {
        state.filteredNotesList = notesList;
      }
    }
  },
  rejected: rejectedNotesListAction,
};
