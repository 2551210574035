import React, { memo, useEffect, useMemo, useRef } from 'react';
//
import { Icon } from '@atoms/icon';
import { Toaster } from '@uikit/Toast';
import { Loader } from '@blocks/loader';
import { storageDb } from '@api/storageApi';
import { NoAccess } from '@components/noAccess';
import { getAccountsData } from '@redux/selectors';
import { ErrorMessage } from '@blocks/errorMessage';
import { getAccounts } from '@redux/Accounts/thunks';
import { AccountsTemplate } from '@templates/accounts';
import { hideNoFilialError } from '@redux/Accounts/slice';
import { useAppDispatch, useAppSelector } from '@hooks/index';

const AccountsPage = memo(() => {
  // получаем диспатч из хука
  const dispatch = useAppDispatch();

  const { auth } = storageDb.getAuth();

  // получаем данные из store
  const { data, isLoading, isError, isAccessDenied, isRetries, isNoFilialError } =
    useAppSelector(getAccountsData);

  // При первой загрузке страницы запрашиваем список филиалов
  useEffect(() => {
    if (!isRetries && auth) {
      dispatch(getAccounts());
    }
  }, [auth, dispatch, isRetries]);

  // Запрашивает список филиалов повторно при ошибке токена
  useEffect(() => {
    if (isRetries && auth) {
      setTimeout(() => dispatch(getAccounts()), 100);
    }
  }, [auth, dispatch, isRetries]);

  // Объект настроек errorToastMessage
  const errorToast = useMemo(
    () => ({
      message:
        'Ошибка, \n запрашиваемый вами филиал не найден.\n Пожалуйста уточните параметры ссылки',
      icon: <Icon variant='errorToast' className='ml-4' />,
      onDismiss: () => {
        dispatch(hideNoFilialError());
      },
      timeout: 0,
    }),
    [dispatch],
  );

  // реф для сообщения об ошибке
  const errorToastRef = useRef(errorToast);

  // Эффект показывает сообщение об ошибке при сохранении настроек
  useEffect(() => {
    if (isNoFilialError) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      errorToastRef.current.show(errorToast);
    }
  }, [isNoFilialError, errorToast]);

  return (
    <>
      {isLoading ? <Loader className='h-full' /> : null}
      {!isLoading && !isError && !isAccessDenied ? (
        <AccountsTemplate data={data} className='px-[1rem] sm:px-[1.5rem] w-full' />
      ) : null}
      {isAccessDenied && !isLoading ? <NoAccess /> : null}
      {!isAccessDenied && isError ? <ErrorMessage /> : null}
      <Toaster
        maxToasts={1}
        view='smooth'
        color='danger'
        className='error'
        ref={errorToastRef}
        position='top-right'
        withoutClose={false}
      />
    </>
  );
});

export default AccountsPage;
