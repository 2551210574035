import { PayloadAction } from '@reduxjs/toolkit';
import { Descendant } from 'slate';
import { TTaskInfo } from '@models/Tasks';
import { COMMON_TOAST_MESSAGES } from '@const/common';
import { TFolderItem, TNoteTag } from '@redux/noteList/zod';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { SUCCESS_TOAST_MESSAGES, TAG_PREFIX, WARNING_TOAST_MESSAGES } from '@const/notes';
import { REJECT_RESPONSE_2_KEY, REJECT_RESPONSE_KEY, TRejectActionPayload } from '@api/types';
import {
  TNote,
  TInitialState,
  TAttachedTemplates,
  TNoteWithAttachedTemplateData,
} from '@redux/note/zod';

export const reducers = {
  setNoteData(state: TInitialState, action: PayloadAction<TNote>) {
    state.isLoading = false;
    state.data = action.payload;
    state.attachedTemplates = [];
    state.oldData = action.payload;
  },
  setTitle(state: TInitialState, action: PayloadAction<string>) {
    const newTitle = action.payload;

    state.data.title = newTitle.length > 250 ? newTitle.slice(0, 250) : newTitle;
  },
  setDescription(state: TInitialState, action: PayloadAction<Descendant[]>) {
    state.data.description = JSON.stringify(action.payload);
  },
  setVisibility(state: TInitialState, action: PayloadAction<boolean>) {
    state.data.isPrivate = action.payload;
  },
  addTag(state: TInitialState, action: PayloadAction<TNoteTag>) {
    const newTag = action.payload;

    if (state.data.tags.length < 6) {
      state.newTag = '';
      state.errorMessage = '';
      state.data.tags.push({ id: newTag.id, title: newTag.title });
    } else {
      state.errorMessage = WARNING_TOAST_MESSAGES.TAG_MAX_QUANTITY;
    }
  },
  deleteTag(state: TInitialState, action: PayloadAction<string>) {
    state.data.tags = state.data.tags.filter(tag => tag.id !== action.payload);
  },
  setNewTag(state: TInitialState, action: PayloadAction<string>) {
    state.newTag = action.payload.replace(TAG_PREFIX, '');
  },
  setEditMode(state: TInitialState, action: PayloadAction<boolean>) {
    state.isLoading = false;
    state.isNoteEdit = action.payload;
  },
  setActiveFolderInNote(state: TInitialState, action: PayloadAction<TFolderItem>) {
    const newActiveFolder = action.payload;
    state.data.folder = { id: newActiveFolder.id, title: newActiveFolder.title };
  },
  setTaskListToAttachNote(state: TInitialState, action: PayloadAction<TTaskInfo[]>) {
    state.taskListToAttachNote = action.payload;
  },
  setIsShowNoSaveExitModal(state: TInitialState, action: PayloadAction<boolean>) {
    state.isShowNoSaveExitModal = action.payload;
  },
  showNotUniqueTitleError(state: TInitialState) {
    state.errorMessage = WARNING_TOAST_MESSAGES.NOT_UNIQUE_TITLE;
  },
  addTaskToAttachList(state: TInitialState, action: PayloadAction<TAttachedTemplates>) {
    state.attachedTemplates.push(action.payload);
  },
  clearErrorMessageN(state: TInitialState) {
    state.errorMessage = '';
  },
  clearSuccessMessageN(state: TInitialState) {
    state.successMessage = '';
  },
};

const rejected = (state: TInitialState, action: PayloadAction<TRejectActionPayload>) => {
  if (action.payload && typeof action.payload === 'object') {
    if (REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
      const { detail } = action.payload;
      if (detail === REQUEST_TEXT_ERROR_STATUS.USER_NOT_FOUND) {
        state.isAccessDenied = true;
      } else if (detail !== REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        state.isError = true;
        state.isLoading = false;
      }
    } else if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
      const { status } = action.payload;
      if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
        state.isAccessDenied = true;
      } else {
        state.isError = true;
      }
      state.isLoading = false;
    }
  } else {
    state.isError = true;
    state.isLoading = false;
  }
};

const rejectedNoteAction = (state: TInitialState, action: PayloadAction<TRejectActionPayload>) => {
  if (action.payload && typeof action.payload === 'object') {
    if (REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
      const { detail } = action.payload;
      if (detail !== REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        state.errorMessage = COMMON_TOAST_MESSAGES.COMMON_ERROR_MESSAGE;
      }
      if (detail === REQUEST_TEXT_ERROR_STATUS.USER_NOT_FOUND) {
        state.isAccessDenied = true;
      }
    } else if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
      const { status } = action.payload;
      if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
        state.isAccessDenied = true;
      } else {
        state.errorMessage = COMMON_TOAST_MESSAGES.COMMON_ERROR_MESSAGE;
      }
    }
  } else {
    state.errorMessage = COMMON_TOAST_MESSAGES.COMMON_ERROR_MESSAGE;
  }
  state.status = 'error';
};

export const getNoteByIdReducer = {
  pending: (state: TInitialState) => {
    state.status = 'loading';
    state.isLoading = true;
  },
  fulfilled: (
    state: TInitialState,
    action: PayloadAction<TNoteWithAttachedTemplateData | void>,
  ) => {
    if (action.payload) {
      const noteData = action.payload;

      state.isLoading = false;
      state.status = 'success';
      state.isNoteEdit = false;
      state.data = noteData.note;
      state.oldData = noteData.note;
      state.attachedTemplates = noteData.attachedTemplates;
    }
  },
  rejected,
};

export const updateNoteByIdReducer = {
  pending: (state: TInitialState) => {
    state.status = 'loading';
  },
  fulfilled: (state: TInitialState, action: PayloadAction<TNote | void>) => {
    if (action.payload) {
      state.status = 'success';
      state.isNoteEdit = false;
      state.data = action.payload;
      state.oldData = action.payload;
      state.successMessage = SUCCESS_TOAST_MESSAGES.NOTE_UPDATED;
    }
  },
  rejected,
};

export const createNoteReducer = {
  pending: (state: TInitialState) => {
    state.status = 'noteCreation';
  },
  fulfilled: (state: TInitialState, action: PayloadAction<TNote | void>) => {
    if (action.payload) {
      state.status = 'success';
      state.isNoteEdit = false;
      state.data = action.payload;
      state.oldData = action.payload;
      state.successMessage = SUCCESS_TOAST_MESSAGES.NOTE_CREATED;
    }
  },
  rejected,
};

export const moveNoteToFolderReducer = {
  pending: (state: TInitialState) => {
    state.status = 'moveToFolder';
  },
  fulfilled: (state: TInitialState, action: PayloadAction<TNote | void>) => {
    if (action.payload) {
      state.status = 'success';
      state.isNoteEdit = false;
      state.data = action.payload;
      state.oldData = action.payload;
      state.successMessage = SUCCESS_TOAST_MESSAGES.NOTE_MOVED;
    }
  },
  rejected,
};

export const deleteNoteByIdReducer = {
  pending: (state: TInitialState) => {
    state.status = 'noteDeleting';
  },
  fulfilled: (state: TInitialState) => {
    state.status = 'success';
  },
  rejected,
};

export const createNewTagReducer = {
  pending: (state: TInitialState) => {
    state.status = 'tagCreation';
  },
  fulfilled: (state: TInitialState, action: PayloadAction<TNoteTag | void>) => {
    if (action.payload) {
      const newTag = action.payload;

      state.status = 'success';
      state.newTag = '';
      state.allTags.push(newTag);
      state.data.tags.push({ id: newTag.id, title: newTag.title });
      state.successMessage = SUCCESS_TOAST_MESSAGES.TAG_CREATED;
    }
  },
  rejected: rejectedNoteAction,
};

export const attachNoteToTaskReducer = {
  pending: (state: TInitialState) => {
    state.status = 'noteAttached';
    state.taskListToAttachNote = [];
  },
  fulfilled: (state: TInitialState, action: PayloadAction<number | void>) => {
    state.status = 'success';

    if (action.payload === 200) {
      state.errorMessage = WARNING_TOAST_MESSAGES.ALREADY_ADDED;
    } else {
      state.successMessage = SUCCESS_TOAST_MESSAGES.NOTE_ATTACHED;
    }
  },
  rejected: rejectedNoteAction,
};

export const deleteNoteFromTaskReducer = {
  pending: (state: TInitialState) => {
    state.status = 'noteUnpinFromTask';
  },
  fulfilled: (state: TInitialState, action: PayloadAction<string | void>) => {
    if (action.payload) {
      state.status = 'success';
      state.attachedTemplates = state.attachedTemplates.filter(
        template => template.id !== action.payload,
      );
      state.successMessage = SUCCESS_TOAST_MESSAGES.NOTE_UNPIN_FROM_TASK;
    } else {
      state.status = 'error';
      state.errorMessage = COMMON_TOAST_MESSAGES.COMMON_ERROR_MESSAGE;
    }
  },
  rejected: rejectedNoteAction,
};
